import {useEffect, useState} from "react";
import {IGroupedSchedule} from "../../../store/reducers/schedule/saga";

export const useScheduleSearch = (allSchedules: IGroupedSchedule[]) => {
    const [searchValue, setSearchValue] = useState("");
    const [searchResult, setSearchResult] = useState<IGroupedSchedule[]>([]);
    const onSearch = (word:string) => {
        const searchWord = word
        setSearchValue(searchWord)
        const schedules: IGroupedSchedule[] = []
        allSchedules.forEach(group => {
            const groupSchedules = group.schedules.filter(schedule =>
                schedule.competitionName?.toLowerCase().includes(searchWord.toLowerCase())
                ||
                schedule.home?.toLowerCase().includes(searchWord.toLowerCase())
                ||
                schedule.away?.toLowerCase().includes(searchWord.toLowerCase())
                ||
                schedule.region?.toLowerCase().includes(searchWord.toLowerCase())
            )
            const filteredGroup = {...group, schedules: groupSchedules}
            if (filteredGroup.schedules.length) {
                schedules.push(filteredGroup)
            }
        })
        return setSearchResult(schedules)
    }
    useEffect(() => {
        onSearch(searchValue)
    }, [allSchedules]);

    return {searchResult, searchValue, onSearch}
}