import {
    DecodersActionEnum,
    ICreateDecoder,
    IDecoder,
    IDeleteDecoder, IDeleteDecoderSuccess,
    IGetDecoders,
    ISetDecoders,
    IToggleDeleteDecoder, IToggleDeleteDecoderSuccess,
    IUpdateDecoder,
} from "./types";

export const DecodersActionCreators = {
    getDecoders: (queryString:string):IGetDecoders => ({type:DecodersActionEnum.GET_DECODERS,queryString}),
    setDecoders: (payload:IDecoder[]):ISetDecoders => ({type:DecodersActionEnum.SET_DECODERS,payload}),
    createDecoder: (payload:{name:string}):ICreateDecoder => ({type:DecodersActionEnum.CREATE_DECODER,payload}),
    updateDecoder: (payload:{name:string,id:number}):IUpdateDecoder => ({type:DecodersActionEnum.UPDATE_DECODER,payload}),
    deleteDecoder: (payload:number):IDeleteDecoder => ({type:DecodersActionEnum.DELETE_DECODER,payload}),
    deleteDecoderSuccess: (payload:number):IDeleteDecoderSuccess => ({type:DecodersActionEnum.DELETE_DECODER_SUCCESS,payload}),
    toggleDeleteDecoder: (payload:number):IToggleDeleteDecoder => ({type:DecodersActionEnum.TOGGLE_DELETE_DECODER,payload}),
    toggleDeleteDecoderSuccess: (payload:IDecoder):IToggleDeleteDecoderSuccess => ({type:DecodersActionEnum.TOGGLE_DELETE_DECODER_SUCCESS,payload})
}