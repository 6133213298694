import React, {Dispatch, SetStateAction, useEffect} from 'react';
import styles from './styles.module.scss'
import Title from "../../../../components/Title";
import {Controller, useForm} from "react-hook-form";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import {useDispatch} from "react-redux";
import {SportTimingActionCreators} from "../../../../store/reducers/sportTimings/action-creators";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";


interface ISportTimingsFormProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    editInfo: { isEdit: boolean, sportId: string }
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; sportId: string; }>>
}

const SportTimingsForm: React.FC<ISportTimingsFormProps> = ({setIsOpenModal, setEditInfo, editInfo}) => {
    const dispatch = useDispatch()
    const {sportTimings} = useTypedSelector(state => state.sportTimingsReducer)
    const {handleSubmit, control, getValues, reset, setValue, formState: {errors},} = useForm({
        defaultValues: {
            sport: "",
            duration: ""
        }
    });
    useEffect(() => {
        if (editInfo.sportId) {
            const currentSportTiming = sportTimings.find(sport => sport.id === editInfo.sportId)
            if (currentSportTiming) {
                setValue("sport", currentSportTiming.name)
                // @ts-ignore
                setValue("duration", currentSportTiming.duration)
            }
        }
    }, [editInfo]);

    const createSportTiming = () => {
        const payload = {name: getValues("sport"), durationInMinutes: +getValues("duration")}
        closeForm()
        dispatch(SportTimingActionCreators.createSportTiming(payload))
    }

    const updateSportTimings = () => {
        const payload = {
            id: editInfo.sportId,
            name: getValues("sport"),
            durationInMinutes: +getValues("duration"),
        }
        closeForm()
        dispatch(SportTimingActionCreators.updateSportTiming(payload))

    }

    const closeForm = () => {
        setIsOpenModal(false)
        setEditInfo({isEdit: false, sportId: ""})
        reset()
    }
    return (
        <div className={styles.sportTimingsForm}>
            <form
                onSubmit={editInfo.isEdit ? handleSubmit(updateSportTimings) : handleSubmit(createSportTiming)}
                className={styles.modalForm}
            >
                <button
                    onClick={closeForm}
                    className={styles.closeBtn}
                />
                <Title value={editInfo.isEdit ? 'Update Sport Timing' : 'Add Sport Timing'}/>
                <div className="largeSelect mt15">
                    <Controller
                        name="sport"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Input
                            value={value}
                            setValue={onChange}
                            label={'Sport'}
                            readonly={false}
                        />}
                    />
                </div>
                {errors.sport?.type === 'required' && <p className={styles.formError}>Sport is required field</p>}
                <div className="largeSelect mt15">
                    <Controller
                        name="duration"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Input
                            type="number"
                            value={value}
                            setValue={onChange}
                            label={'Duration in minutes'}
                            readonly={false}
                        />}
                    />
                </div>
                {errors.duration?.type === 'required' && <p className={styles.formError}>Duration is required field</p>}
                <div className={styles.addSportBtn}>
                    <Button
                        type={"submit"}
                        value={editInfo.isEdit ? 'Update Timing' : 'Add Timing +'}
                    />
                </div>
            </form>
        </div>
    );
};

export default SportTimingsForm;
