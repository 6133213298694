import {
    ChannelsActionEnum,
    IChannel,
    ICreateChannel,
    ICreateChannelSuccess,
    IDeleteChannel,
    IDeleteChannelSuccess,
    IGetChannels,
    IGetChannelsPayload,
    IRestoreChannel, IRestoreChannelSuccess,
    ISetChannels,
    IUpdateChannel, IUpdateChannelSuccess,
} from "./types";

export const ChannelsActionCreators = {
    getChannels: (payload:IGetChannelsPayload|{}):IGetChannels => ({type:ChannelsActionEnum.GET_CHANNELS,payload}),
    setChannels: (payload:IChannel[]):ISetChannels => ({type:ChannelsActionEnum.SET_CHANNELS,payload}),
    createChannel: (payload:{name:string}):ICreateChannel => ({type:ChannelsActionEnum.CREATE_CHANNEL,payload}),
    createChannelSuccess: (payload:IChannel):ICreateChannelSuccess => ({type:ChannelsActionEnum.CREATE_CHANNEL_SUCCESS,payload}),
    updateChannel: (payload:{name:string,id:number}):IUpdateChannel => ({type:ChannelsActionEnum.UPDATE_CHANNEL,payload}),
    updateChannelSuccess: (payload:IChannel):IUpdateChannelSuccess => ({type:ChannelsActionEnum.UPDATE_CHANNEL_SUCCESS,payload}),
    deleteChannel: (payload:number):IDeleteChannel => ({type:ChannelsActionEnum.DELETE_CHANNEL,payload}),
    deleteChannelSuccess: (payload:number):IDeleteChannelSuccess => ({type:ChannelsActionEnum.DELETE_CHANNEL_SUCCESS,payload}),
    restoreChannel: (payload:number):IRestoreChannel => ({type:ChannelsActionEnum.RESTORE_CHANNEL,payload}),
    restoreChannelSuccess: (payload:number):IRestoreChannelSuccess => ({type:ChannelsActionEnum.RESTORE_CHANNEL_SUCCESS,payload}),
}