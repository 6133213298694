import React, {Dispatch, SetStateAction} from 'react';
import DtPicker from 'react-calendar-datetime-picker'
import 'react-calendar-datetime-picker/dist/index.css'
import './style.scss'
import {IDay} from "react-calendar-datetime-picker/dist/type";


interface IRangeDaysCalendarProps {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
    label: string
    setCurrentDate: Dispatch<SetStateAction<IDay | undefined>>
    currentDate:IDay | undefined
    calendarClass?: string
}

const TimeCalendar: React.FC<IRangeDaysCalendarProps> = ({label,setCurrentDate,currentDate,calendarClass}) => {
    return (
        <>
            <div className={"calendarWrapper"}>
                <button
                    type="button"
                    className={"calendarBtn"}
                />
                <DtPicker
                    placeholder={label}
                    calenderModalClass={calendarClass}
                    inputClass={"datepicker-input"}
                    daysClass={"custom-days"}
                    headerClass={"custom-header"}
                    initValue={currentDate}
                    onChange={setCurrentDate}
                    withTime
                    showTimeInput
                    autoClose={false}
                />
            </div>
        </>
    );
}

export default React.memo(TimeCalendar);
