import {MultiValue, SingleValue} from "react-select";
import {IInputOption} from "../fixtures/types";
import {IGetScheduleResp, IGroupedSchedule} from "./saga";
import {IUpdatedEvent} from "../event/types";

export interface IUpdateEvents {
    eventIds: number[],
    broadcasterId?: number,
    decoderId?: number,
    channelId?: number,
    description?: string,
    information?: string,
    isVerified?: boolean
}

export interface IScheduledFixturesState {
    groupByChoices: { items: IInputOption[] }
    groupedSchedules: IGroupedSchedule[]
    sports: { items: IInputOption[] }
    emailTemplate: IEmailTemplateInit
    isLoading: boolean,
    broadcasters: IInputOption[],
    decoders: IInputOption[],
    countries: IInputOption[],
    sportsFilter: IInputOption[],
    competitions: IInputOption[],
    servers: IInputOption[],
}

export interface IGetFilersOptionsResp {
    broadcasters: IInputOption[],
    decoders: IInputOption[],
    countries: IInputOption[],
    sports: IInputOption[],
    competitions: IInputOption[],
    servers: IInputOption[]
}

export interface ISchedule {
    fixtureId: string,
    eventId: number,
    startTime: string | undefined,
    endTime: string | undefined,
    channelId: number,
    competitionId: string,
    competitionName: string,
    broadcasterId: number,
    broadcasterName: string
    decoderId: number,
    sport: string,
    home: string,
    away: string,
    info: string,
    description: string,
    verified: boolean,
    isCanceled: boolean,
    isProtected: boolean,
    isInternal: boolean,
    decoderName: string,
    channelName: string,
    packageName: string
    region: string
    round: string
    season: string
    group: string
}

export interface IEmailTemplateInit {
    emailReasons: {
        items: IInputOption[]
    },
    clientList: {
        items: IInputOption[]
    }
}

export interface IEmailBody {
    userId: string
    emailTemplateId: string
    eventId: number
    body?: string
    subject?: string
    address: string
}

export enum ScheduleActionEnum {
    GET_SCHEDULED_FIXTURES = "GET_SCHEDULED_FIXTURES",
    SET_SCHEDULED_FIXTURES = "SET_SCHEDULED_FIXTURES",
    SET_SCHEDULE_FILTER = "SET_SCHEDULE_FILTER",
    SET_IS_VERIFY = "SET_IS_VERIFY",
    UPDATE_SCHEDULE_EVENT = "UPDATE_SCHEDULE_EVENT",
    SET_PROTECTION = "SET_PROTECTION",
    SET_INTERNAL = "SET_INTERNAL",
    CANCEL_EVENT = "CANCEL_EVENT",
    EXPIRE_EVENT = "EXPIRE_EVENT",
    REMOVE_EVENT = "REMOVE_EVENT",
    REMOVE_SUCCESS = "REMOVE_SUCCESS",
    DUPLICATE_EVENT = "DUPLICATE_EVENT",
    DUPLICATE_EVENT_SUCCESS = "DUPLICATE_EVENT_SUCCESS",
    SET_END_DATE = "SET_END_DATE",
    GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE",
    SET_EMAIL_TEMPLATE = "SET_EMAIL_TEMPLATE",
    SEND_EMAIL = "SEND_EMAIL",
    SET_SCHEDULE_LOADER = "SET_SCHEDULE_LOADER",
    EDIT_SCHEDULE_EVENT = "EDIT_SCHEDULE_EVENT",
    EDIT_SEVERAL_SCHEDULE_EVENTS = "EDIT_SEVERAL_SCHEDULE_EVENTS",
    SET_SEVERAL_SCHEDULE_EVENTS = "SET_SEVERAL_SCHEDULE_EVENTS",
    GET_FILTERS_OPTIONS = "GET_FILTERS_OPTIONS",
    SET_FILTERS_OPTIONS = "SET_FILTERS_OPTIONS"
}

export interface IGetSchedule {
    type: ScheduleActionEnum.GET_SCHEDULED_FIXTURES,
    queryString: string
}

export interface ISetScheduledFixtures {
    type: ScheduleActionEnum.SET_SCHEDULED_FIXTURES,
    payload: IGetScheduleResp
}

export interface ISetScheduleFilter {
    type: ScheduleActionEnum.SET_SCHEDULE_FILTER,
    payload: { name: string, event: MultiValue<string> | SingleValue<string> }
}

export interface ISetIsVerify {
    type: ScheduleActionEnum.SET_IS_VERIFY,
    payload: { i: number, b: boolean }
}

export interface ISetProtection {
    type: ScheduleActionEnum.SET_PROTECTION,
    payload: { i: number, b: boolean }
}

export interface ISetInternal {
    type: ScheduleActionEnum.SET_INTERNAL,
    payload: { i: number, b: boolean }
}

export interface ICancelEvent {
    type: ScheduleActionEnum.CANCEL_EVENT,
    payload: { i: number, b: boolean }
}

export interface IExpireEvent {
    type: ScheduleActionEnum.EXPIRE_EVENT,
    payload: number
}

export interface IUpdateEvent {
    type: ScheduleActionEnum.UPDATE_SCHEDULE_EVENT,
    payload: ISchedule
}

export interface IEditEvent {
    type: ScheduleActionEnum.EDIT_SCHEDULE_EVENT,
    payload: IUpdatedEvent
}

export interface IRemoveEvent {
    type: ScheduleActionEnum.REMOVE_EVENT,
    payload: number
}

export interface IRemoveSuccess {
    type: ScheduleActionEnum.REMOVE_SUCCESS,
    payload: number
}

export interface IDuplicateEvent {
    type: ScheduleActionEnum.DUPLICATE_EVENT,
    payload: number
}

export interface IDuplicateEventSuccess {
    type: ScheduleActionEnum.DUPLICATE_EVENT_SUCCESS,
    payload: ISchedule
}

export interface ISetEndDate {
    type: ScheduleActionEnum.SET_END_DATE,
    payload: { i: number, b: boolean }
}

export interface IGetEmailTemplate {
    type: ScheduleActionEnum.GET_EMAIL_TEMPLATE,
}

export interface ISetEmailTemplate {
    type: ScheduleActionEnum.SET_EMAIL_TEMPLATE,
    payload: IEmailTemplateInit
}

export interface ISendEmail {
    type: ScheduleActionEnum.SEND_EMAIL,
    payload: IEmailBody
}

export interface ISetScheduleLoader {
    type: ScheduleActionEnum.SET_SCHEDULE_LOADER,
    payload: boolean
}

export interface IGetFiltersOptions {
    type: ScheduleActionEnum.GET_FILTERS_OPTIONS
}

export interface ISetFiltersOptions {
    type: ScheduleActionEnum.SET_FILTERS_OPTIONS,
    payload: IGetFilersOptionsResp
}

export interface IUpdateSeveralEvents {
    type: ScheduleActionEnum.EDIT_SEVERAL_SCHEDULE_EVENTS,
    payload: IUpdateEvents
}

export interface ISetSeveralEvents {
    type: ScheduleActionEnum.SET_SEVERAL_SCHEDULE_EVENTS,
    payload: ISchedule[]
}



export type ScheduleAction =
    IGetSchedule | ISetScheduledFixtures | ISetScheduleFilter |
    IUpdateEvent | IRemoveSuccess | IDuplicateEventSuccess | ISetEmailTemplate | ISetScheduleLoader |
    IGetFiltersOptions | ISetFiltersOptions | IUpdateSeveralEvents | ISetSeveralEvents
