import React, {useEffect, useState} from 'react';
import styles from "./index.module.scss";
import Title from "../../../../../components/Title";
import Button from "../../../../../components/Button";
import {Controller, useForm} from "react-hook-form";
import Input from "../../../../../components/Input";
import SelectInput from "../../../../../components/SelectInput";
import {formatOptions} from "../../../../../helpers/formatSelectOptions";
import {UsersActionCreators} from "../../../../../store/reducers/userManagement/action-creators";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../../hooks/useTypedSelector";
import {IUser} from "../../../../../store/reducers/userManagement/types";
import Loader from "../../../../../components/Loader/Loader";


export interface IUserManagementFormProps {
    setIsOpenForm: (arg: boolean) => void,
    editUser?: IUser,
    modalTitle: string
}


const UserManagementForm: React.FC<IUserManagementFormProps> = ({
                                                                    setIsOpenForm,
                                                                    editUser,
                                                                    modalTitle,
                                                                }) => {
    const {handleSubmit, control, reset, getValues, setValue, formState: {errors},} = useForm({
        defaultValues: {
            email: "",
            role: {value: "", label: ""} as any,
        }
    });

    const [isSetEditUserValues, setIsSetEditUserValues] = useState(false);
    const {roles} = useTypedSelector(state => state.usersReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(UsersActionCreators.getUsersRoles());

        if (editUser) {
            const roleId = roles.find(role => {
                if (role.name === editUser.role) return role.id
            })

            const formatRole = {
                value: roleId ? +roleId.id : undefined,
                label: editUser.role
            }
            setValue("role", formatRole)
            setValue("email", editUser.email)

            if (getValues('role') && getValues('email').length) {
                setIsSetEditUserValues(true);
            }
        }
        setIsSetEditUserValues(true);

        dispatch(UsersActionCreators.getUsersRoles());
    }, [])

    const addUser = () => {
        const payload = {
            email: getValues("email"),
            roleId: +getValues("role").value,
        }
        setIsOpenForm(false)
        clearForm()
        dispatch(UsersActionCreators.addUser(payload))
    }

    const updateUser = () => {
        if (editUser) {
            const payload = {
                updatingBody: {
                    userId: editUser.userId,
                    roleId: +getValues("role").value,
                },
                roleName: getValues("role").label,
            }
            setIsOpenForm(false)
            clearForm()
            dispatch(UsersActionCreators.updateUser(payload))
        }
    }

    const isEmptyField = (v: any) => {
        return v.value > 0
    }

    const clearForm = () => {
        reset()
    }

    const closeModal = () => {
        setIsOpenForm(false)
        clearForm()
    }

    return (
        <div className={styles.userManagementInner}>
            <form
                onSubmit={editUser ? handleSubmit(updateUser) : handleSubmit(addUser)}
                className={styles.modalForm}
            >
                {!isSetEditUserValues && <Loader/>}

                <div className={styles.titleInner}>
                    <Title value={modalTitle}/>
                    <button onClick={closeModal} className={styles.closeBtn}/>
                </div>

                <div className={styles.inputInner}>
                    <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: !editUser,
                            maxLength: 40,
                            minLength: 10,
                            pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                        }}
                        render={({field: {onChange, value}}) => <Input
                            value={value}
                            setValue={onChange}
                            label={'Email'}
                            readonly={false}
                            disabled={!!editUser}
                        />}
                    />
                </div>
                {errors?.email?.type === "required" &&
                    <p className={styles.formError}>Email is required field</p>}
                {errors?.email?.type === "minLength" &&
                    <p className={styles.formError}>Email should contain at least 10
                        characters</p>}
                {errors?.email?.type === "maxLength" &&
                    <p className={styles.formError}>Email should contain max 40 characters</p>}
                {errors?.email?.type === "pattern" &&
                    <p className={styles.formError}>Email should be like example@xxx.xx</p>}

                <div className={styles.inputInner}>
                    <Controller
                        name="role"
                        control={control}
                        rules={{
                            required: true,
                            validate: {
                                isEmptyField: v => isEmptyField(v),
                            }
                        }}
                        render={({field: {onChange, value}}) => (
                            <SelectInput
                                value={value}
                                onChange={onChange}
                                name={'role'}
                                searchable={true}
                                options={formatOptions(roles)}
                                label={'Role'}
                            />
                        )
                        }
                    />
                </div>
                {errors.role?.type === "isEmptyField" &&
                    <p className={styles.formError}>Role is required field</p>}
                <div className={styles.submitButtonContainer}>
                    <div>
                        <Button
                            type={"submit"}
                            value={`${modalTitle}`}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default React.memo(UserManagementForm);