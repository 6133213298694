import styles from './style.module.scss'
import Input from "../../../../components/Input";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import Title from "../../../../components/Title";
import SelectInput from "../../../../components/SelectInput";
import ToggleSwitchBtn from "../../../../components/Button/Toggle-Switch";
import Button from "../../../../components/Button";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {IInputOption} from "../../../../store/reducers/fixtures/types";
import {ICompFilterValue} from "../CompetitionSearch";
import {useDispatch} from "react-redux";
import {CompetitionsActionCreators} from "../../../../store/reducers/competitions/action-creators";
import {Controller, useForm} from "react-hook-form";

interface ISelectOptions {
    value: string,
    label: string
}

interface ICompetitionsModalProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    editInfo: { isEdit: boolean, competitionId: string }
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; competitionId: string; }>>
    formatOptions: (options: IInputOption[]) => ISelectOptions[]
    filterValues: ICompFilterValue
    getQueryString: (filter: { sportId: string, regionId: string, typeId: string }) => string
}

const CompetitionsModal: React.FC<ICompetitionsModalProps> = ({
      setIsOpenModal,
      setEditInfo,
      editInfo,
      formatOptions,
      filterValues,
      getQueryString
    }) => {
    const dispatch = useDispatch()
    const {competitions} = useTypedSelector(state => state.competitionsReducer)
    const {init} = useTypedSelector(state => state.competitionsReducer)
    const [isWomen, setIsWomen] = useState(false)
    const {handleSubmit, control, getValues, setValue, formState: {errors},} = useForm({
        defaultValues: {
            compName: "",
            sportId: {value: "", label: ""} as any,
            regionId: {value: "", label: ""} as any,
            typeId: {value: "", label: ""} as any,
        }
    });

    const closeModal = () => {
        setIsOpenModal(false)
        setEditInfo({isEdit: false, competitionId: ''})
    }

    const createCompetition = () => {
        const filterValuesForQuery = {
            sportId: filterValues.sportId,
            regionId: filterValues.regionId,
            typeId: filterValues.typeId
        }
        const payload = {
            newCompetition: {
                sportId: getValues("sportId").value,
                countryId: getValues("regionId").value,
                type: Number(getValues("typeId").value,),
                name: getValues("compName"),
                belongsToWomen: isWomen,
                runningTime: "0"
            },
            queryString: getQueryString(filterValuesForQuery)
        }
        closeModal()
        dispatch(CompetitionsActionCreators.createCompetition(payload))
    }

    const updateCompetition = () => {
        const filterValuesForQuery = {
            sportId: filterValues.sportId,
            regionId: filterValues.regionId,
            typeId: filterValues.typeId
        }
        const payload = {
            newCompetition: {
                id: editInfo.competitionId,
                sportId: getValues("sportId").value,
                countryId: getValues("regionId").value,
                type: Number(getValues("typeId").value,),
                name: getValues("compName"),
                belongsToWomen: isWomen,
                runningTime: "0"
            },
            queryString: getQueryString(filterValuesForQuery)
        }
        dispatch(CompetitionsActionCreators.updateCompetition(payload))
        closeModal()
    }

    const setDefaultInputValues = () => {
        const updatedComp = competitions.find(comp => comp.id === editInfo.competitionId)
        if (updatedComp) {
            setValue("compName", updatedComp.name)
            setIsWomen(updatedComp.belongsToWomen)
            const sport = formatOptions(init?.sports?.items).find(sport => sport.value === updatedComp.sportId)
            const region = formatOptions(init?.regions?.items).find(region => region.value === updatedComp.countryId)
            const type = formatOptions(init?.competitionTypes?.items).find(type => type.value === updatedComp.type.toString())
            if (sport && region && type) {
                setValue("sportId", sport)
                setValue("regionId", region)
                setValue("typeId", type)
            }
        }
    }

    useEffect(() => {
        if (editInfo.isEdit) {
            setDefaultInputValues()
        }
    }, [editInfo])

    // @ts-ignore
    return (
        <div className={styles.competitionsModal}>
            <form
                className={styles.modalForm}
            >
                <button
                    onClick={closeModal}
                    className={styles.closeBtn}
                />
                <Title value={editInfo.isEdit ? "Update competition" : 'Create competitions'}/>
                <div className="largeSelect mt15">
                    <Controller
                        name="compName"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Input
                            value={value}
                            setValue={onChange}
                            label={'Name'}
                            readonly={false}
                        />}
                    />
                </div>
                {errors.compName?.type === 'required' && <p className={styles.formError}>Name is required field</p>}
                <div className="largeSelect mt15">
                    <Controller
                        name="sportId"
                        control={control}
                        rules={{
                            validate: {
                                emptyField: v => v.value.length > 0,
                            }
                        }}
                        render={({field: {onChange, value}}) => <SelectInput
                            value={value}
                            onChange={onChange}
                            name={'sportId'}
                            searchable={true}
                            options={formatOptions(init?.sports?.items)}
                            label={'Sport'}
                        />}
                    />
                </div>
                {errors.sportId?.type === "emptyField" && <p className={styles.formError}>Sport is required field</p>}
                <div className="largeSelect mt15">
                    <Controller
                        name="regionId"
                        control={control}
                        rules={{
                            validate: {
                                emptyField: v => v.value.length > 0,
                            }
                        }}
                        render={({field: {onChange, value}}) => <SelectInput
                            value={value}
                            onChange={onChange}
                            name={'regionId'}
                            searchable={true}
                            options={formatOptions(init?.regions?.items)}
                            label={'Region'}
                        />
                        }
                    />
                </div>
                {errors.regionId?.type === "emptyField" && <p className={styles.formError}>Region is required field</p>}
                <div className="largeSelect mt15">
                    <Controller
                        name="typeId"
                        control={control}
                        rules={{
                            validate: {
                                emptyField: v => v.value.length > 0,
                            }
                        }}
                        render={({field: {onChange, value}}) => <SelectInput
                            value={value}
                            onChange={onChange}
                            name={"typeId"}
                            searchable={true}
                            options={formatOptions(init?.competitionTypes?.items)}
                            label={'Type'}
                        />
                        }
                    />
                </div>
                {errors.typeId?.type === "emptyField" && <p className={styles.formError}>Type is required field</p>}
                <div onClick={()=>setIsWomen(!isWomen)} className={styles.formFooter}>
                    <ToggleSwitchBtn
                        checked={isWomen}
                        height={17}
                        width={40}
                        handleDiameter={14}
                        label={'Women'}
                    />
                    <div className="regularBtn mr20">
                        <Button
                            onClick={editInfo.isEdit ? handleSubmit(updateCompetition) : handleSubmit(createCompetition)}
                            value={editInfo.isEdit ? "Update" : 'Create'}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default React.memo(CompetitionsModal);