import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import styles from './style.module.scss'
import Title from "../../../../components/Title";
import Input from "../../../../components/Input";
import SelectInput from "../../../../components/SelectInput";
import {SingleValue} from "react-select";
import Button from "../../../../components/Button";
import ToggleSwitchBtn from "../../../../components/Button/Toggle-Switch";
import {useDispatch} from "react-redux";
import {EventsActionCreator} from "../../../../store/reducers/event/action-creators";
import TimeCalendar from "../../../../components/TimeCalendar/TimeCalendar";
import {IInputOption} from "../../../../store/reducers/fixtures/types";
import {client} from "../../../../services/api-servise";
import {Controller, useForm} from "react-hook-form";
import {useAutoFillEndDate} from "../hooks/useAutoFillEndDate";
import CreatableSelectInp from "../../../../components/CreatableSelect/CreatableSelect";
import {IDay} from "react-calendar-datetime-picker/dist/type";
import {formatDate} from "../../../../helpers/TimePickerHelper";
import moment from "moment/moment";


interface IEventsModalProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    filter: { sportId: SingleValue<string>, regionId: SingleValue<string>, competitionId: SingleValue<string> }
    getQueryString: (filter: { sportId: string, regionId: string, compId: string }) => string
}

interface IInitFormOptions {
    broadcasters: {
        items: IInputOption[]
    },
    decoders: {
        items: IInputOption[]
    },
    servers: {
        items: IInputOption[]
    }
    seasons: string []
    rounds: string[]
    sportDuration: number
}

const EventsManualModal: React.FC<IEventsModalProps> = ({setIsOpenModal, filter, getQueryString}) => {
    const dispatch = useDispatch()
    const [sport] = useState<SingleValue<any>>(filter.sportId)
    const [region] = useState<SingleValue<any>>(filter.regionId)
    const [competition] = useState<SingleValue<any>>(filter.competitionId)
    const [season, setSeason] = useState<SingleValue<any>>("")
    const [round, setRound] = useState<SingleValue<any>>("")
    const [group, setGroup] = useState("")
    const [numberOfEvents, setNumberOfEvents] = useState<SingleValue<any>>(0)
    const [home, setHome] = useState('')
    const [away, setAway] = useState('')
    const [startDate, setStartDate] = useState<IDay | undefined>(undefined);
    const [endDate, setEndDate] = useState<IDay | undefined>(undefined);
    const [isInternal, setIsInternal] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    const [isOpenStartDate, setIsOpenStartDate] = useState(false)
    const [isOpenEndDate, setIsOpenEndDate] = useState(false)
    const [initOptions, setInitOptions] = useState({} as IInitFormOptions)
    const [futureEventsNum, setFutureEventsNum] = useState(0)
    const [createdEventsCount, setCreatedEventsCount] = useState(1)
    const [dateValidation, setDateValidation] = useState({startDate: true, endDate: true})
    const [isValidEndTime, setIsValidEndTime] = useState(true);
    const [descriptionValidation, setDescriptionValidation] = useState(true)
    const {handleSubmit, control, getValues, setValue, formState: {errors},} = useForm({
        defaultValues: {
            broadcaster: {value: "", label: ""} as any,
            decoder: {value: "", label: ""} as any,
            server: {value: "", label: ""} as any,
            description: ""
        }
    });
    const [sportTimeDuration, setSportTimeDuration] = useState(0);
    const descriptionValue = getValues("description")


    useAutoFillEndDate(startDate, competition.value, setEndDate, sportTimeDuration, endDate)

    useEffect(() => {
        getInitOptions()
    }, [])

    useEffect(() => {
        if (numberOfEvents > 0 && descriptionValue) {
            setValue("description", `${getValues("description")}-${formatNumber(numberOfEvents)}`)
        }
    }, [numberOfEvents, descriptionValue]);

    useEffect(() => {
        if (endDate && startDate) {
            const start = moment(formatDate(startDate))
            const end = moment(formatDate(endDate))
            const duration = moment.duration(end.diff(start));
            setSportTimeDuration(duration.asMinutes())
        }
    }, [endDate])

    const getChannels = async (decoderId: number) => {
        try {
            const channels = await client.get(`/api/Event/Channels?decoderId=${decoderId}`)
            setInitOptions({...initOptions, servers: channels.data.channels})
        } catch (e) {
            console.log(e)
        }
    }

    const getDecoders = async (channelId: number) => {
        try {
            const decoders = await client.get(`/api/Event/Decoders?channelId=${channelId}`)
            setInitOptions({...initOptions, decoders: decoders.data.decoders})
        } catch (e) {
            console.log(e)
        }
    }

    const onChangeDecoder = (e: SingleValue<any>) => {
        setValue("decoder", e)
        getChannels(e.value)

    }

    const onChangeServer = (e: SingleValue<any>) => {
        setValue("server", e)
        getDecoders(e.value)
    }

    const clearForm = () => {
        setSeason("")
        setRound("")
        setGroup("")
        setIsInternal(false)
        setIsVerified(false)
        setAway("")
        setHome("")
        setValue("decoder", "")
        setValue("server", "")
    }

    const getInitOptions = async () => {
        try {
            const inputsValues = await client.get<IInitFormOptions>(`/api/Event/Init?competitionId=${competition.value}`)
            setInitOptions(inputsValues.data)
            setSportTimeDuration(inputsValues.data.sportDuration)
        } catch (e) {
            console.log(e)
        }
    }
    const checkDateValidation = () => {
        if (!startDate && !endDate) {
            setDateValidation({startDate: false, endDate: false})
            return false
        } else if (!startDate) {
            setDateValidation({...dateValidation, startDate: false})
            return false
        } else if (!endDate) {
            setDateValidation({...dateValidation, endDate: false})
            return false
        } else {
            return true
        }
    }
    const checkIsCorrectEndTime = (startDate: IDay | undefined, endDate: IDay | undefined): boolean => {
        const start = moment(formatDate(startDate))
        const end = moment(formatDate(endDate))
        const diff = moment.duration(end.diff(start)).asMilliseconds();
        setIsValidEndTime(false)
       return !(diff === 0 || diff < 0);
    }
    const checkIsHomeAway = () => {
        return !!(home && away);
    }
    const validateDescriptionHomeAway = () => {
        const description = getValues("description")
        if (!description && !checkIsHomeAway()) {
            setDescriptionValidation(false)
            return false
        } else {
            setDescriptionValidation(true)
            return true
        }
    }

    const formatDescription = () => {
        if (numberOfEvents.value > 0 && getValues("description")) {
            return getValues("description") + "-" + formatNumber(createdEventsCount)
        } else {
            return getValues("description")
        }
    }

    const createEvent = () => {
        if (!validateDescriptionHomeAway()) {
            return
        }

        if (!checkDateValidation()) {
            return
        }
        if (!checkIsCorrectEndTime(startDate, endDate)) {
            return
        }

        const event = {
            competitionId: competition.value,
            broadcasterId: Number(getValues("broadcaster").value) !== 0  ? getValues("broadcaster").value : null,
            decoderId: Number(getValues("decoder").value) !== 0  ? getValues("decoder").value : null,
            channelId: Number(getValues("server").value) !== 0  ? getValues("server").value : null,
            season: season.label,
            round: round.label,
            group,
            description: formatDescription(),
            home,
            away,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            isVerified,
            isInternal,
            isProtected: true,
            sportId: sport.value,
            countryId: region.value
        }

        const mainFilter = {
            sportId: sport?.value,
            regionId: region?.value,
            compId: competition?.value
        }

        const payload = {
            event,
            queryString: getQueryString(mainFilter)
        }

        setCreatedEventsCount(createdEventsCount + 1)

        if (createdEventsCount > futureEventsNum - 1) {
            dispatch(EventsActionCreator.createEvent(payload))
            clearForm()
            setIsOpenModal(false)
            setStartDate(endDate)
            return
        }

        setHome("")
        setAway("")
        setValue("decoder", "")
        setValue("server", "")
        setDateValidation({startDate: true, endDate: true})
        dispatch(EventsActionCreator.createEvent(payload))
        setStartDate(endDate)
        setIsValidEndTime(true)
        return
    }

    const formatOptions = useCallback((options: IInputOption[]) => {
        if (options) {
            const allOptions = options?.map(opt => ({value: opt?.id, label: opt?.name}))
            allOptions.unshift({value: "0", label: "Blank"})
            return allOptions
        } else {
            return []
        }
    }, [])

    const formatStringOptions = useCallback((options: string[]) => {
        if (!options) return [];

        return [{ value: "0", label: "Blank" }, ...options.map(opt => ({ value: opt, label: opt }))];    
    }, [])

    const formatNumber = (number: number) => {
        if (getValues("description")) {
            return (number < 10) ? "0" + number : number
        } else return "01"
    }

    const closeCalendars = () => {
        setIsOpenStartDate(false)
        setIsOpenEndDate(false)
    }

    const eventsCountOptions = (totalCount: number) => {
        const options = []
        for (let i = 1; i <= totalCount; i++) {
            options.push({value: i, label: i})
        }
        return options
    }

    const setNumberOfEventsHandler = (e: SingleValue<any>) => {
        setNumberOfEvents(e)
        setFutureEventsNum(e.value)
    }

    return (
        <div onClick={closeCalendars} className={styles.eventsModal}>
            <form
                onSubmit={handleSubmit(createEvent)}
                className={styles.modalForm}
            >
                <button
                    onClick={() => setIsOpenModal(false)}
                    className={styles.closeBtn}
                />
                <div className={styles.modalTitle}>
                    <Title value={'Create Event'}/>
                </div>
                <div className={styles.addEventWrapper}>
                    <div className={styles.inputsColumn}>
                        <div className={styles.inputWrapper}>
                            <Controller
                                name="broadcaster"
                                control={control}
                                render={({field: {onChange, value}}) => <SelectInput
                                    value={value}
                                    onChange={onChange}
                                    label={'Broadcaster'}
                                    name={'Broadcaster'}
                                    searchable={true}
                                    options={formatOptions(initOptions?.broadcasters?.items)}
                                />}
                            />
                        </div>
                        {errors.broadcaster?.type === "emptyField" &&
                            <p className={styles.formError}>Broadcaster is required field</p>}
                        <div className={styles.inputWrapper}>
                            <Controller
                                name="decoder"
                                control={control}
                                render={({field: {value}}) => <SelectInput
                                    value={value}
                                    onChange={onChangeDecoder}
                                    label={'Decoder'}
                                    name={'Decoder'}
                                    searchable={true}
                                    options={formatOptions(initOptions?.decoders?.items)}
                                />}
                            />
                        </div>
                        {errors.decoder?.type === "emptyField" &&
                            <p className={styles.formError}>Decoder is required field</p>}
                        <div className={styles.inputWrapper}>
                            <Controller
                                name="server"
                                control={control}
                                render={({field: {value}}) => <SelectInput
                                    value={value}
                                    onChange={onChangeServer}
                                    label={'Server'}
                                    name={'Server'}
                                    searchable={true}
                                    options={formatOptions(initOptions?.servers?.items)}
                                />}
                            />
                        </div>
                        {errors.server?.type === "emptyField" &&
                            <p className={styles.formError}>Server is required field</p>}
                        <div className={styles.inputWrapper}>
                            <CreatableSelectInp
                                value={season}
                                onChange={setSeason}
                                label={'Season'}
                                name={'Season'}
                                searchable={true}
                                options={formatStringOptions(initOptions?.seasons)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <CreatableSelectInp
                                value={round}
                                onChange={setRound}
                                label={'Round'}
                                name={'Round'}
                                searchable={true}
                                options={formatStringOptions(initOptions?.rounds)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <Input
                                value={group}
                                setValue={setGroup}
                                label={'Group'}
                                readonly={false}
                            />
                        </div>
                    </div>

                    <div className={styles.inputsColumn}>
                        <div className={styles.inputWrapper}>
                            <SelectInput
                                value={numberOfEvents}
                                onChange={setNumberOfEventsHandler}
                                label={'Number of Events'}
                                name={'Number of Events'}
                                searchable={true}
                                options={eventsCountOptions(20)}
                            />
                        </div>
                        <div className={`${styles.inputWrapper} ${styles.inputDescription}`}>
                            <Controller
                                name="description"
                                control={control}
                                render={({field: {onChange, value}}) => <Input
                                    value={value}
                                    setValue={onChange}
                                    label={'Description'}
                                    readonly={false}
                                />}
                            />
                            {numberOfEvents.value > 0 && <div className={styles.inputDescriptionNumber}>
                                {formatNumber(createdEventsCount)}
                            </div>}
                        </div>
                        <div className={`${styles.inputWrapper} ${styles.homeAwayWrapper}`}>
                            <div className={styles.inputSmallWrapper}>
                                <Input
                                    value={home}
                                    setValue={setHome}
                                    label={'Home'}
                                    readonly={false}
                                />
                            </div>
                            <div className={styles.inputSmallWrapper}>
                                <Input
                                    value={away}
                                    setValue={setAway}
                                    label={'Away'}
                                    readonly={false}
                                />
                            </div>
                        </div>
                        {!descriptionValidation &&
                            <p className={styles.formError}>Description or Home and Away should be fill</p>}
                        <div className={styles.inputWrapper}>
                            <TimeCalendar
                                calendarClass={styles.timeCalendarWrapper}
                                currentDate={startDate}
                                setCurrentDate={setStartDate}
                                label={"Start Date & Time"}
                                isOpen={isOpenStartDate}
                                setIsOpen={setIsOpenStartDate}
                            />
                        </div>
                        {!dateValidation.startDate &&
                            <p className={styles.formError}>Start Date & Time is required field</p>}
                        <div className={styles.inputWrapper}>
                            <TimeCalendar
                                calendarClass={styles.timeCalendarWrapper}
                                currentDate={endDate}
                                setCurrentDate={setEndDate}
                                label={"End Date & Time"}
                                isOpen={isOpenEndDate}
                                setIsOpen={setIsOpenEndDate}
                            />
                        </div>
                        {!dateValidation.endDate &&
                            <p className={styles.formError}>End Date & Time is required field</p>
                        }
                        {!isValidEndTime &&
                            <p className={styles.formError}>End Date can’t early than Start Date</p>
                        }
                        <div className={styles.inputWrapper}>
                            <div className={styles.buttonsWrapper}>
                                <div className={styles.radioControls}>
                                    <div onClick={()=>setIsInternal(!isInternal)} className={styles.radioScheduleBtn}>
                                        <ToggleSwitchBtn
                                            checked={isInternal}
                                        />
                                        <div className={styles.radioScheduleLabel}>
                                            <h6>Internal</h6>
                                            <h6>Schedule</h6>
                                        </div>
                                    </div>
                                    <div onClick={()=>setIsVerified(!isVerified)} className={styles.radioVerifyBtn}>
                                        <ToggleSwitchBtn
                                            checked={isVerified}
                                        />
                                        <div className={styles.radioVerifyLabel}>
                                            <h6>Verify</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.btnGroupWrapper}>
                                    <div className={styles.btnWrapper}>
                                        <Button onClick={clearForm} value={'Reset'}/>
                                    </div>
                                    <div className={`${styles.btnWrapper} ${styles.btnAdd}`}>
                                        <Button
                                            type={"submit"}
                                            value={'Add'}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default React.memo(EventsManualModal);