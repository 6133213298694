import React, {Dispatch, SetStateAction, useEffect} from 'react';
import Title from "../../../../components/Title";
import {Controller, useForm} from "react-hook-form";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import styles from './styles.module.scss'
import {useDispatch} from "react-redux";
import {ChannelsActionCreators} from "../../../../store/reducers/channels/action-creators";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";


interface IChannelsFormProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    editInfo: { isEdit: boolean, channelId: number }
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; channelId: number; }>>
}

const ChannelForm: React.FC<IChannelsFormProps> = ({setIsOpenModal, setEditInfo, editInfo}) => {
    const dispatch = useDispatch()
    const {channels} = useTypedSelector(state => state.channelsReducer)
    const {handleSubmit, control, getValues, reset, setValue, formState: {errors},} = useForm({
        defaultValues: {
            channel: "",
        }
    });

    useEffect(() => {
        if (editInfo.channelId) {

            const currentChannel = channels.find(channel => channel.id === editInfo.channelId)
            if (currentChannel) {
                setValue("channel", currentChannel.name)
                // @ts-ignore
                setValue("duration", currentChannel.duration)
            }
        }
    }, [editInfo]);

    const addChannel = () => {
        const payload = {name: getValues("channel")}
        closeForm()
        dispatch(ChannelsActionCreators.createChannel(payload))
    }

    const updateChannelTimings = () => {
        const payload = {
            id: editInfo.channelId,
            name: getValues("channel")
        }
        closeForm()
        dispatch(ChannelsActionCreators.updateChannel(payload))
    }

    const closeForm = () => {
        setValue("channel","")
        setIsOpenModal(false)
        setEditInfo({isEdit: false, channelId: 0})
    }
    return (
        <div className={styles.channelForm}>
            <form
                onSubmit={editInfo.isEdit ? handleSubmit(updateChannelTimings) : handleSubmit(addChannel)}
                className={styles.modalForm}
            >
                <button
                    onClick={closeForm}
                    className={styles.closeBtn}
                />
                <Title value={editInfo.isEdit ? 'Update Server' : 'Add Server'}/>
                <div className="largeSelect mt15">
                    <Controller
                        name="channel"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Input
                            value={value}
                            setValue={onChange}
                            label={'Server'}
                            readonly={false}
                        />}
                    />
                </div>
                {errors.channel?.type === 'required' && <p className={styles.formError}>Channel is required field</p>}
                <div className={styles.addBtn}>
                    <Button
                        type={"submit"}
                        value={editInfo.isEdit ? 'Update Server' : 'Add Server +'}
                    />
                </div>
            </form>
        </div>
    );
};

export default ChannelForm;
