import {call, takeEvery, put,} from 'redux-saga/effects';
import {FixturesActionEnum, ICreateEventsFromFixtures, IFilterItems, IGetFixtures} from "./types";
import {getFixturesRequest} from "./requests";
import {FixturesActionCreators} from "./action-creators";
import {multiCreateEventsRequest} from "../event/requests";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";

export interface IGetFixturesResp {
    startDate: string;
    endDate: string;
    sports: IFilterItems;
    sportsSelected: string[];
    regions: IFilterItems;
    regionsSelected: string[];
    competitions: IFilterItems;
    competitionsSelected: string[];
    data: any[];
}
function* tryGetFixtures({payload}:IGetFixtures){
    try {
        yield put(FixturesActionCreators.setFixturesLoader(true))
        const fixtures:IGetFixturesResp = yield call(getFixturesRequest,payload)
        yield put(FixturesActionCreators.setFixtures(fixtures))
        yield put(FixturesActionCreators.setFixturesLoader(false))
    }
    catch (e) {
        yield put(FixturesActionCreators.setFixturesLoader(false))
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}
function* tryCreateEventsFromFixtures({payload}: ICreateEventsFromFixtures) {
    try {
        yield call(multiCreateEventsRequest,payload)
        yield put(FixturesActionCreators.createEventsFromFixturesSuccess(payload))
        toastr.success('IGameMedia', 'Events has been created',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

export default function* fixturesSagas(): any {
    yield takeEvery(FixturesActionEnum.GET_FIXTURES, tryGetFixtures);
    yield takeEvery(FixturesActionEnum.CREATE_EVENTS_FROM_FIXTURES, tryCreateEventsFromFixtures);
}