import styles from './input.module.scss'
import React, {Dispatch, InputHTMLAttributes, SetStateAction} from "react";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement>{
    label?: string,
    value: string,
    setValue?:Dispatch<SetStateAction<string>>
    readonly: boolean,
    type?: string
}

const Input: React.FC<IInputProps> = ({label, value, readonly,setValue,type,...props}) => {

    return (
        <div className={styles.inputWrapper}>
            <label
                htmlFor="searchInput"
                className="inputLabel">{label}</label>
            <input
                onChange={(e)=> setValue ? setValue(e.target.value) :null}
                readOnly={readonly}
                className={styles.searchInput}
                type={type}
                value={value}
                {...props}
            />
        </div>
    );
};

export default React.memo(Input);
