import Title from "../../../components/Title";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import AuditFixtureTable from "./auditFixtureTable";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {AuditActionCreators} from "../../../store/reducers/audit/action-creators";
import {toastr} from "react-redux-toastr";


export const AuditFixture =() => {
    const {id} = useParams<{id:string}>()
    const dispatch = useDispatch()
    const [fixtureId, setFixtureId] = useState("")
    useEffect(()=>{
        if (id !== ":id" && id){
            getAuditFixture(id)
            setFixtureId(id)
        }
    },[id])

    const getAuditFixture = (fixtureId:string) => {
        if (fixtureId) {
            dispatch(AuditActionCreators.getAuditFixture(fixtureId))
        }
        else toastr.warning("IGame","Enter fixture id")
    }

    return (
        <div className={'container'}>
            <div className="mt64">
                <Title value={'Fixture Audit Trail'}/>
            </div>
            <div className="flexAlignEnd">
                <div className="largeSelect mt15">
                    <Input
                        setValue={setFixtureId}
                        value={fixtureId}
                        label={'Fixture'}
                        readonly={false}/>
                </div>
                <div className="regularBtn">
                    <Button onClick={()=>getAuditFixture(fixtureId)} value={'View Audit Trail'}/>
                </div>
            </div>
            <div className={'mt60'}>
                <AuditFixtureTable/>
            </div>
        </div>
    )
}

export default React.memo(AuditFixture);
