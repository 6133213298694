import React from "react";
import AuditFixture from "./AuditFixture/AuditFixture";
import {Route, Routes} from "react-router-dom";
import {RouterNames} from "../../route";
import AuditCompetition from "./AuditCompetition/AuditCompetition";
import AuditEvent from "./AuditEvent/AuditEvent";

const Audits: React.FC = () => {
    return (
        <div>
            <Routes>
                <Route
                    path={RouterNames.FIXTURE}
                    element={<AuditFixture/>}
                />
                <Route
                    path={RouterNames.COMPETITION}
                    element={<AuditCompetition/>}
                />
                <Route
                    path={RouterNames.EVENT}
                    element={<AuditEvent/>}
                />
            </Routes>
        </div>
    );
};

export default Audits
