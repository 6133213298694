import {
    BroadcastersActionEnum,
    IBroadcaster,
    IBroadcastersInit,
    ICreateBroadcaster,
    ICreatingBroadcaster,
    IDeleteBroadcaster, IDeleteBroadcasterSuccess,
    IGetBroadcasters,
    ISetBroadcasterInit,
    ISetBroadcasters,
    IUpdateBroadcaster,
    IUpdatedBroadcaster,
} from "./types";

export const BroadcastersActionCreators = {
    getBroadcasters: (queryString:string):IGetBroadcasters => ({type:BroadcastersActionEnum.GET_BROADCASTERS,queryString}),
    setBroadcasters: (payload:IBroadcaster[]):ISetBroadcasters => ({type:BroadcastersActionEnum.SET_BROADCASTERS,payload}),
    setBroadcastersInit: (payload:IBroadcastersInit):ISetBroadcasterInit => ({type:BroadcastersActionEnum.SET_BROADCASTERS_INIT,payload}),
    createBroadcaster:(payload:ICreatingBroadcaster):ICreateBroadcaster => ({type:BroadcastersActionEnum.CRETE_BROADCASTER,payload}),
    updateBroadcaster:(payload:IUpdatedBroadcaster):IUpdateBroadcaster => ({type:BroadcastersActionEnum.UPDATE_BROADCASTER,payload}),
    deleteBroadcaster: (payload: number):IDeleteBroadcaster => ({type: BroadcastersActionEnum.DELETE_BROADCASTER,payload}),
    deleteBroadcasterSuccess: (payload: number):IDeleteBroadcasterSuccess => ({type: BroadcastersActionEnum.DELETE_BROADCASTER_SUCCESS,payload})
}