import {call, takeEvery, put,} from 'redux-saga/effects';
import {
    IChannel,
    ChannelsActionEnum,
    ICreateChannel,
    IUpdateChannel,
    IDeleteChannel,
    IGetChannels,
    IRestoreChannel
} from "./types";
import {ChannelsActionCreators} from "./action-creators";
import {
    createChannelRequest, deleteChannelRequest,
    getChannelsRequest, restoreChannelRequest, updateChannelRequest,
} from "./requests";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";


function* tryGetChannels({payload}: IGetChannels) {
    try {
        const resp: IChannel[] = yield call(getChannelsRequest, payload)
        yield put(ChannelsActionCreators.setChannels(resp))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryCreateChannel({payload}: ICreateChannel) {
    try {
        const resp: IChannel = yield call(createChannelRequest, payload)
        yield put(ChannelsActionCreators.createChannelSuccess(resp))
        toastr.success('IGameMedia', 'Server has been created',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryUpdateChannel({payload}: IUpdateChannel) {
    try {
        const resp: IChannel = yield call(updateChannelRequest, payload)
        yield put(ChannelsActionCreators.updateChannelSuccess(resp))
        toastr.success('IGameMedia', 'Server  has been updated',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryDeleteChannel({payload}: IDeleteChannel) {
    try {
        yield call(deleteChannelRequest, payload)
        yield put(ChannelsActionCreators.deleteChannelSuccess(payload))
        toastr.success('IGameMedia', 'Server has been deleted',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryRestoreChannel({payload}: IRestoreChannel) {
    try {
        yield call(restoreChannelRequest, payload)
        yield put(ChannelsActionCreators.restoreChannelSuccess(payload))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}


export default function* channelsSagas(): any {
    yield takeEvery(ChannelsActionEnum.GET_CHANNELS, tryGetChannels);
    yield takeEvery(ChannelsActionEnum.CREATE_CHANNEL, tryCreateChannel);
    yield takeEvery(ChannelsActionEnum.UPDATE_CHANNEL, tryUpdateChannel);
    yield takeEvery(ChannelsActionEnum.DELETE_CHANNEL, tryDeleteChannel);
    yield takeEvery(ChannelsActionEnum.RESTORE_CHANNEL, tryRestoreChannel);
}