import styles from './style.module.scss'
import Title from "../../../../components/Title";
import Input from "../../../../components/Input";
import SelectInput from "../../../../components/SelectInput";
import ToggleSwitchBtn from "../../../../components/Button/Toggle-Switch";
import Button from "../../../../components/Button";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {BroadcastersActionCreators} from "../../../../store/reducers/broadcasters/action-creators";
import {useForm, Controller} from "react-hook-form";
import {IInputOption} from "../../../../store/reducers/fixtures/types";
import {formatOptions} from "../../../../helpers/formatSelectOptions";

interface IBroadcasterModalProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    editInfo: { isEdit: boolean, broadcasterId: number }
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; broadcasterId: number; }>>
}

const BroadcasterModal: React.FC<IBroadcasterModalProps> = ({setIsOpenModal, editInfo, setEditInfo}) => {
    const {init, broadcasters} = useTypedSelector(state => state.broadcastersReducer)
    const dispatch = useDispatch()
    const [isLatent, setIsLatent] = useState(false)
    const [modalTitle, setModalTitle] = useState("Add Broadcaster")

    const {handleSubmit, control, reset, getValues, setValue, formState: {errors},} = useForm({
        defaultValues: {
            broadcaster: "",
            region: {value: "", label: ""} as any,
            definition: {value: "", label: ""} as any,
            link: ""
        }
    });

    const setDefaultInputValue = () => {
        const broadcasterId = editInfo.broadcasterId
        const broadcaster = broadcasters.find(item => item.id === broadcasterId)
        if (broadcaster) {
            const regionOptions = formatOptions(init.regions.items)
            const definitionOptions = formatOptions(init.definitions.items)
            setValue("region", regionOptions.find(opt => opt.value === broadcaster?.countryId?.toString()))
            setValue("definition", definitionOptions.find(opt => opt.value === broadcaster?.definition?.toString()))
            setValue("broadcaster", broadcaster.name)
            setValue("link", broadcaster.sourceUrl)
            setIsLatent(broadcaster.isLatent)
            setModalTitle("Update Broadcaster")
        }
    }

    useEffect(() => {
        if (editInfo.isEdit) {
            setDefaultInputValue()
        } else {
            clearForm()
            setModalTitle("Add Broadcaster")
        }
    }, [editInfo])


    const clearForm = () => {
        reset()
    }

    const createBroadcaster = () => {
        const payload = {
            name: getValues("broadcaster"),
            countryId: getValues("region").value,
            isLatent,
            definition: Number(getValues("definition").value),
            sourceUrl: getValues("link"),
        }
        setIsOpenModal(false)
        clearForm()
        dispatch(BroadcastersActionCreators.createBroadcaster(payload))
    }

    const updateBroadcaster = () => {
        const payload = {
            id: editInfo.broadcasterId,
            name: getValues("broadcaster"),
            countryId: getValues("region").value,
            isLatent,
            definition: Number(getValues("definition")?.value) ? Number(getValues("definition").value) : null,
            sourceUrl: getValues("link") ? getValues("link") : "",
        }
        closeModal()
        dispatch(BroadcastersActionCreators.updateBroadcaster(payload))
    }

    const closeModal = () => {
        setIsOpenModal(false)
        clearForm()
        setModalTitle("Add Broadcaster")
        setEditInfo({isEdit: false, broadcasterId: 0})
    }
    const isEmptyValidate = (v: any) => {
        return v.value.length > 0
    }
    const setLatent = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        return setIsLatent(!isLatent)
    }

    const formatOptionsWithBlankValue = (options: IInputOption[]) => {
        if (options) {
            const allOptions = options.map(opt => ({value: opt?.id, label: opt?.name}))
            allOptions.unshift({value:"0",label:"Blank"})
            return allOptions
        } else {
            return []
        }
    }
    return (
        <div className={styles.managementModalInner}>
            <form
                onSubmit={editInfo.isEdit ? handleSubmit(updateBroadcaster) : handleSubmit(createBroadcaster)}
                className={styles.modalForm}
            >
                <div className={styles.titleInner}>
                    <Title value={modalTitle}/>
                    <button onClick={closeModal} className={styles.closeBtn}/>
                </div>
                <div className="largeSelect mt15">
                    <Controller
                        name="broadcaster"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) => <Input
                            value={value}
                            setValue={onChange}
                            label={'Broadcaster'}
                            readonly={false}
                        />}
                    />
                </div>
                {errors.broadcaster?.type === 'required' &&
                    <p className={styles.formError}>Broadcaster is required field</p>}
                <div className={styles.mt15}>
                    <Controller
                        name="region"
                        control={control}
                        rules={{
                            validate: {
                                emptyField: v => isEmptyValidate(v),
                            }
                        }}
                        render={({field: {onChange, value}}) => <SelectInput
                            value={value}
                            onChange={onChange}
                            name={'region'}
                            searchable={true}
                            options={formatOptions(init?.regions?.items)}
                            label={'Region'}
                        />}
                    />
                </div>
                {errors.region?.type === "emptyField" && <p className={styles.formError}>Region is required field</p>}
                <div className={styles.mt15}>
                    <Controller
                        name="definition"
                        control={control}
                        render={({field: {onChange, value}}) => <SelectInput
                            name='definition'
                            value={value}
                            onChange={onChange}
                            searchable={true}
                            options={formatOptionsWithBlankValue(init?.definitions?.items)}
                            label={'Definition'}
                        />}
                    />
                </div>
                <div className="largeSelect ">
                    <Controller
                        name="link"
                        control={control}
                        rules={{
                            pattern:/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                        }}
                        render={({field: {onChange, value}}) => <Input
                            value={value}
                            setValue={onChange}
                            label={'Link'}
                            readonly={false}
                        />}
                    />
                </div>
                {errors?.link?.type === "pattern" &&
                    <div className={styles.formError}>Link should be like https://...</div>
                }
                <div className={'columnInputFooter'}>
                    <div onClick={(e) => setLatent(e)} className={styles.switch}>
                        <ToggleSwitchBtn
                            checked={isLatent}
                            label={'Is Latent'}
                            setChecked={setIsLatent}
                        />
                    </div>
                    <div className={styles.createBtn}>
                        <div className="regularBtn">
                            <Button
                                type={"submit"}
                                value={`${modalTitle} +`}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default React.memo(BroadcasterModal);