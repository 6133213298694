import React from "react";
import Select, {MultiValue, SingleValue} from 'react-select';
import './selectInput.scss';


interface ISelectInputProps {
    label: string
    searchable: boolean
    options: any[]
    name: string
    handlerOnChange: (e:MultiValue<any> | SingleValue<any>,name:string) => void
    filterValue?: any
}

const MultiSelectInput: React.FC<ISelectInputProps> = ({ options, searchable, label, handlerOnChange,name,filterValue}) => {
    const handleMultiChange = (e:MultiValue<any> | SingleValue<any>,name:string) => {
        if (handlerOnChange) {
            return handlerOnChange(e, name)
        }
    }

    return (
        <div>
            <label className={'inputLabel'}>{label}</label>
            <Select
                value={filterValue}
                isMulti={true}
                onChange={(e) =>handleMultiChange(e,name)}
                classNamePrefix={'select'}
                options={options}
                isSearchable={searchable}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#DEEBFF',
                        primary50: '#DEEBFF',
                        primary: '#495464',
                        neutral0: 'white'
                    },
                })}
            />
        </div>
    )
}

export default React.memo(MultiSelectInput);
