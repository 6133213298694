import {Dispatch, SetStateAction, useEffect} from "react";
import moment from "moment/moment";
import {IDay} from "react-calendar-datetime-picker/dist/type";
import {formatDate, reFormatDate} from "../../../../helpers/TimePickerHelper";

export const useAutoFillEndDate =  (startTime:IDay | undefined,compId:string,setEndDate:Dispatch<SetStateAction<IDay | undefined>>,sportDuration:number,endTime:IDay | undefined) => {
    const getEndDate = async()=> {
        try {
            const endDate = moment(formatDate(startTime)).add(sportDuration,"minutes").format()
            setEndDate(reFormatDate(endDate))
        }
        catch (e) {
            console.log(e)
        }
    }
    useEffect(() => {
        if (startTime) {
            getEndDate()
        }
    },[startTime])
}