import {client} from "../../../services/api-servise";
import {IGetChannelsPayload} from "./types";

export const getChannelsRequest = async (payload:IGetChannelsPayload|{}): Promise<any> => {
    const decoders = await client.post('/api/Channel/Search', payload)
    return decoders.data
}

export const createChannelRequest = async (payload:{name:string}): Promise<any> => {
    const decoder = await client.post('/api/Channel/Create',payload )
    return decoder.data
}

export const updateChannelRequest = async (payload:{name:string,id:number}): Promise<any> => {
    const decoder = await client.put('/api/Channel/Update',payload )
    return decoder.data
}
export const deleteChannelRequest = async (payload:number): Promise<any> => {
    const channel = await client.delete(`/api/Channel/Delete?channelId=${payload}`)
    return channel.data
}

export const restoreChannelRequest = async (payload:number): Promise<any> => {
    const channel = await client.put(`/api/Channel/Restore?id=${payload}`)
    return channel.data
}





