import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import ChannelTable from "./ChannelTable/ChannelTable";
import ChannelForm from "./ChannelForm/ChannelForm";
import WarningModal from "../../../components/WarningModal/WarningModal";
import {ChannelsActionCreators} from "../../../store/reducers/channels/action-creators";
import styles from './style.module.scss'

const Channel = () => {
    const dispatch = useDispatch()
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [editInfo, setEditInfo] = useState({isEdit: false, channelId: 0})
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)


    useEffect(() => {
       dispatch(ChannelsActionCreators.getChannels({}))
    }, []);

    const openConfirmMenu = () => {
        setIsOpenConfirmModal(true)
    }
    const deleteChannel = () => {
        setEditInfo({isEdit: false, channelId: 0})
        dispatch(ChannelsActionCreators.deleteChannel(editInfo.channelId))
    }

    return (
        <div className={styles.channelPage}>
            {isOpenForm &&
                <ChannelForm
                    setIsOpenModal={setIsOpenForm}
                    editInfo={editInfo}
                    setEditInfo={setEditInfo}
                />
            }
            <ChannelTable
                setIsOpenModal={setIsOpenForm}
                setEditInfo={setEditInfo}
                openConfirmMenu={openConfirmMenu}
            />
            {isOpenConfirmModal &&
                <WarningModal
                    modalText={"Do you want to delete server ?"}
                    action={deleteChannel}
                    setIsOpenModal={setIsOpenConfirmModal}
                />
            }
        </div>
    );
};

export default Channel;
