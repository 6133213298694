import {EventActionEnum, EventsAction, IEventsInfo, IEventsInit, IEventState,} from "./types";

const defaultState: IEventState = {
    events: {} as IEventsInfo,
    init: {} as IEventsInit,
    eventsFixtures: [],
    fixturesCount: 0
}

export const eventsReducer = (state = defaultState, action: EventsAction): IEventState => {
    switch (action.type) {
        case EventActionEnum.SET_EVENTS: {
            const {regions, sports, competitions, events, fixtures, fixturesCount} = action.payload
            const init = {
                sports: {
                    items: sports.items
                },
                regions: {
                    items: regions.items
                },
                competitions: {
                    items: competitions?.items
                }
            }
            return {...state, events: events, init, eventsFixtures: fixtures, fixturesCount}
        }

        case EventActionEnum.DELETE_EVENTS_FIXTURE: {
            const newEventsFixtures = state.eventsFixtures.filter(fixture => {
                return action.payload.every(id => id !== fixture.fixtureId)
            })
            return {...state, eventsFixtures: newEventsFixtures}
        }
        default:
            return state
    }
}