import {
    FixturesActionEnum,
    IClearFilter,
    ICreateEventsFromFixtures,
    ICreateEventsFromFixturesSuccess,
    ISetFixtures,
    ISetFixturesLoader
} from "./types";
import {IGetFixturesResp} from "./saga";

export const FixturesActionCreators = {
    getFixtures:(payload:string) => ({type:FixturesActionEnum.GET_FIXTURES,payload}),
    setFixtures: (fixtures:IGetFixturesResp):ISetFixtures => ({type: FixturesActionEnum.SET_FIXTURES, payload: fixtures}),
    setFixturesFilter: (payload:{options:any[],name:string}) => ({type: FixturesActionEnum.SET_FIXTURE_FILTER,payload}),
    setFilterDateRange: (payload: Range):{ payload: Range; type: FixturesActionEnum.SET_FILTER_DATE_RANGE } => ({type: FixturesActionEnum.SET_FILTER_DATE_RANGE,payload}),
    setFixturesLoader:(payload:boolean):ISetFixturesLoader => ({type:FixturesActionEnum.SET_FIXTURES_LOADER,payload}),
    clearFixturesFilter: ():IClearFilter => ({type:FixturesActionEnum.CLEAR_FIXTURES_FILTER}),
    createEventsFromFixtures:(payload:string[]):ICreateEventsFromFixtures=>({type:FixturesActionEnum.CREATE_EVENTS_FROM_FIXTURES,payload}),
    createEventsFromFixturesSuccess:(payload:string[]):ICreateEventsFromFixturesSuccess=>({type:FixturesActionEnum.CREATE_EVENTS_FROM_FIXTURES_SUCCESS,payload})
}