import {call, takeEvery, put,} from 'redux-saga/effects';
import {
    DecodersActionEnum,
    ICreateDecoder,
    IDecoder,
    IDeleteDecoder, IGetDecoders,
    IToggleDeleteDecoder,
    IUpdateDecoder
} from "./types";
import {DecodersActionCreators} from "./action-creators";
import {
    createDecoderRequest,
    deleteDecoderRequest,
    getDecodersRequest,
    toggleDeleteDecoderRequest,
    updateDecoderRequest
} from "./requests";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";

interface IGetDecodersResp {
    decoders: IDecoder[]
}

function* tryGetDecoders({queryString}:IGetDecoders) {
    try {
        const resp: IGetDecodersResp = yield call(getDecodersRequest,queryString)
        yield put(DecodersActionCreators.setDecoders(resp.decoders))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryCreateDecoder({payload}: ICreateDecoder) {
    try {
        yield call(createDecoderRequest, payload)
        yield put(DecodersActionCreators.getDecoders(""))
        toastr.success('IGameMedia', 'Decoder has been created',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryUpdateDecoder({payload}: IUpdateDecoder) {
    try {
        yield call(updateDecoderRequest, payload)
        yield put(DecodersActionCreators.getDecoders(""))
        toastr.success('IGameMedia', 'Decoder has been updated',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryDeleteDecoder({payload}: IDeleteDecoder) {
    try {
        yield call(deleteDecoderRequest, payload)
        yield put(DecodersActionCreators.deleteDecoderSuccess(payload))
        toastr.success('IGameMedia', 'Decoder has been deleted',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryToggleDeleteDecoder({payload}: IToggleDeleteDecoder) {
    try {
        const decoder: IDecoder = yield call(toggleDeleteDecoderRequest, payload)
        yield put(DecodersActionCreators.toggleDeleteDecoderSuccess(decoder))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

export default function* decoderSagas(): any {
    yield takeEvery(DecodersActionEnum.GET_DECODERS, tryGetDecoders);
    yield takeEvery(DecodersActionEnum.CREATE_DECODER, tryCreateDecoder);
    yield takeEvery(DecodersActionEnum.UPDATE_DECODER, tryUpdateDecoder);
    yield takeEvery(DecodersActionEnum.DELETE_DECODER, tryDeleteDecoder);
    yield takeEvery(DecodersActionEnum.TOGGLE_DELETE_DECODER, tryToggleDeleteDecoder);
}