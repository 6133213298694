import React, {useEffect, useState} from "react";
import DecodersTable from "./DecodersTable/DecodersTable";
import styles from './style.module.scss'
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {DecodersActionCreators} from "../../../store/reducers/decoders/action-creators";
import DecodersModal from "./DecodersModal/DecodersModal";
import WarningModal from "../../../components/WarningModal/WarningModal";
import {useDecodersSearch} from "./DecodersHooks/useDecodersSearch";

const Decoders: React.FC = () => {
    const dispatch = useDispatch();
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [editInfo, setEditInfo] = useState({isEdit: false, decoderId: 0})
    const {decoders} = useTypedSelector(state => state.decodersReducer)
    const {findDecoderByName,searchResult,searchValue} = useDecodersSearch(decoders)

    const [isOpenConfirm, setIsOpenConfirm] = useState(false)

    useEffect(() => {
        dispatch(DecodersActionCreators.getDecoders(""))
    }, [])

    const deleteDecoder = () => {
        dispatch(DecodersActionCreators.deleteDecoder(editInfo.decoderId))
    }

    const openConfirmMenu = () => {
        setIsOpenConfirm(true)
    }

    return (
        <div>
            <div className={styles.decoder}>
                {isOpenModal &&
                <DecodersModal
                    setIsOpenModal={setIsOpenModal}
                    setEditInfo={setEditInfo}
                    editInfo={editInfo}
                />
                }
                <div className={styles.decodersTable}>
                    <DecodersTable
                        searchValue={searchValue}
                        findDecoderByName={findDecoderByName}
                        searchResult={searchResult}
                        openConfirmMenu={openConfirmMenu}
                        setIsOpenModal={setIsOpenModal}
                        setEditInfo={setEditInfo}
                        decoders={decoders}
                    />
                </div>
            </div>
            {isOpenConfirm &&
            <WarningModal
                modalText={"Do you want to delete decoder ?"}
                action={deleteDecoder}
                setIsOpenModal={setIsOpenConfirm}
            />
            }
        </div>
    );
};

export default React.memo(Decoders);
