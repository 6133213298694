import React, {Dispatch, useRef, useState} from "react";
import styles from './scheduleTable.module.scss'
import ScheduleTableAccordion from "./ScheduleTableAccordion/ScheduleTableAccordion";
import moment from "moment/moment";
import {IGroupedSchedule} from "../../../store/reducers/schedule/saga";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import Loader from "../../../components/Loader/Loader";
import {ISchedule} from "../../../store/reducers/schedule/types";
import ContextMenu from "../ContextMenu/ContextMenu";
import filterSvg from '../../../img/filter.svg'
import {MultiValue} from "react-select";
import TableFilter from "../TableFilter/TableFilter";
import {filtersFields, IFiltersState} from "../ScheduleHooks/useTableFilters";
import {useKeyboardButton} from "../ScheduleHooks/useKeyboardButton";
import {ICellInfo, ScheduleSortingField} from "../Schedule";


interface IScheduleTableProps {
    items: IGroupedSchedule[]
    setIsOpenMenu: (flag: boolean) => void
    editCellInfo: ICellInfo
    setEditCellInfo: Dispatch<React.SetStateAction<{
        eventId: number,
        compId: string,
        fixtureId: string,
        event: ISchedule | null
    }>>
    createSortingString: (sortingField: ScheduleSortingField) => void
    sortingFieldsProps: {
        StartTime: boolean,
        CompetitionName: boolean;
        ChannelName: boolean;
        DecoderName: boolean;
        Region: boolean;
        Sport: boolean;
    }
    currentSortingField: string
    isOpenMenu: boolean
    isOpenMailForm: boolean
    openEditModal: () => void
    setIsOpenMailForm: Dispatch<React.SetStateAction<boolean>>
    toggleAccordion: (id: string) => void
    checkIsExpanded: (id: string) => boolean
    filtersState: IFiltersState
    handleTableFilters: (option: MultiValue<{ value: string, label: string }>, filterName: string) => void
    editCellInfoGroup: ICellInfo[] | null
    setEditCellInfoGroup: Dispatch<React.SetStateAction<ICellInfo[] | null>>
    editableEvent: number
    setEditableEvent: Dispatch<React.SetStateAction<number>>
}

const ScheduleTable: React.FC<IScheduleTableProps> = ({
                                                          items,
                                                          setIsOpenMenu,
                                                          setEditCellInfo,
                                                          editCellInfo,
                                                          createSortingString,
                                                          currentSortingField,
                                                          sortingFieldsProps,
                                                          isOpenMenu,
                                                          isOpenMailForm,
                                                          setIsOpenMailForm,
                                                          openEditModal,
                                                          checkIsExpanded,
                                                          toggleAccordion,
                                                          filtersState,
                                                          handleTableFilters,
                                                          editCellInfoGroup,
                                                          setEditCellInfoGroup,
                                                          editableEvent,
                                                          setEditableEvent
                                                      }) => {
    const {
        isLoading,
        broadcasters,
        servers,
        decoders,
        countries,
        competitions,
        sportsFilter,
    } = useTypedSelector(state => state.scheduleReducer);

    const contextMenuRef = useRef<HTMLDivElement>(null);
    const [isFilterOpen, setIsFilterOpen] = useState({
        broadcaster: false,
        server: false,
        decoder: false,
        sport: false,
        competition: false,
        country: false
    });
    const {isCommandTab} = useKeyboardButton();

    const formatGroupByTitle = (title: string) => {
        if (title?.includes("Decoder")) {
            return title
        }
        if (moment(title).isValid()) {
            return moment(title).format("YYYY-MM-DD")
        } else {
            return title
        }
    }

    const outsideClickTableClick = () => {
        setEditCellInfo({
            eventId: 0,
            compId: "",
            fixtureId: "",
            event: {} as ISchedule | null
        })
        setEditableEvent(0)
        setEditCellInfoGroup(null);
    }

    const handleOpenFilter = (filterName: string, isOpenFilter: boolean) => {
        setIsFilterOpen({...isFilterOpen, [filterName]: !isOpenFilter})
    }

    return (
        <div>
            <div ref={contextMenuRef} className={styles.scheduleMenu}>
                {isOpenMenu && !isOpenMailForm &&
                    <ContextMenu
                        setIsOpenMenu={setIsOpenMenu}
                        setEditCellInfo={setEditCellInfo}
                        setIsOpenEditModal={openEditModal}
                        setIsOpenMailForm={setIsOpenMailForm}
                        editCellInfo={editCellInfo}
                    />}
            </div>
            <div className={styles.wrapper}>
                {isLoading && <Loader/>}
                <table onClick={() => outsideClickTableClick()} className={styles.auditTable}>
                    <thead className={styles.tableHead}>
                    <tr className={styles.tableRow} key={1}>
                        <th
                            onClick={() => createSortingString("StartTime")}
                            className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                            Start Time
                        </th>
                        <th className={styles.tableHeader}>End Time</th>

                        <th className={styles.colHeader}>
                            <span className={styles.tableHeader}>Broadcaster</span>
                            <img src={filterSvg}
                                 alt="filterSvg"
                                 className={styles.filterSvg}
                                 onClick={() => handleOpenFilter(filtersFields.broadcaster, isFilterOpen.broadcaster)}
                            />
                            {isFilterOpen.broadcaster &&
                                <TableFilter
                                    filterLabel={'Broadcaster'}
                                    filterName={filtersFields.broadcaster}
                                    filterOptions={broadcasters}
                                    filterValue={filtersState.broadcaster}
                                    setIsFilterOpen={() => handleOpenFilter(filtersFields.broadcaster, isFilterOpen.broadcaster)}
                                    handleTableFilters={handleTableFilters}/>
                            }
                        </th>
                        <th className={styles.colHeader}>
                            <span onClick={() => createSortingString("ChannelName")}
                                  className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                Server
                            </span>
                            <img src={filterSvg} alt="filterSvg"
                                 className={styles.filterSvg}
                                 onClick={() => handleOpenFilter(filtersFields.server, isFilterOpen.server)}
                            />
                            {isFilterOpen.server &&
                                <TableFilter
                                    filterLabel={'Server'}
                                    filterName={filtersFields.server}
                                    filterOptions={servers}
                                    setIsFilterOpen={() => handleOpenFilter(filtersFields.server, isFilterOpen.server)}
                                    filterValue={filtersState.server}
                                    handleTableFilters={handleTableFilters}/>
                            }
                        </th>
                        <th className={styles.colHeader}>
                            <span onClick={() => createSortingString("DecoderName")}
                                  className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                             Decoder
                            </span>
                            <img src={filterSvg} alt="filterSvg"
                                 className={styles.filterSvg}
                                 onClick={() => handleOpenFilter(filtersFields.decoder, isFilterOpen.decoder)}
                            />

                            {isFilterOpen.decoder &&
                                <TableFilter
                                    filterLabel={'Decoder'}
                                    filterName={filtersFields.decoder}
                                    filterOptions={decoders}
                                    setIsFilterOpen={() => handleOpenFilter(filtersFields.decoder, isFilterOpen.decoder)}
                                    filterValue={filtersState.decoder}
                                    handleTableFilters={handleTableFilters}/>
                            }
                        </th>
                        <th className={styles.colHeader}>
                            <span onClick={() => createSortingString("Sport")}
                                  className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                Sport
                            </span>
                            <img src={filterSvg} alt="filterSvg"
                                 className={styles.filterSvg}
                                 onClick={() => handleOpenFilter(filtersFields.sport, isFilterOpen.sport)}
                            />

                            {isFilterOpen.sport &&
                                <TableFilter
                                    filterLabel={'Sport'}
                                    filterName={filtersFields.sport}
                                    filterOptions={sportsFilter}
                                    setIsFilterOpen={() => handleOpenFilter(filtersFields.sport, isFilterOpen.sport)}
                                    filterValue={filtersState.sport}
                                    handleTableFilters={handleTableFilters}/>
                            }
                        </th>
                        <th className={styles.colHeader}>
                            <span onClick={() => createSortingString("CompetitionName")}
                                  className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                       Competition
                                      </span>
                            <img src={filterSvg} alt="filterSvg"
                                 className={styles.filterSvg}
                                 onClick={() => handleOpenFilter(filtersFields.competition, isFilterOpen.competition)}
                            />

                            {isFilterOpen.competition &&
                                <TableFilter
                                    filterLabel={'Competition'}
                                    filterName={filtersFields.competition}
                                    filterOptions={competitions}
                                    setIsFilterOpen={() => handleOpenFilter(filtersFields.competition, isFilterOpen.competition)}
                                    filterValue={filtersState.competition}
                                    handleTableFilters={handleTableFilters}/>
                            }
                        </th>
                        <th className={styles.colHeader}>
                            <span onClick={() => createSortingString("Region")}
                                  className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                Country
                            </span>
                            <img src={filterSvg} alt="filterSvg"
                                 className={styles.filterSvg}
                                 onClick={() => handleOpenFilter(filtersFields.country, isFilterOpen.country)}
                            />
                            {isFilterOpen.country &&
                                <TableFilter
                                    filterLabel={'Country'}
                                    filterName={filtersFields.country}
                                    filterOptions={countries}
                                    setIsFilterOpen={() => handleOpenFilter(filtersFields.country, isFilterOpen.country)}
                                    filterValue={filtersState.country}
                                    handleTableFilters={handleTableFilters}/>
                            }
                        </th>
                        <th className={styles.tableHeader}>Home Team</th>
                        <th className={styles.tableHeader}>Away Team</th>
                        <th className={styles.tableHeader}>Information</th>
                    </tr>
                    </thead>
                    {
                        items?.map((item, index) => {
                            if (item.schedules.length > 0) {
                                return (
                                    <ScheduleTableAccordion
                                        id={item.schedules[0].eventId.toString()}
                                        checkIsExpanded={checkIsExpanded}
                                        toggleAccordion={toggleAccordion}
                                        editableEvent={editableEvent}
                                        setEditableEvent={setEditableEvent}
                                        contextMenuRef={contextMenuRef}
                                        key={index + 1}
                                        editCellInfo={editCellInfo}
                                        setEditCellInfo={setEditCellInfo}
                                        setIsOpenMenu={setIsOpenMenu}
                                        events={item.schedules}
                                        date={formatGroupByTitle(item.groupBy)}
                                        editCellInfoGroup={editCellInfoGroup}
                                        setEditCellInfoGroup={setEditCellInfoGroup}
                                        isCommandTab={isCommandTab}
                                    />
                                )
                            }
                        })
                    }
                </table>
            </div>
        </div>
    );
};

export default React.memo(ScheduleTable);
