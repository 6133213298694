import axios from "axios";

export const auth = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Encoding': 'identity'
    },
    baseURL: process.env.REACT_APP_API_ENDPOINT
})



