import {client} from "../../../services/api-servise";
import {IUpdatedEvent} from "./types";

export const getEventsRequest = async (queryString:string): Promise<any> => {
    const events = await client.get(`/api/Management/events?${queryString}`)
    return events.data
}

export const createEventRequest = async(event:any):Promise<any>=> {
    const events = await client.post("/api/Event",event)
    return events.data
}

export const multiCreateEventsRequest = async(payload:string[]):Promise<any>=> {
    const events = await client.post("/api/Event/Bulk",payload)
    return events.data
}

export const updateEventRequest = async(event:IUpdatedEvent):Promise<any>=> {
    const events = await client.put("/api/Event",event)
    return events.data
}

export const deleteEventRequest = async(eventId:number):Promise<any>=> {
    const deletedEvent = await client.delete(`/api/Event?eventId=${eventId}`)
    return deletedEvent.data
}




