import Login from "../pages/Login/Login";
import Audits from "../pages/Audits/Audits";
import Schedule from "../pages/Schedule/Schedule";
import Management from "../pages/Management/Management";
import Fixtures from "../pages/Fixtures/Fixtures";
import User from "../pages/User/User";
import React from "react";


export interface IRoute {
    path: string
    element: React.FC
}

export enum RouterNames {
    SCHEDULE = "schedule",
    MANAGEMENT = "management/*",
    FIXTURES = "fixtures",
    AUDITS = "audits/*",
    DECODERS = "decoders",
    BROADCASTERS = "broadcasters",
    COMPETITIONS = "competitions",
    EVENTS = "events",
    FIXTURE = "fixture/:id",
    COMPETITION = "competition/:id",
    EVENT = "event/:id",
    USER = "user/*",
    USERMANAGEMENT = "userManagement",
    SPORT_TIMINGS = "sport-timings",
    SERVERS = "servers"
}

export const privateRouters: IRoute[] = [
    {path: RouterNames.SCHEDULE, element: Schedule},
    {path: RouterNames.MANAGEMENT, element: Management},
    {path: RouterNames.FIXTURES, element: Fixtures},
    {path: RouterNames.AUDITS, element: Audits},
    {path: RouterNames.USER, element: User},
]


