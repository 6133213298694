import moment from "moment/moment";
import {addDays} from "date-fns";
import {Day} from "react-calendar-datetime-picker";

interface IRangeDate {
    from: Day,
    to: Day
}

export const getDefaultDateRange = (): IRangeDate => {
    const dateFrom = new Date()
    const dateTo = new Date(moment(addDays(dateFrom, 3)).toDate())
    return {
        from: {
            year: dateFrom.getFullYear(),
            month: dateFrom.getMonth() + 1,
            day: dateFrom.getDate()
        }, to: {
            year: dateTo.getFullYear(),
            month: dateTo.getMonth() + 1,
            day: dateTo.getDate()
        }
    }
}
