import styles  from './style.module.scss'
import {useNavigate} from "react-router-dom";
import React from "react";

interface IFixtureContextMenuProps {
    selectedFixtureInfo:{fixtureId:string,competitionId:string};
}
const FixtureContextMenu:React.FC<IFixtureContextMenuProps> = ({selectedFixtureInfo}) => {
    const navigate = useNavigate()
    return (
        <div className={styles.fixtureContextMenu}>
           <ul className={styles.linksList}>
               {selectedFixtureInfo.fixtureId &&<li
                   onClick={() => navigate(`/audits/fixture/${selectedFixtureInfo.fixtureId}`)}
                   className={styles.link}>Fixture Audit Trail
               </li>}
               <li
                   onClick={()=>navigate(`/audits/competition/${selectedFixtureInfo.competitionId}`)}
                   className={styles.link}>Competition Audit Trail
               </li>
           </ul>
        </div>
    )
}


export default React.memo(FixtureContextMenu);