import {useState} from "react";
import {ScheduleSortingField} from "../Schedule";

export const useScheduleSorting = () => {
    const [currentSortingField, setCurrentSortingField] = useState("")
    const [sortingString, setSortingString] = useState("")
    const [sortingFieldsProps, setSortingFieldsProps] = useState({
        StartTime: true,
        CompetitionName: true,
        ChannelName: true,
        DecoderName: true,
        Region: true, Sport: true
    })
    const createSortingString = (sortingField: ScheduleSortingField) => {
        setCurrentSortingField(sortingField)
        const ascending = sortingFieldsProps[sortingField]
        if (currentSortingField === sortingField) {
            const sortingString = `&Sorting.Property=${sortingField}&Sorting.IsAscending=${!ascending}`
            setSortingFieldsProps({...sortingFieldsProps, [sortingField]: !sortingFieldsProps[sortingField]})
            return setSortingString(sortingString)
        }
        const sortingString = `&Sorting.Property=${sortingField}&Sorting.IsAscending=${ascending}`
        setSortingString(sortingString)
    }
    return {createSortingString, sortingString, currentSortingField, sortingFieldsProps}
}
