import {
    AuditActionEnum,
    ICompetitionAudit,
    IEventAudit, IFixtureAudit,
    IGetAuditFixture,
    IGetCompetitionAudit,
    IGetEventAudit,
    ISetCompetitionAudit, ISetEventAudit, ISetFixtureAudit
} from "./types";

export const AuditActionCreators = {
    getAuditFixture: (fixtureId:string):IGetAuditFixture => ({type: AuditActionEnum.GET_AUDIT_FIXTURE,fixtureId}),
    getCompetitionAudit: (competitionId:string):IGetCompetitionAudit => ({type: AuditActionEnum.GET_COMPETITION_AUDIT,competitionId}),
    getEventAudit: (eventId:string):IGetEventAudit => ({type: AuditActionEnum.GET_EVENT_AUDIT,eventId}),
    setFixtureAudit: (payload:IFixtureAudit[]):ISetFixtureAudit => ({type: AuditActionEnum.SET_FIXTURE_AUDIT,payload}),
    setEventAudit: (payload:IEventAudit[]):ISetEventAudit => ({type: AuditActionEnum.SET_EVENT_AUDIT,payload}),
    setCompetitionAudit: (payload:ICompetitionAudit[]):ISetCompetitionAudit => ({type: AuditActionEnum.SET_COMPETITION_AUDIT,payload}),
}