import {
    CompetitionsActionEnum,
    ICompetition,
     ICompetitionsInit,
    ICreateCompetition, ICreatedCompetition,
    IGetCompetition,
    ISetCompetitions, ISetCompetitionsInit, IUpdateCompetition, IUpdatedCompetition
} from "./types";

export const CompetitionsActionCreators = {
    getCompetition: (queryString:string):IGetCompetition => ({type:CompetitionsActionEnum.GET_COMPETITIONS,queryString}),
    setCompetitions: (payload:ICompetition[]):ISetCompetitions => ({type:CompetitionsActionEnum.SET_COMPETITIONS,payload}),
    setCompetitionsInit: (payload:ICompetitionsInit):ISetCompetitionsInit => ({type:CompetitionsActionEnum.SET_COMPETITIONS_INIT,payload}),
    createCompetition:(payload:ICreatedCompetition):ICreateCompetition => ({type:CompetitionsActionEnum.CREATE_COMPETITION,payload}),
    updateCompetition:(payload:IUpdatedCompetition):IUpdateCompetition => ({type:CompetitionsActionEnum.UPDATE_COMPETITION,payload})
}