import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styles from './style.module.scss'
import Input from "../Input";
import Button from "../Button";
interface IPaginationProps {
    setPageNumber: Dispatch<SetStateAction<number>>
    pageNumber: number
    totalPages:number
}
const Pagination:React.FC<IPaginationProps> = ({pageNumber,setPageNumber,totalPages}) => {

    const [paginationValue, setPaginationValue] = useState(`${pageNumber} / ${totalPages}`)

    useEffect(() => {
        if (totalPages) {
            setPaginationValue(`${pageNumber} / ${totalPages}`)
        }
    })

    const setNextPage = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1)
            setPaginationValue(`${pageNumber + 1} / ${totalPages}`)
            return
        }
    }

    const setPrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
            setPaginationValue(`${pageNumber - 1} / ${totalPages}`)
            return
        }
    }
    return (
        <div className={styles.footer}>
            <div className={styles.footerPagination}>
                <div className={styles.footerInput}>
                    <Input
                        setValue={setPaginationValue}
                        readonly={true}
                        value={paginationValue}
                    />
                </div>
                <div className={styles.footerPaginationBtn}>
                    <Button onClick={setPrevPage} value={'<'}/>
                </div>
                <div className={styles.footerPaginationBtn}>
                    <Button onClick={setNextPage} value={'>'}/>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Pagination);