import {IInputOption} from "../fixtures/types";


export interface ICompetition {
    id:string
    name:string
    futureEventsCount:number
    countryId:string
    belongsToWomen:boolean
    type:number
    runningTimeInMinutes:number
    lastUpdated:string
    lastModifierUserId:number
    sportId:string
    sportName:string
    countryName:string
}

export interface ICreatedCompetition {
    newCompetition: {
        sportId: string,
        countryId: string,
        type: number,
        name: string,
        belongsToWomen: boolean,
        runningTime: string
    }
    queryString:string
}

export interface IUpdatedCompetition {
    newCompetition: {
        id: string
        sportId: string,
        countryId: string,
        type: number,
        name: string,
        belongsToWomen: boolean,
        runningTime: string
    }
    queryString:string
}

export interface ICompetitionsInit {
    sports: {
        items: IInputOption[]
    },
    regions: {
        items: IInputOption[]
    }
    competitionTypes: {
        items: IInputOption[]
    }

}

export interface ICompetitionsState {
    competitions: ICompetition[]
    init: ICompetitionsInit
}

export enum CompetitionsActionEnum {
    GET_COMPETITIONS = "GET_COMPETITIONS",
    SET_COMPETITIONS = "SET_COMPETITIONS",
    CREATE_COMPETITION = "CREATE_COMPETITION",
    UPDATE_COMPETITION = "UPDATE_COMPETITION",
    SET_COMPETITIONS_INIT = "SET_COMPETITIONS_INIT",
}

export interface IGetCompetition {
    type: CompetitionsActionEnum.GET_COMPETITIONS
    queryString: string
}

export interface ISetCompetitions {
    type: CompetitionsActionEnum.SET_COMPETITIONS
    payload: ICompetition[]
}

export interface ICreateCompetition {
    type: CompetitionsActionEnum.CREATE_COMPETITION
    payload: ICreatedCompetition
}
export interface IUpdateCompetition {
    type: CompetitionsActionEnum.UPDATE_COMPETITION
    payload: IUpdatedCompetition
}

export interface ISetCompetitionsInit {
    type: CompetitionsActionEnum.SET_COMPETITIONS_INIT
    payload: ICompetitionsInit
}


export type CompetitionsAction = ISetCompetitions | ISetCompetitionsInit