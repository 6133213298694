import {call, takeEvery, put,} from 'redux-saga/effects';
import {
    ICancelEvent,
    IDuplicateEvent,
    IEditEvent,
    IEmailTemplateInit,
    IExpireEvent,
    IGetFilersOptionsResp,
    IGetSchedule,
    IRemoveEvent,
    ISchedule,
    ISendEmail,
    ISetEndDate,
    ISetInternal,
    ISetIsVerify,
    ISetProtection, IUpdateSeveralEvents,
    ScheduleActionEnum
} from "./types";
import {ScheduleActionCreators} from "./action-creators";
import {
    cancelEventRequest, duplicateEventRequest, expireEventRequest, getEmailTemplateRequest, getFiltersOptions,
    getScheduleRequest, removeEventRequest, sendEmailRequest, setEventEndDateRequest,
    setInternalRequest,
    setProtectionRequest,
    setVerifyRequest, updateSeveralEvents
} from "./requests";
import {IInputOption} from "../fixtures/types";
import {toastr} from "react-redux-toastr";
import {updateEventRequest} from "../event/requests";
import {AxiosError} from "axios";


export interface IGroupedSchedule {
    groupBy: string,
    schedules: ISchedule[]
}

export interface IGetScheduleResp {
    groupedSchedules: IGroupedSchedule[]
    sports: {
        items: IInputOption[]
    },
    groupByChoices: {
        items: IInputOption[]
    }
}

function* tryGetSchedule({queryString}: IGetSchedule) {
    try {
        yield put(ScheduleActionCreators.setScheduleLoader(true))
        const schedules: IGetScheduleResp = yield call(getScheduleRequest, queryString)
        yield put(ScheduleActionCreators.setSchedulesFixtures(schedules))
        yield put(ScheduleActionCreators.setScheduleLoader(false))
    } catch (e) {
        yield put(ScheduleActionCreators.setScheduleLoader(false))
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* trySetProtection({payload}: ISetProtection) {
    try {
        const resp: ISchedule = yield call(setProtectionRequest, payload)
        yield put(ScheduleActionCreators.updateEvent(resp))
        toastr.success('IGameMedia', 'Set protection completed',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* trySetVerify({payload}: ISetIsVerify) {
    try {
        const resp: ISchedule = yield call(setVerifyRequest, payload)
        yield put(ScheduleActionCreators.updateEvent(resp))
        toastr.success('IGameMedia', 'Set verify completed',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* trySetInternal({payload}: ISetInternal) {
    try {
        const resp: ISchedule = yield call(setInternalRequest, payload)
        yield put(ScheduleActionCreators.updateEvent(resp))
        toastr.success('IGameMedia', 'Set internal completed',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryCancelEvent({payload}: ICancelEvent) {
    try {
        const resp: ISchedule = yield call(cancelEventRequest, payload)
        yield put(ScheduleActionCreators.updateEvent(resp))
        toastr.success('IGameMedia', 'Cancel completed',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryExpireEvent({payload}: IExpireEvent) {
    try {
        const resp: ISchedule = yield call(expireEventRequest, payload)
        yield put(ScheduleActionCreators.updateEvent(resp))
        toastr.success('IGameMedia', 'Expire completed',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryRemoveEvent({payload}: IRemoveEvent) {
    try {
        const resp: ISchedule = yield call(removeEventRequest, payload)
        yield put(ScheduleActionCreators.removeSuccess(resp.eventId))
        toastr.success('IGameMedia', 'Remove completed',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryDuplicateEvent({payload}: IDuplicateEvent) {
    try {
        const resp: ISchedule = yield call(duplicateEventRequest, payload)
        yield put(ScheduleActionCreators.duplicateEventSuccess(resp))
        toastr.success('IGameMedia', 'Duplicate completed',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* trySetEventEndDate({payload}: ISetEndDate) {
    try {
        const resp: ISchedule = yield call(setEventEndDateRequest, payload)
        if (resp) {
            yield put(ScheduleActionCreators.updateEvent(resp))
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryGetEmailTemplate() {
    try {
        const resp: IEmailTemplateInit = yield call(getEmailTemplateRequest)
        yield put(ScheduleActionCreators.setEmailTemplate(resp))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* trySendEmail({payload}: ISendEmail) {
    try {
        yield call(sendEmailRequest, payload)
        toastr.success('IGameMedia', 'Email sent',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryEditEvent({payload}: IEditEvent) {
    try {
        const resp: ISchedule = yield call(updateEventRequest, payload)
        yield put(ScheduleActionCreators.updateEvent(resp))
        toastr.success('IGameMedia', 'Event has been updated',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}


function* tryEditSeveralEvents({payload}: IUpdateSeveralEvents) {
    try {
        const resp: ISchedule[] = yield call(updateSeveralEvents, payload);
        yield put(ScheduleActionCreators.setSeveralEvents(resp));
        toastr.success('IGameMedia', 'Events has been updated',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryGetFiltersOptions() {
    try {
        yield put(ScheduleActionCreators.setScheduleLoader(true))
        const options: IGetFilersOptionsResp = yield call(getFiltersOptions)
        yield put(ScheduleActionCreators.setFiltersOptions(options))
        yield put(ScheduleActionCreators.setScheduleLoader(false))
    } catch (e) {
        yield put(ScheduleActionCreators.setScheduleLoader(false))
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

export default function* scheduleSaga(): any {
    yield takeEvery(ScheduleActionEnum.GET_SCHEDULED_FIXTURES, tryGetSchedule);
    yield takeEvery(ScheduleActionEnum.SET_IS_VERIFY, trySetVerify);
    yield takeEvery(ScheduleActionEnum.SET_PROTECTION, trySetProtection);
    yield takeEvery(ScheduleActionEnum.SET_INTERNAL, trySetInternal);
    yield takeEvery(ScheduleActionEnum.CANCEL_EVENT, tryCancelEvent);
    yield takeEvery(ScheduleActionEnum.EXPIRE_EVENT, tryExpireEvent);
    yield takeEvery(ScheduleActionEnum.REMOVE_EVENT, tryRemoveEvent);
    yield takeEvery(ScheduleActionEnum.DUPLICATE_EVENT, tryDuplicateEvent);
    yield takeEvery(ScheduleActionEnum.SET_END_DATE, trySetEventEndDate);
    yield takeEvery(ScheduleActionEnum.GET_EMAIL_TEMPLATE, tryGetEmailTemplate);
    yield takeEvery(ScheduleActionEnum.SEND_EMAIL, trySendEmail);
    yield takeEvery(ScheduleActionEnum.EDIT_SCHEDULE_EVENT, tryEditEvent);
    yield takeEvery(ScheduleActionEnum.GET_FILTERS_OPTIONS, tryGetFiltersOptions);
    yield takeEvery(ScheduleActionEnum.EDIT_SEVERAL_SCHEDULE_EVENTS, tryEditSeveralEvents);
}
