import styles from './managementTable.module.scss';
import React, {Dispatch, SetStateAction, useState} from "react";
import Title from "../../../components/Title";
import Checkbox from "../../../components/Checkbox";
import {IBroadcaster} from "../../../store/reducers/broadcasters/types";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import {BroadcastersActionCreators} from "../../../store/reducers/broadcasters/action-creators";
import {useDispatch} from "react-redux";
import {ReactComponent as Sort} from "../../../img/tableSort.svg";

interface ITableProps {
    broadcasters: IBroadcaster[]
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; broadcasterId: number; }>>
    openConfirmMenu: () => void
    searchResult: IBroadcaster[]
    findBroadcasterByName: (event: React.ChangeEvent<HTMLInputElement>) => void
    searchValue: string
}

const ManagementTable: React.FC<ITableProps> = ({
        broadcasters,
        setIsOpenModal,
        setEditInfo,
        openConfirmMenu,
        findBroadcasterByName,
        searchResult,
        searchValue,
    }) => {
    const dispatch = useDispatch()
    const [sortingState, setSortingState] = useState([{field: "name", asc: true}, {field: "countryName", asc: true}]);
    const [currentSortingField, setCurrentSortingField] = useState("");
    const openEditModal = (broadcasterId: number) => {
        setEditInfo({isEdit: true, broadcasterId})
        setIsOpenModal(true)
    }
    const openConfirm = (broadcasterId: number) => {
        setEditInfo({isEdit: false, broadcasterId})
        openConfirmMenu()
    }
    const getCurrentBroadcasterArray = () => {
        if (searchValue) {
            return searchResult
        } else return broadcasters
    }

    const onSort = (field: string) => {
        setCurrentSortingField(field)
        const newSortingState = sortingState.map(state => {
            if (state.field === field) {
                state.asc = !state.asc
                return state
            } else {
                state.asc = true
                return state
            }
        })
        const currentDirection = newSortingState.find(state => state.field === field)
        const queryString = `?SortingModel.Property=${field}&SortingModel.IsAscending=${currentDirection?.asc}`
        setSortingState(newSortingState)
        dispatch(BroadcastersActionCreators.getBroadcasters(queryString))

    }
    const getCurrentDirectionByFieldName = (field: string) => {
        const currentDirection = sortingState.find(state => state.field === field)
        if (currentDirection) {
            return currentDirection.asc
        }
    }

    return (
        <div className={styles.wrapper}>
            <div className={'tableTitle'}>
                <Title value={'Broadcasters'}/>
                <div className={styles.tableControls}>
                    <Input
                        label={"Search"}
                        placeholder={"Enter broadcaster's name"}
                        onChange={(e) => findBroadcasterByName(e)}
                        value={searchValue}
                        readonly={false}
                    />
                    <div className="regularBtn">
                        <Button onClick={() => setIsOpenModal(true)} value={'Create'}/>
                    </div>
                </div>
            </div>
            <table className={styles.auditTable}>
                <thead className={styles.tableHeadWrapper}>
                <tr className={styles.tableRow} key={1}>
                    <th
                        onClick={() => onSort("name")}
                        className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                        Name
                        {currentSortingField === "name" && <Sort
                            className={getCurrentDirectionByFieldName("name") ? `${styles.tableSortAsc}` : `${styles.tableSort}`}
                        />}
                    </th>
                    <th
                        onClick={() => onSort("countryName")}
                        className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                        Region
                        {currentSortingField === "countryName" && <Sort
                            className={getCurrentDirectionByFieldName("countryName") ? `${styles.tableSortAsc}` : `${styles.tableSort}`}
                        />}
                    </th>
                    <th className={styles.tableHeader}>Definition</th>
                    <th className={styles.tableHeader}>Latent</th>
                    <th className={styles.tableHeader}/>
                </tr>
                </thead>
                {searchResult.length === 0 && searchValue &&
                    <div className={styles.tableInfoBlock}>
                        <h1 className={styles.text}>No search results</h1>
                    </div>
                }
                <tbody className={styles.tableBodyWrapper}>
                {getCurrentBroadcasterArray()?.map(broadcaster => {
                    return (
                        <tr className={styles.tableRow} key={broadcaster.id}>
                            <td className={styles.tableDescription}>{broadcaster.name}</td>
                            <td className={styles.tableDescription}>{broadcaster.countryName}</td>
                            <td className={styles.tableDescription}>{broadcaster.definitionName}</td>
                            <td className={styles.tableDescription}><Checkbox isChecked={broadcaster.isLatent}/></td>
                            <td className={styles.tableDescriptionTextEnd}>
                                <div className={styles.controlBtns}>
                                    <button onClick={() => openEditModal(broadcaster.id)} className={styles.updateBtn}/>
                                    <button onClick={() => openConfirm(broadcaster.id)} className={styles.removeBtn}/>
                                </div>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
};

export default React.memo(ManagementTable)
