export interface IAuditFixtureState {
    fixtureAudit: IFixtureAudit[]
    eventAudit: IEventAudit[]
    competitionAudit: ICompetitionAudit[]
}
export interface IFixtureSnapshotStage {
    id: string,
    name: string,
    dateStart: string,
    dateEnd: string,
    extraTime: true,
    penaltyShootout: true
}
export interface IFixtureSnapshot {
    competition: null | string
    dataProviderType: 1
    description: string
    endTime: null|string
    fixtureDate: string
    id: string
    importDate: string
    information: string
    lastModifierUser: null |string
    lastModifierUserId: 2
    lastModifyDate: string
    sportId: string
    season: string
    startTime: string
    status: 2
    round: string
}
export interface IFixtureAudit {
    id: string
    season: string,
    round: string,
    sportName: string,
    modifierUserId: number
    modifierUsername: string
    modifyDate: string
    snapshot:IFixtureSnapshot
    wtUpdate:null | string
    modifiedFields: string[]

}
export interface IEventAudit {
    id: string
    modifiedFields: string[]
    modifierUserId: number
    modifierUsername: string
    modifyDate: string
    decoderId: number
    decoderName: string
    channelName:null | string
    snapshot: {
        away: string
        broadcaster: null | string
        broadcasterId: number
        channel: null | string
        channelId: number
        competition: null | string
        competitionId: string
        country: null | string
        countryId: string
        createDate: string
        decoder: { id: number,isDeleted: boolean,name:string }
        decoderId: number
        description: string
        endDate: string
        finishDate: string
        fixture: null | string
        fixtureId: null |string
        group: string
        home: string
        id: number
        isCanceled: false
        isInternal: false
        isManualEvent: true
        isProtected: true
        isVerified: true
        lastModifierUser: null
        lastModifierUserId: number
        lastModifyDate: string
        round: string
        season: string
        sport: null | string
        sportId: string
        startDate: string
    }
}
export interface ICompetitionAudit {
    belongsToWomen: boolean
    countryId: string
    countryName: string
    id: string
    modifiedFields: string[]
    modifierUserId: number
    modifierUsername: string
    modifyDate: string
    name: string
    runningTime: string
    scheduleUpdateTime: string
    sportId: string
    sportName: string
    type: number
    teamType: string
    wtUpdate: null | string
    competitionType:string
}

export enum AuditActionEnum {
    GET_AUDIT_FIXTURE = "GET_AUDIT_FIXTURE",
    GET_COMPETITION_AUDIT = "GET_COMPETITION_AUDIT",
    GET_EVENT_AUDIT = "GET_EVENT_AUDIT",
    SET_FIXTURE_AUDIT = "SET_FIXTURE_AUDIT",
    SET_COMPETITION_AUDIT = "SET_COMPETITION_AUDIT",
    SET_EVENT_AUDIT = "SET_EVENT_AUDIT"
}

export interface IGetAuditFixture {
    type: AuditActionEnum.GET_AUDIT_FIXTURE,
    fixtureId: string
}

export interface IGetCompetitionAudit {
    type: AuditActionEnum.GET_COMPETITION_AUDIT,
    competitionId: string
}

export interface IGetEventAudit {
    type: AuditActionEnum.GET_EVENT_AUDIT,
    eventId: string
}

export interface ISetFixtureAudit {
    type: AuditActionEnum.SET_FIXTURE_AUDIT,
    payload: IFixtureAudit[]
}

export interface ISetCompetitionAudit {
    type: AuditActionEnum.SET_COMPETITION_AUDIT,
    payload: ICompetitionAudit[]
}

export interface ISetEventAudit {
    type: AuditActionEnum.SET_EVENT_AUDIT,
    payload: IEventAudit[]
}


export type AuditFixtureAction = ISetFixtureAudit | ISetCompetitionAudit | ISetEventAudit