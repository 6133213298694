import React from "react";
import styles from './auditCompetitionTable.module.scss';
import Checkbox from "../../../components/Checkbox";
import {ICompetitionAudit} from "../../../store/reducers/audit/types";
import moment from "moment/moment";

interface IAuditCompetitionTableProps {
    competitionAudit: ICompetitionAudit[]
}

const AuditCompetitionTable: React.FC<IAuditCompetitionTableProps> = ({competitionAudit}) => {
    const highlightField = (fieldName:string,modifiedFields:string[]) => {
        let isModifiedField = false
        modifiedFields.forEach((field)=>{
                if (field === fieldName) {
                    isModifiedField = true
                }
        })
        return isModifiedField ? styles.modifiedField : ""
    }

    return (
        <div className={styles.wrapper}>
            <table className={styles.auditTable}>
                <thead className={styles.tableHeadWrapper}>
                <tr className={styles.tableRow} key={1}>
                    <th className={styles.tableHeader}>Comp. ID</th>
                    <th className={styles.tableHeader}>Comp. Name</th>
                    <th className={styles.tableHeader}>Region</th>
                    <th className={styles.tableHeader}>Region ID</th>
                    <th className={styles.tableHeader}>Sport</th>
                    <th className={styles.tableHeader}>Sport ID</th>
                    <th className={styles.tableHeader}>Live Schedule</th>
                    <th className={styles.tableHeader}>Last <br></br> Updated</th>
                    <th className={styles.tableHeader}>Mod By</th>
                    <th className={styles.tableHeader}>Comp. Type</th>
                    <th className={styles.tableHeader}>Team Type</th>
                    <th className={styles.tableHeader}>WTUpdate Time</th>
                </tr>
                </thead>
                <tbody className={styles.tableBodyWrapper}>
                {competitionAudit?.map(audit => {
                    return (
                        <tr className={styles.tableRow} key={audit.modifyDate}>
                            <td className={styles.tableDescription}>{audit.id}</td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("Name",audit.modifiedFields)}
                                >
                                    {audit.name}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("CountryName",audit.modifiedFields)}
                                 >
                                     {audit.countryName}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("CountryId",audit.modifiedFields)}
                                 >
                                     {audit.countryId}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("SportName",audit.modifiedFields)}
                                 >
                                      {audit.sportName}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("SportId",audit.modifiedFields)}
                                 >
                                      {audit.sportId}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <Checkbox isChecked/>
                            </td>
                            <td className={styles.tableDescription}>{moment(audit.modifyDate).format("YYYY-MM-DD")}</td>
                            <td className={styles.tableDescription}>{audit.modifierUsername}</td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("Type",audit.modifiedFields)}
                                 >
                                    {audit.competitionType}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("BelongsToWomen",audit.modifiedFields)}
                                 >
                                   {audit.teamType}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>{audit.wtUpdate && moment(audit.wtUpdate).format("YYYY-MM-DD:HH:mm")}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>

    );
};

export default React.memo(AuditCompetitionTable);
