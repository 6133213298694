import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import styles from './style.module.scss'
import {IInputOption} from "../../../store/reducers/fixtures/types";
import {useDispatch} from "react-redux";
import {SingleValue} from "react-select";
import {client} from "../../../services/api-servise";
import Title from "../../../components/Title";
import SelectInput from "../../../components/SelectInput";
import Input from "../../../components/Input";
import TimeCalendar from "../../../components/TimeCalendar/TimeCalendar";
import Button from "../../../components/Button";
import {ISchedule} from "../../../store/reducers/schedule/types";
import {ScheduleActionCreators} from "../../../store/reducers/schedule/action-creators";
import CreatableSelectInp from "../../../components/CreatableSelect/CreatableSelect";
import {IDay} from "react-calendar-datetime-picker/dist/type";
import {formatDate, reFormatDate} from "../../../helpers/TimePickerHelper";
import ToggleSwitchBtn from "../../../components/Button/Toggle-Switch";

interface IEventUpdateModalProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    event: ISchedule | null
    editEventId: number
    setEditCellInfo: Dispatch<React.SetStateAction<{ eventId: number, compId: string, fixtureId: string, event: ISchedule | null }>>
}

export interface IInitFormOptions {
    broadcasters: {
        items: IInputOption[]
    },
    decoders: {
        items: IInputOption[]
    },
    servers: {
        items: IInputOption[]
    }
    seasons: string[]
    rounds: string[]
}

const EditEventModal: React.FC<IEventUpdateModalProps> = ({
      setIsOpenModal,
      editEventId,
      event,
      setEditCellInfo
    }) => {
    const dispatch = useDispatch()
    const [season, setSeason] = useState<SingleValue<any>>("")
    const [round, setRound] = useState<SingleValue<any>>("")
    const [group, setGroup] = useState("")
    const [description, setDescription] = useState("")
    const [home, setHome] = useState('')
    const [away, setAway] = useState('')
    const [startDate, setStartDate] = useState<IDay | undefined>(undefined);
    const [endDate, setEndDate] = useState<IDay | undefined>(undefined);
    const [broadcaster, setBroadcaster] = useState<SingleValue<any>>([])
    const [decoder, setDecoder] = useState<SingleValue<any>>([])
    const [server, setServer] = useState<SingleValue<any>>([])
    const [isInternal, setIsInternal] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    const [isOpenStartDate, setIsOpenStartDate] = useState(false)
    const [isOpenEndDate, setIsOpenEndDate] = useState(false)
    const [initOptions, setInitOptions] = useState({} as IInitFormOptions)

    useEffect(() => {
        setDefaultFormValue()
    }, [])

    const setDefaultFormValue = async () => {
        if (event) {
            const initOptions: IInitFormOptions = await getInitOptions()
            const decoder = initOptions.decoders.items.find(decoder => decoder.id === event.decoderId?.toString())
            const broadcaster = initOptions.broadcasters.items.find(broadcaster => broadcaster.id === event.broadcasterId?.toString())
            const server = initOptions.servers.items.find(server => server.id === event.channelId?.toString())
            const season = initOptions.seasons.find(season => season === event.season)
            const round = initOptions.rounds.find(round => round === event.round)
            if (season) {
                setSeason({value: season, label: season})
            }
            if (round) {
                setRound({value: round, label: round})
            }
            if (event.group) {
                setGroup(event.group)
            }
            setDescription(event.description)
            setHome(event.home)
            setAway(event.away)
            setStartDate(reFormatDate(event.startTime))
            setEndDate(reFormatDate(event.endTime))
            setIsInternal(event.isInternal)
            setIsVerified(event.verified)
            if (decoder) {
                setDecoder({value: decoder?.id, label: decoder?.name})
            }
            if (broadcaster) {
                setBroadcaster({value: broadcaster?.id, label: broadcaster?.name})
            }
            if (server) {
                setServer({value: server?.id, label: server?.name})
            }
        }
    }


    const getChannels = async (decoderId: number) => {
        try {
            const channels = await client.get(`/api/Event/Channels?decoderId=${decoderId}`)
            setInitOptions({...initOptions, servers: channels.data.channels})
        } catch (e) {
            console.log(e)
        }
    }

    const getDecoders = async (channelId: number) => {
        try {
            const decoders = await client.get(`/api/Event/Decoders?channelId=${channelId}`)
            setInitOptions({...initOptions, decoders: decoders.data.decoders})
        } catch (e) {
            console.log(e)
        }
    }


    const onChangeDecoder = (e: SingleValue<any>) => {
        setDecoder(e)
        getChannels(e.value)

    }
    const onChangeServer = (e: SingleValue<any>) => {
        setServer(e)
        getDecoders(e.value)

    }
    const clearForm = () => {
        setSeason("")
        setRound("")
        setGroup("")
        setDescription("")
        setIsInternal(false)
        setIsVerified(false)
        setServer([])
        setDecoder([])
        setBroadcaster([])
        setStartDate(undefined)
        setEndDate(undefined)
        setHome("")
        setAway("")
    }

    const getInitOptions = async () => {
        try {
            const inputsValues = await client.get(`/api/Event/Init?competitionId=${event?.competitionId}`)
            await setInitOptions(inputsValues.data)
            return inputsValues.data
        } catch (e) {
            console.log(e)
        }
    }

    const updateEvent = () => {
        const updatedEvent = {
            competitionId: event!.competitionId,
            eventId: editEventId,
            home,
            away,
            season: season.label,
            round: round.label,
            group,
            description,
            finishDate: formatDate(endDate),
            endDate: formatDate(endDate),
            startDate: formatDate(startDate),
            broadcasterId: Number(broadcaster.value) === 0 ? null : Number(broadcaster.value),
            decoderId: Number(decoder.value) === 0 ? null : Number(decoder.value),
            channelId: Number(server.value) === 0 ? null : Number(server.value),
            isVerified,
            isInternal
        }
        setEditCellInfo({
            eventId: updatedEvent.eventId,
            compId: updatedEvent.competitionId,
            fixtureId: event!.fixtureId,
            event: {
                eventId: updatedEvent.eventId,
                fixtureId: event!.fixtureId,
                competitionId: updatedEvent.competitionId,
                away: updatedEvent.away,
                home: updatedEvent.home,
                decoderId: Number(decoder.value),
                group: updatedEvent.group,
                round: updatedEvent.round,
                decoderName: event!.decoderName,
                season: updatedEvent.season,
                channelName: event!.channelName,
                broadcasterId: Number(broadcaster.value),
                channelId: Number(server.value),
                description: updatedEvent.description,
                startTime: formatDate(startDate),
                endTime: formatDate(endDate),
                isCanceled: event!.isCanceled,
                info: event!.info,
                broadcasterName: broadcaster.label,
                competitionName: event!.competitionName,
                isInternal: event!.isInternal,
                isProtected: event!.isProtected,
                region: event!.region,
                sport: event!.sport,
                packageName: event!.packageName,
                verified: event!.verified
            }
        })
        clearForm()
        setIsOpenModal(false)
        dispatch(ScheduleActionCreators.editScheduleEvent(updatedEvent))
    }

    const formatOptions = useCallback((options: IInputOption[]) => {
        if (options) {
            const allOptions = options?.map(opt => ({value: opt?.id, label: opt?.name}))
            allOptions.unshift({value: "0", label: "Blank"})
            return allOptions
        } else {
            return []
        }
    }, [initOptions])

    const formatStringOptions = useCallback((options: string[]) => {
        if (!options) return [];

        return [{ value: "0", label: "Blank" }, ...options.map(opt => ({ value: opt, label: opt }))];    
    }, [])


    const closeCalendars = (e:React.MouseEvent) => {
        e.stopPropagation()
        setIsOpenStartDate(false)
        setIsOpenEndDate(false)
    }

    return (
        <div onClick={closeCalendars} className={styles.eventsModal}>
            <div className={styles.modalForm}>
                <button
                    onClick={() => setIsOpenModal(false)}
                    className={styles.closeBtn}
                />
                <div className={styles.modalTitle}>
                    <Title value={'Update Event'}/>
                </div>
                <div className={styles.addEventWrapper}>
                    <div className={styles.inputsColumn}>
                        <div className={styles.inputWrapper}>
                            <SelectInput
                                value={broadcaster}
                                onChange={setBroadcaster}
                                label={'Broadcaster'}
                                name={'Broadcaster'}
                                searchable={true}
                                options={formatOptions(initOptions?.broadcasters?.items)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <SelectInput
                                value={decoder}
                                onChange={onChangeDecoder}
                                label={'Decoder'}
                                name={'Decoder'}
                                searchable={true}
                                options={formatOptions(initOptions?.decoders?.items)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <SelectInput
                                value={server}
                                onChange={onChangeServer}
                                label={'Server'}
                                name={'Server'}
                                searchable={true}
                                options={formatOptions(initOptions?.servers?.items)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <CreatableSelectInp
                                value={season}
                                onChange={setSeason}
                                label={'Season'}
                                name={'Season'}
                                searchable={true}
                                options={formatStringOptions(initOptions?.seasons)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <CreatableSelectInp
                                value={round}
                                onChange={setRound}
                                label={'Round'}
                                name={'Round'}
                                searchable={true}
                                options={formatStringOptions(initOptions?.rounds)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <Input
                                value={group}
                                setValue={setGroup}
                                label={'Group'}
                                readonly={false}
                            />
                        </div>
                    </div>

                    <div className={styles.inputsColumn}>
                        <div className={`${styles.inputWrapper} ${styles.inputDescription}`}>
                            <Input
                                value={description}
                                setValue={setDescription}
                                label={'Description'}
                                readonly={false}
                            />
                            <div className={styles.inputDescriptionNumber}>
                              D
                            </div>
                        </div>
                        <div className={`${styles.inputWrapper} ${styles.homeAwayWrapper}`}>
                            <div className={styles.inputSmallWrapper}>
                                <Input
                                    value={home}
                                    setValue={setHome}
                                    label={'Home'}
                                    readonly={false}
                                />
                            </div>
                            <div className={styles.inputSmallWrapper}>
                                <Input
                                    value={away}
                                    setValue={setAway}
                                    label={'Away'}
                                    readonly={false}
                                />
                            </div>
                        </div>
                        <div className={styles.inputWrapper}>
                            <TimeCalendar
                                calendarClass={"schedule-calendar"}
                                currentDate={startDate}
                                setCurrentDate={setStartDate}
                                label={"Start Date & Time"}
                                isOpen={isOpenStartDate}
                                setIsOpen={setIsOpenStartDate}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <TimeCalendar
                                calendarClass={"schedule-calendar"}
                                currentDate={endDate}
                                setCurrentDate={setEndDate}
                                label={"End Date & Time"}
                                isOpen={isOpenEndDate}
                                setIsOpen={setIsOpenEndDate}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <div className={styles.buttonsWrapper}>
                                <div className={styles.radioControls}>
                                    <div onClick={()=>setIsInternal(!isInternal)} className={styles.radioScheduleBtn}>
                                        <ToggleSwitchBtn
                                            checked={isInternal}
                                            setChecked={setIsInternal}
                                        />
                                        <div className={styles.radioScheduleLabel}>
                                            <h6>Internal</h6>
                                            <h6>Schedule</h6>
                                        </div>
                                    </div>
                                    <div onClick={()=>setIsVerified(!isVerified)} className={styles.radioVerifyBtn}>
                                        <ToggleSwitchBtn
                                            checked={isVerified}
                                            setChecked={setIsVerified}
                                        />
                                        <div className={styles.radioVerifyLabel}>
                                            <h6>Verify</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.btnGroupWrapper}>
                                    <div className={styles.btnWrapper}>
                                        <Button onClick={() => setIsOpenModal(false)} value={'Cancel'}/>
                                    </div>
                                    <div className={`${styles.btnWrapper} ${styles.btnAdd}`}>
                                        <Button onClick={updateEvent} value={'Update'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(EditEventModal);