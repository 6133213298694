import React from 'react';
import SelectInput from "../../components/MultiSelect";
import FixturesTable from "./FixturesTable";
import Title from "../../components/Title";
import Button from "../../components/Button";
import {useDispatch} from "react-redux";
import {FixturesActionCreators} from "../../store/reducers/fixtures/action-creators";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {MultiValue, SingleValue} from "react-select";
import RangeCalendar from "../../components/RangeCalendar/RangeCalendar";
import {useSortFixtures} from "./FixturesHooks/useSortFixtures";
import {formatOptions} from "../../helpers/formatSelectOptions";
import {getQueryString} from "./FixturesHooks/getQueryString";
import {useAccordionsState} from "./hooks/useAccordionState";
import { useDeepCompareEffect } from '../../hooks/useDeepCompare';
import './style.scss'

export type SortingField = "CompetitionName" | "SportName" | "Status" | "ModeBy" | "Description"

const Fixtures: React.FC = () => {
    const {fixtures, filterOptions, filterValues} = useTypedSelector(state => state.fixturesReducer)
    const dispatch = useDispatch()
    const {expandAll, collapseAll, toggleAccordion, accordionsState,isCollapsedGroup} = useAccordionsState(fixtures)
    const {sortingFieldsProps, currentSortingField, sortingString, createSortingString} = useSortFixtures()
    const clearFilter = () => {
        dispatch(FixturesActionCreators.clearFixturesFilter())
    }

    useDeepCompareEffect(() => {
        dispatch(FixturesActionCreators.getFixtures(getQueryString(filterValues, sortingString)))
    }, [filterValues, sortingString])


    const setFilter = (e: MultiValue<any> | SingleValue<any>, name: string) => {
        const options = e
        const payload = {name, options}
        dispatch(FixturesActionCreators.setFixturesFilter(payload))
    }

    const setDateRange = (e: any) => {
        dispatch(FixturesActionCreators.setFilterDateRange(e))
    }

    return (
        <div className="container">
            <div className="mt64">
                <Title value={'Fixture Search'}/>
            </div>
            <div className="flexAlignCenter mt15">
                <div className="calendarInput">
                    <RangeCalendar
                        defaultRange={filterValues.dateRange}
                        setRange={setDateRange}
                    />
                </div>
                <div className="regularInput-Select">
                    <SelectInput
                        filterValue={filterValues.sportIds}
                        handlerOnChange={setFilter}
                        name={'sportIds'}
                        searchable={true}
                        options={formatOptions(filterOptions.sports.items)}
                        label={'Sport'}/>
                </div>
                <div className="regularInput-Select">
                    <SelectInput
                        filterValue={filterValues.regionIds}
                        handlerOnChange={setFilter}
                        name={'regionIds'}
                        searchable={true}
                        options={formatOptions(filterOptions.regions.items)}
                        label={'Region'}/>
                </div>
                <div className="regularInput-Select">
                    <SelectInput
                        filterValue={filterValues.competitionIds}
                        handlerOnChange={setFilter}
                        name={'competitionIds'}
                        searchable={true}
                        options={formatOptions(filterOptions.competitions.items)}
                        label={'Competition'}/>
                </div>
                <div className={"clear-filter-btn"}>
                    <Button
                        value={'Clear Filter'}
                        onClick={() => clearFilter()}
                    />
                </div>
            </div>
            <FixturesTable
                isCollapsedGroup={isCollapsedGroup}
                expandAllGroup={expandAll}
                collapseAllGroup={collapseAll}
                accordionsState={accordionsState}
                toggleAccordion={toggleAccordion}
                currentSortingField={currentSortingField}
                sortingFieldsProps={sortingFieldsProps}
                createSortingString={createSortingString}
                fixtures={fixtures}
            />
        </div>
    );
}

export default React.memo(Fixtures);

