import styles from './style.module.scss'
import Button from "../Button";
import React, {Dispatch, SetStateAction} from "react";

interface IWarningModalProps {
    modalText?: string
    action: () => void
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    children?: JSX.Element
    confirmButtonText?: string
    cancelButtonText?: string
}

const WarningModal: React.FC<IWarningModalProps> = ({
    modalText,
    action,
    setIsOpenModal,
    children,
    confirmButtonText,
    cancelButtonText
    }) => {

    const onConfirm = () => {
        setIsOpenModal(false)
        action()
    }

    return (
        <div className={styles.warningModal}>
            <div className={styles.contentInner}>
                <div className={styles.content}>
                    <div className={styles.titleBlock}>
                        <div className={styles.attention}>!</div>
                    </div>
                    <div className={styles.contentInner}>
                        <h4 className={styles.contentText}>
                            {children ? children : modalText}
                        </h4>
                        <div className={styles.btnInner}>
                            <div className={styles.btn}>
                                <Button onClick={() => setIsOpenModal(false)}
                                        value={cancelButtonText ? cancelButtonText : "No"}/>
                            </div>
                            <div className={styles.btn}>
                                <Button onClick={()=>onConfirm()} value={confirmButtonText ? confirmButtonText : "Yes"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(WarningModal);