import {useEffect, useState} from "react";
import {IFixturesData} from "../../../store/reducers/fixtures/types";

interface IAccordionState {
    accId: string,
    isExpand: boolean
}

export const useAccordionsState = (fixtures: IFixturesData[]) => {
    const [accordionsState, setAccordionsState] = useState<IAccordionState[]>([]);
    const [isCollapsedGroup, setIsCollapsedGroup] = useState(true);

    const toggleAccordion = (id: string) => {
        const newState = accordionsState.map(accordion => {
            if (accordion.accId === id) {
                return {accId: accordion.accId, isExpand: !accordion.isExpand}
            }
            return accordion
        })
        checkIsOneCollapse(newState)
        setAccordionsState(newState)
    }

    const checkIsOneCollapse = (state:IAccordionState[])=>{
        const isExpanded = state.find(acc=>acc.isExpand)
        if (isExpanded) {
            setIsCollapsedGroup(false)
        }
        else {
            setIsCollapsedGroup(true)
        }
    }

    const createAccordionState = () => {
        if (fixtures) {
            const accordionState = fixtures.map(fixture => {
                return {
                    accId: fixture.date,
                    isExpand: false
                }
            })
            setAccordionsState(accordionState)
        }
    }
    const collapseAll = () => {
        const newState = accordionsState.map(accordion => {
            return {...accordion, isExpand: false}
        })
        setAccordionsState(newState)
        setIsCollapsedGroup(true)
    }
    const expandAll = () => {
        const newState = accordionsState.map(accordion => {
            return { ...accordion, isExpand: true };
        });

        setAccordionsState(newState);
        setIsCollapsedGroup(false)
    };

    useEffect(() => {
        if (accordionsState.length === fixtures.length) {
            return
        } else {
            createAccordionState()
        }
    }, [fixtures]);

    return {toggleAccordion, collapseAll, expandAll,accordionsState,isCollapsedGroup}
}