import {client} from "../../../services/api-servise";
import {ICreatingBroadcaster} from "./types";

export const getBroadcastersRequest = async (queryString:string): Promise<any> => {
    const broadcasters = await client.get(`/api/Management/broadcasters/get${queryString}`)
    return broadcasters.data
}

export const getBroadcastersInit = async ():Promise<any> => {
    const init = await client.get('/api/Management/broadcasters/init')
    return init.data
}

export const createBroadcastersRequest = async (payload: ICreatingBroadcaster): Promise<any> => {
    const broadcaster = await client.post('/api/Broadcaster', payload)
    return broadcaster.data
}

export const updateBroadcastersRequest = async (payload: ICreatingBroadcaster): Promise<any> => {
    const broadcaster = await client.put('/api/Broadcaster', payload)
    return broadcaster.data
}

export const deleteBroadcastersRequest = async (payload: number): Promise<any> => {
    const broadcaster = await client.delete(`/api/Broadcaster/${payload}`)
    return broadcaster.data
}