import {IEmailTemplateInit, IScheduledFixturesState, ScheduleAction, ScheduleActionEnum} from "./types";
import {log} from "util";

const defaultState: IScheduledFixturesState = {
    groupByChoices: {items: []},
    sports: {items: []},
    groupedSchedules: [],
    emailTemplate: {} as IEmailTemplateInit,
    isLoading: false,
    broadcasters: [],
    decoders: [],
    countries: [],
    sportsFilter: [],
    competitions: [],
    servers: [],
}

export const scheduleReducer = (state = defaultState, action: ScheduleAction): IScheduledFixturesState => {
    switch (action.type) {
        case ScheduleActionEnum.SET_SCHEDULED_FIXTURES: {
            const {groupByChoices, sports, groupedSchedules} = action.payload
            return {...state, groupByChoices, sports, groupedSchedules}
        }
        case ScheduleActionEnum.UPDATE_SCHEDULE_EVENT: {
            let newGroupedSchedules = []
            for (let i = 0; i < state.groupedSchedules.length; i++) {
                const arr = state.groupedSchedules[i]
                arr.schedules = state.groupedSchedules[i].schedules.map(schedule => schedule.eventId === action.payload.eventId ? action.payload : schedule)
                newGroupedSchedules.push(arr)
            }
            return {...state, groupedSchedules: newGroupedSchedules}
        }
        case ScheduleActionEnum.REMOVE_SUCCESS: {
            let newGroupedSchedules = []
            for (let i = 0; i < state.groupedSchedules.length; i++) {
                const arr = state.groupedSchedules[i]
                arr.schedules = state.groupedSchedules[i].schedules.filter(schedule => schedule.eventId !== action.payload)
                newGroupedSchedules.push(arr)
            }
            return {...state, groupedSchedules: newGroupedSchedules}
        }
        case ScheduleActionEnum.DUPLICATE_EVENT_SUCCESS: {
            let newGroupedSchedules = []
            for (let i = 0; i < state.groupedSchedules.length; i++) {
                const arr = state.groupedSchedules[i]
                const currentArr = state.groupedSchedules[i].schedules.find(schedule => schedule.fixtureId === action.payload.fixtureId)
                if (currentArr) {
                    arr.schedules = [...arr.schedules, action.payload]
                    newGroupedSchedules.push(arr)
                } else {
                    newGroupedSchedules.push(arr)
                }

            }
            return {...state, groupedSchedules: newGroupedSchedules}
        }
        case ScheduleActionEnum.SET_EMAIL_TEMPLATE: {
            return {...state, emailTemplate: action.payload}
        }
        case ScheduleActionEnum.SET_SCHEDULE_LOADER: {
            return {...state, isLoading: action.payload}
        }
        case ScheduleActionEnum.SET_FILTERS_OPTIONS: {
            const {broadcasters, decoders, countries, sports, competitions, servers} = action.payload
            return {...state, broadcasters, decoders, sportsFilter: sports, competitions, servers, countries}
        }
        case ScheduleActionEnum.SET_SEVERAL_SCHEDULE_EVENTS: {
            let newGroupedSchedules = []
            for (let i = 0; i < state.groupedSchedules.length; i++) {
                const arr = state.groupedSchedules[i]
                arr.schedules = state.groupedSchedules[i].schedules.map(schedule => {
                    action.payload.map(event =>{
                        if(event.eventId === schedule.eventId){
                            schedule = event
                        }
                        return event
                    })
                    return schedule
                })
                newGroupedSchedules.push(arr)
            }
            return {...state, groupedSchedules: newGroupedSchedules}
        }
        default:
            return state
    }
}
