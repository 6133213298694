import React from 'react';
import styles from "./index.module.scss";
import closeImg from "../../../img/close.svg";
import MultiSelectInput from "../../../components/MultiSelect";
import {formatOptions} from "../../../helpers/formatSelectOptions";
import {IInputOption} from "../../../store/reducers/fixtures/types";
import {MultiValue} from "react-select";
import OutsideClickHandler from "react-outside-click-handler";

interface ITableFilterProps {
    filterLabel: string,
    filterName: string,
    filterOptions: IInputOption[],
    setIsFilterOpen: (filtersField: string, isFilterOpen: boolean) => void,
    handleTableFilters: (option: MultiValue<{ value: string, label: string }>, filterName: string) => void
    filterValue: MultiValue<{ value: string, label: string }>
}

const TableFilter: React.FC<ITableFilterProps> = React.memo(({
                                                                 filterLabel,
                                                                 filterName,
                                                                 filterOptions,
                                                                 setIsFilterOpen,
                                                                 handleTableFilters,
                                                                 filterValue
                                                             }) => {
    return (
        <OutsideClickHandler
            onOutsideClick={() => {setIsFilterOpen(filterName, false)}}>

            <div className={styles.filter}>
                <div className={styles.closeBtnInner}>
                    <button className={styles.closeBtn}
                            onClick={() => setIsFilterOpen(filterName, false)}>
                        <img src={closeImg} alt="closeImg" className={styles.closeImg}/>
                    </button>
                </div>
                <MultiSelectInput
                    label={filterLabel}
                    searchable={true}
                    name={filterName}
                    options={formatOptions(filterOptions)}
                    handlerOnChange={(opt) => handleTableFilters(opt, filterName)}
                    filterValue={filterValue}
                />
            </div>
        </OutsideClickHandler>
    );
});

export default TableFilter;
