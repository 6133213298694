import {
    EventActionEnum,
    ICreatedEvent,
    ICreateEvent,
    IDeleteEvent, IDeleteEventsFixture,
    IGetEvents, IMultiCreateEvents,
    ISetEvents, IUpdatedEvent,
    IUpdateEvent,
} from "./types";
import {IGetEventsResp} from "./saga";

export const EventsActionCreator = {
    getEvents: (queryString: string): IGetEvents => ({type: EventActionEnum.GET_EVENTS, queryString}),
    setEvents: (payload: IGetEventsResp): ISetEvents => ({type: EventActionEnum.SET_EVENTS, payload}),
    createEvent: (payload: { event: ICreatedEvent, queryString: string }): ICreateEvent => ({
        type: EventActionEnum.CREATE_EVENT,
        payload
    }),
    updateEvent: (payload: { event: IUpdatedEvent, queryString: string }): IUpdateEvent => ({
        type: EventActionEnum.UPDATE_EVENT,
        payload
    }),
    deleteEvent: (payload: { eventId: number; queryString: string }): IDeleteEvent => ({
        type: EventActionEnum.DELETE_EVENT,
        payload
    }),
    multiCreateEvents: (payload: { fixtures: string[], queryString: string }): IMultiCreateEvents => ({
        type: EventActionEnum.MULTI_CREATE_EVENTS,
        payload
    }),
    deleteEventsFixture: (payload: string[]): IDeleteEventsFixture => ({
        type: EventActionEnum.DELETE_EVENTS_FIXTURE,
        payload
    }),
}