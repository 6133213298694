import {
    AuthActionEnum,
    ILogin, ILogout,
    ISetAuth, ISetAuthError, ISetAuthLoading,
    ISetIsFirstStep,
    ISetUserInfo,
    ISetUsername,
    IVerifyUser,
    UserInfo
} from "./types";

export const AuthActionCreators = {
    setIsAuth: (isAuth: boolean):ISetAuth => ({type: AuthActionEnum.SET_AUTH, payload: isAuth}),
    setAuthLoading: (payload: boolean):ISetAuthLoading => ({type: AuthActionEnum.SET_AUTH_LOADING, payload}),
    setUsername: (payload:string):ISetUsername => ({type: AuthActionEnum.SET_USERNAME,payload}),
    login: (payload:{username:string,password:string}):ILogin => ({type: AuthActionEnum.TRY_LOGIN,payload}),
    setIsFirstStep: (payload:boolean):ISetIsFirstStep => ({type: AuthActionEnum.SET_IS_FIRST_STEP,payload}),
    verifyUser:(payload:{username:string,verificationCode:string,staySignedIn:boolean}):IVerifyUser=>({type:AuthActionEnum.VERIFY_USER,payload}),
    setUserInfo:(payload:UserInfo):ISetUserInfo=>({type:AuthActionEnum.SET_USER_INFO,payload}),
    setAuthError: (payload:string):ISetAuthError => ({type: AuthActionEnum.SET_AUTH_ERROR,payload}),
    logout: ():ILogout =>({type: AuthActionEnum.LOGOUT})
}
