import React, {useRef, useState} from "react";
import styles from './fixturesTable.module.scss'
import {ReactComponent as Sort} from "../../../img/tableSort.svg";
import TableAccordion from "./TableAccordion/TableAccordion";
import FixtureContextMenu from "./ContextMenu/FixtureContextMenu";
import {IFixture, IFixturesData} from "../../../store/reducers/fixtures/types";
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {SortingField} from "../Fixtures";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import Loader from "../../../components/Loader/Loader";
import Button from "../../../components/Button";
import {useDispatch} from "react-redux";
import {FixturesActionCreators} from "../../../store/reducers/fixtures/action-creators";
import {IAccordionState} from "../../Schedule/ScheduleHooks/useCreateAccordeonsState";

interface IFixtureTableProps {
    fixtures: IFixturesData[]
    createSortingString: (sortingField: SortingField) => void
    sortingFieldsProps: { CompetitionName: boolean, SportName: boolean, Status: boolean, ModeBy: boolean, Description: boolean }
    currentSortingField: string
    toggleAccordion: (id: string) => void
    accordionsState: IAccordionState[]
    expandAllGroup: () => void
    collapseAllGroup: () => void
    isCollapsedGroup: boolean
}

const FixturesTable: React.FC<IFixtureTableProps> = ({
         fixtures,
         createSortingString,
         sortingFieldsProps,
         currentSortingField,
         toggleAccordion,
         accordionsState,
         expandAllGroup,
         collapseAllGroup,
         isCollapsedGroup
     }) => {
    const dispatch = useDispatch()
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
    const [selectedFixtureInfo, setSelectedFixtureInfo] = useState({fixtureId: "", competitionId: ""})
    const contextMenuRef = useRef<any>(null)
    const [fixturesForCreateEvents, setFixturesForCreateEvents] = useState<IFixture[]>([])
    const {isLoading} = useTypedSelector(state => state.fixturesReducer)
    const closeMenu = () => {
        setIsOpenMenu(false)
    }
    useOutsideClick(contextMenuRef, closeMenu)

    const handleRowClick = async (event: any, fixtureId: string, competitionId: string) => {
        const currentEventId = event.target.className.split(" ")[0]
        if (currentEventId !== selectedFixtureInfo.fixtureId) {
            return
        }
        await setIsOpenMenu(true)
        setSelectedFixtureInfo({fixtureId, competitionId})
        const clickX = event.clientX;
        const clickY = event.clientY;
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        const rootW = contextMenuRef.current!.offsetWidth;
        const rootH = contextMenuRef.current!.offsetHeight;
        const right = (screenW - clickX) > rootW;
        const left = !right;
        const top = (screenH - clickY) > rootH;
        const bottom = !top
        contextMenuRef.current.style.left = 0
        contextMenuRef.current.style.top = 0

        if (right) {
            contextMenuRef.current.style.left = `${clickX + 5}px`;
        }

        if (left) {
            contextMenuRef.current.style.left = `${clickX - rootW - 5}px`;
        }

        if (top) {
            contextMenuRef.current.style.top = `${clickY + 5}px`;
        }

        if (bottom) {
            contextMenuRef.current.style.top = `${clickY - rootH - 5}px`;
        }
    }

    const renderSortIcon = (columnName: SortingField) => {
        if (sortingFieldsProps[columnName] && currentSortingField === columnName) {
            return <Sort className={styles.tableSortAsc}/>
        }
        if (!sortingFieldsProps[columnName] && currentSortingField === columnName) {
            return <Sort className={styles.tableSort}/>
        }
    }

    const createEvents = () => {
        const payload: string[] = []
        fixturesForCreateEvents.forEach(fixture => {
            payload.push(fixture.fixtureId)
        })
        dispatch(FixturesActionCreators.createEventsFromFixtures(payload))
        setFixturesForCreateEvents([])
    }

    const toggleAllGroup = () => {
        isCollapsedGroup ? expandAllGroup() : collapseAllGroup()
    }

    return (
        <div>
            <div className={styles.tableControl}>
                {fixtures.length > 0 && < div className="regularBtn mt30 mr20">
                    <Button
                        value={isCollapsedGroup ? "Expand All Groups" : "Collapse All Groups"}
                        onClick={() => toggleAllGroup()}
                    />
                </div>}
                {fixturesForCreateEvents.length > 0 && <div className="regularBtn mt30">
                    <Button
                        value={'Create'}
                        onClick={() => createEvents()}
                    />
                </div>}
            </div>
            {isOpenMenu && <div ref={contextMenuRef} className={styles.fixtureMenu}>
                <FixtureContextMenu selectedFixtureInfo={selectedFixtureInfo}/>
            </div>}
            <div className={styles.totalWrapper}>
                <div className={styles.wrapper}>
                    {isLoading && <Loader/>}
                    <table className={styles.auditTable}>
                        <thead className={styles.tableHead}>
                        <tr>
                            <th className={styles.tableHeader}>Fixture Id</th>
                            <th className={styles.tableHeader}>Start Time</th>
                            <th className={styles.tableHeader}>End Time</th>
                            <th
                                onClick={() => createSortingString("Description")}
                                className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                Description {renderSortIcon("Description")}
                            </th>
                            <th className={styles.tableHeader}>Information</th>
                            <th
                                onClick={() => createSortingString("CompetitionName")}
                                className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                Competition {renderSortIcon("CompetitionName")}
                            </th>
                            <th className={styles.tableHeader}>Region</th>

                            <th
                                onClick={() => createSortingString("SportName")}
                                className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                Sport {renderSortIcon("SportName")}
                            </th>
                            <th
                                onClick={() => createSortingString("Status")}
                                className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                Status {renderSortIcon("Status")}
                            </th>
                            <th
                                onClick={() => createSortingString("ModeBy")}
                                className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                                Mod. By {renderSortIcon("ModeBy")}
                            </th>
                            <th className={styles.tableHeader}>Created</th>
                        </tr>
                        </thead>
                        {
                            fixtures.map(item => {
                                return (
                                    <TableAccordion
                                        accordionsState={accordionsState}
                                        toggleAccordion={toggleAccordion}
                                        setSelectedFixtureInfo={setSelectedFixtureInfo}
                                        selectedFixtureInfo={selectedFixtureInfo}
                                        fixtureForCreateEvents={fixturesForCreateEvents}
                                        setSelectedFixtures={setFixturesForCreateEvents}
                                        handlerRowClick={handleRowClick}
                                        key={item.date}
                                        date={item.date}
                                        fixtures={item.items}
                                    />
                                )
                            })
                        }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default React.memo(FixturesTable);
