import styles from "../fixturesTable.module.scss";
import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";
import {IFixture} from "../../../../store/reducers/fixtures/types";
import moment from "moment";
import Checkbox from "../../../../components/Checkbox";
import {IAccordionState} from "../../../Schedule/ScheduleHooks/useCreateAccordeonsState";

interface ITableAccordionProps {
    date: string,
    fixtures: IFixture[]
    handlerRowClick?: (e: React.MouseEvent, fixtureId: string, competitionId: string) => void
    fixtureForCreateEvents: IFixture[]
    setSelectedFixtures: Dispatch<SetStateAction<IFixture[]>>
    selectedFixtureInfo: { fixtureId: string, competitionId: string }
    setSelectedFixtureInfo: Dispatch<SetStateAction<{ fixtureId: string; competitionId: string; }>>
    toggleAccordion: (id: string) => void
    accordionsState: IAccordionState[]

}

const TableAccordion: React.FC<ITableAccordionProps> = ({
        date,
        fixtures,
        handlerRowClick,
        fixtureForCreateEvents,
        setSelectedFixtures,
        selectedFixtureInfo,
        setSelectedFixtureInfo,
        toggleAccordion,
        accordionsState
    }) => {
    const [selectedCellInfo, setSelectedCellInfo] = useState({fixtureId: "", competitionId: ""})

    const isExpandedCurrentAccordion = useMemo(() => {
        const currentAcc = accordionsState.find(acc => acc.accId === date);
        if (currentAcc) {
            return currentAcc.isExpand;
        }
        return false;
    }, [accordionsState]);


    useEffect(() => {
        // @ts-ignore
        document.addEventListener('contextmenu', openContextMenu);
        return () => {
            // @ts-ignore
            document.removeEventListener('contextmenu', openContextMenu);
        }
    }, [selectedCellInfo])


    const selectFixture = (e: React.MouseEvent, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        const selectedFixture = fixtures.find(fixture => fixture.fixtureId === id)
        if (selectedFixture) {
            const existSelectedFixture = fixtureForCreateEvents.find(fixture => fixture.fixtureId === selectedFixture.fixtureId)
            if (existSelectedFixture) {
                const allSelectedFixture = fixtureForCreateEvents.filter(fixture => fixture.fixtureId !== existSelectedFixture.fixtureId)
                return setSelectedFixtures(allSelectedFixture)
            } else {
                return setSelectedFixtures([...fixtureForCreateEvents, selectedFixture])
            }
        }
    }

    const isChecked = (fixtureId: string) => {
        const isChecked = fixtureForCreateEvents.find(fixture => fixture.fixtureId === fixtureId)
        return !!isChecked
    }

    const openContextMenu = (e: React.MouseEvent) => {
        if (handlerRowClick && selectedCellInfo.fixtureId && selectedCellInfo.competitionId) {
            e.preventDefault()
            return handlerRowClick(e, selectedCellInfo.fixtureId, selectedCellInfo.competitionId)
        }
    }

    const onRowClick = (fixtureId: string, competitionId: string) => {
        setSelectedCellInfo({
            fixtureId,
            competitionId
        })
        setSelectedFixtureInfo({fixtureId, competitionId})
    }

    const getRowClass = (fixtureId: string,) => {
        if (fixtureId === selectedFixtureInfo.fixtureId) {
            return `${styles.tableRow} ${styles.blue}`
        } else {
            return `${styles.tableRow}`
        }

    }

    const toggleFixtureAccordion = (accId: string) => {
        toggleAccordion(accId)
    }
    return (
        <>
            <tbody className={styles.tableDateItemsRow}>
            <tr>
                <td
                    onClick={() => toggleFixtureAccordion(date)}
                    className={isExpandedCurrentAccordion ? styles.tableDateActive : styles.tableDate}>
                    {moment(date).format("DD/MM/YYYY")}
                </td>
                <td className={styles.tableItems}>{`Total items ${fixtures.length}`}</td>
                <td className={styles.tableItems}/>
                <td className={styles.tableItems}/>
                <td className={styles.tableItems}/>
                <td className={styles.tableItems}/>
                <td className={styles.tableItems}/>
                <td className={styles.tableItems}/>
                <td className={styles.tableItems}/>
                <td className={styles.tableItems}/>
            </tr>
            </tbody>
            {<tbody className={isExpandedCurrentAccordion ? styles.tableBody : styles.tableBodyHidden}>
            {fixtures?.map(fixture => {
                return (
                    <tr
                        onClick={() => onRowClick(fixture.fixtureId, fixture.competitionId)}
                        key={fixture.fixtureId}
                        className={getRowClass(fixture.fixtureId)}>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>
                            <div className={styles.cellWithCheckbox}>
                                <div onClick={(e) => selectFixture(e, fixture.fixtureId)} className={styles.checkbox}>
                                    <Checkbox isChecked={isChecked(fixture.fixtureId)}/>
                                </div>
                                <div className={styles.textOverflow}>
                                    {fixture.fixtureId}
                                </div>
                            </div>
                        </td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.startDate}</td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.endDate}</td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.description}</td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.information}</td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.competitionName}</td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.region}</td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.sportName}</td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.status}</td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.modifierUsername}</td>
                        <td className={`${fixture.fixtureId} ${styles.tableDescription}`}>{fixture.createdDate}</td>
                    </tr>
                )
            })}
            </tbody>}
        </>
    )
}


export default React.memo(TableAccordion)