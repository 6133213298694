import {useEffect} from "react";
import {ScheduleActionCreators} from "../../../store/reducers/schedule/action-creators";
import {useDispatch} from "react-redux";
import {getQueryString} from "./getQueryString";
import {Range} from "react-calendar-datetime-picker";
import {MultiValue, SingleValue} from "react-select";
import {useTypedSelector} from "../../../hooks/useTypedSelector";

export type MultiSelectValue = MultiValue<any> | SingleValue<any>

export const useGetSchedules = (range: Range, sport: MultiSelectValue, groupBy: MultiSelectValue, sortingString: string) => {
    const {groupedSchedules} = useTypedSelector(state => state.scheduleReducer)
    const dispatch = useDispatch()
    useEffect(() => {
        if (getQueryString(range, sport, groupBy, sortingString).includes('undefined')) {
            return
        } else {
            dispatch(ScheduleActionCreators.getSchedules(getQueryString(range, sport, groupBy, sortingString)))
        }
    }, [sport, groupBy, range, sortingString,dispatch])
    return {groupedSchedules}
}