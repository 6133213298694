import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import styles from './index.module.scss'
import secondStepIcon from '../../../img/secondStepIcon.png'
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {AuthActionCreators} from "../../../store/reducers/auth/action-creators";
import {FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import closeIcon from '../../../img/close.svg';


interface IFormInput {
    verificationCode: string;
}

const SecondStepForm:React.FC = () => {
    const [verificationCode, setVerificationCode] = useState<string>('')
    const {register, handleSubmit, formState: {errors}} = useForm<IFormInput>();
    const {username, error, isAuth} = useTypedSelector(state => state.authReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setVerificationCode(e.target.value)
    }

    const closeHandler = () => {
        dispatch(AuthActionCreators.setIsFirstStep(false))
    }
    useEffect(() => {
        if (isAuth) {
            navigate("/fixtures")
        }
    }, [isAuth, navigate])

    const onSubmit: SubmitHandler<FieldValues> = () => {
        const userInfo = {
            username,
            verificationCode,
            staySignedIn: true
        }
        dispatch(AuthActionCreators.verifyUser(userInfo))
    };
    return (
        <div className={styles.formOverlay}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.secondStepForm}>
                <div className={styles.leftSide}>
                    <img src={secondStepIcon} alt="2FA-Icon"/>
                    <button
                        onClick={closeHandler}
                        type="submit"
                        className={styles.cancelButton}>
                        Cancel
                    </button>
                </div>
                <div className={styles.rightSide}>
                    <div className={styles.rightSideHeader}>
                        <h6 className={styles.formTitle}>Two factor authentication</h6>
                        <div onClick={closeHandler}>
                            <img className={styles.rightSideHeaderCross} src={closeIcon} alt="close-icon"/>
                        </div>
                    </div>
                    <p className={styles.formSubtitle}>
                        Enter the access code that was sent to your designated email.
                    </p>
                    <label className={styles.accessCodeLabel} htmlFor="verificationCode">Access code</label>
                    <input
                        {...register("verificationCode", {required: true})}
                        className={styles.verifyInput}
                        type="text"
                        name="verificationCode"
                        placeholder="Enter access code"
                        value={verificationCode}
                        onChange={handleChange}
                    />
                    <button
                        type="submit"
                        className={`${styles.cancelButton} ${styles.cancelButtonVerify}`}>
                        Verify
                    </button>
                    {errors.verificationCode && errors.verificationCode.type === "required" &&
                        <div
                            className={styles.formErrorBlock}>Verification code is required field
                        </div>
                    }
                    {errors.verificationCode && errors.verificationCode.type === "maxLength" &&
                        <div
                            className={styles.formErrorBlock}>Max length 10 symbols
                        </div>
                    }
                    {errors.verificationCode && errors.verificationCode.type === "minLength" &&
                        <div
                            className={styles.formErrorBlock}>Min length 10 symbols
                        </div>
                    }
                    {error &&
                        <div
                            className={styles.formErrorBlock}>{error}
                        </div>
                    }
                </div>
            </form>
        </div>
    );
};

export default React.memo(SecondStepForm);
