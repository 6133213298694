import './toogleSwitchBtn.scss'
import React from "react";
import Switch from "react-switch";

interface IToggleSwitchProps {
    label?: string,
    height?: number,
    width?: number
    handleDiameter?: number
    checked: boolean,
    setChecked?: (flag: boolean) => void
}

const ToggleSwitchBtn: React.FC<IToggleSwitchProps> = ({label, height, width, handleDiameter, checked}) => {

    return (
        <label className={'toggleSwitcher'}>
            <Switch
                onChange={(e) => () => e}
                checked={checked}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor='#EA4969'
                height={height}
                width={width}
                handleDiameter={handleDiameter}
            />
            <span className={'toggleSwitcherLabel'}>{label}</span>
        </label>
    );
}

export default React.memo(ToggleSwitchBtn);
