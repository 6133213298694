import { useEffect, useRef } from "react";

function deepCompareEquals(a: any, b: any): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}

export const useDeepCompareEffect = (
  callback: React.EffectCallback,
  dependencies: any[]
) => {
  const currentDependenciesRef = useRef<any[]>();

  if (!deepCompareEquals(currentDependenciesRef.current, dependencies)) {
    currentDependenciesRef.current = dependencies;
  }

  useEffect(callback, [currentDependenciesRef.current]);
};
