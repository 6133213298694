import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styles from './style.module.scss'
import {SingleValue} from "react-select";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {IInputOption} from "../../../store/reducers/fixtures/types";
import {useDispatch} from "react-redux";
import {ScheduleActionCreators} from "../../../store/reducers/schedule/action-creators";
import Button from "../../../components/Button";
import {client} from "../../../services/api-servise";
import CreatableSelectInp from "../../../components/CreatableSelect/CreatableSelect";
import SelectInput from "../../../components/SelectInput";
import Input from "../../../components/Input";


interface ISendEmailProps {
    setIsOpenMailForm: Dispatch<SetStateAction<boolean>>
    setIsOpenMenu: (flag: boolean) => void
    eventId: number
}

interface IEmailInfo {
    body: string
    subject: string
}

const SendEmail: React.FC<ISendEmailProps> = ({setIsOpenMailForm, eventId, setIsOpenMenu}) => {
    const dispatch = useDispatch()
    const [reason, setReason] = useState<SingleValue<any>>("")
    const [sendTo, setSendTo] = useState<SingleValue<any>>("")
    const {emailTemplate} = useTypedSelector(state => state.scheduleReducer)
    const [emailInfo, setEmailInfo] = useState<IEmailInfo>({} as IEmailInfo)
    const [emailBody, setEmailBody] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [address, setAddress] = useState("");
    const [isShowAddressInput, setIsShowAddressInput] = useState(false);
    useEffect(() => {
        setIsOpenMenu(false)
        dispatch(ScheduleActionCreators.getEmailTemplate())
    }, [])
    useEffect(() => {
        if (reason) {
            getEmailInfo()
        }
    }, [reason])

    const getEmailInfo = async () => {
        try {
            const currentEmailInfo = await client.get(`/api/Schedule/EmailInformation?emailTemplateId=${reason.value}&eventId=${eventId}`)
            setEmailInfo(currentEmailInfo.data)
            setEmailBody(currentEmailInfo.data.body)
            setEmailSubject(currentEmailInfo.data.subject)

        } catch (e) {
            console.log(e)
        }
    }
    const formatInputsOptions = (options: IInputOption[]) => {
        if (options) {
            return options.map(opt => ({value: opt.id, label: opt.name}))
        } else {
            return []
        }
    }
    const sendEmail = () => {
        if(emailBody.length !== emailInfo.body.length || emailSubject.length !== emailInfo.subject.length) {
            const body = {
                userId: sendTo.value?sendTo.value:null,
                emailTemplateId: reason.value,
                eventId,
                body:emailBody,
                subject:emailSubject,
                address:address
            }
            dispatch(ScheduleActionCreators.sendEmail(body))
            setIsOpenMailForm(false)
            return
        }
        else {
            const body = {
                userId: sendTo.value?sendTo.value:null,
                emailTemplateId: reason.value,
                eventId,
                body:"",
                subject:"",
                address: address
            }
            dispatch(ScheduleActionCreators.sendEmail(body))
            setIsOpenMailForm(false)
        }

    }

    const showAddressInp = () => {
        setIsShowAddressInput(!isShowAddressInput)
        setAddress("")
    }

    return (
        <div onClick={(e)=>e.stopPropagation()} className={styles.modalInner}>
            <form className={styles.sendEmailForm}>
                <button onClick={() => setIsOpenMailForm(false)} className={styles.closeBtn}/>
                <div className={styles.inputInner}>
                    <div className="hugeSelect">
                        <SelectInput
                            value={reason}
                            name={"Reason"}
                            onChange={setReason}
                            searchable={true}
                            options={formatInputsOptions(emailTemplate?.emailReasons?.items)}
                            label={'Reason'}/>
                    </div>
                    {!isShowAddressInput && <div className="hugeSelect">
                        <CreatableSelectInp
                            value={sendTo}
                            name={"Send To"}
                            onChange={setSendTo}
                            searchable={true}
                            options={formatInputsOptions(emailTemplate?.clientList?.items)}
                            label={'Send To'}/>
                    </div>}
                </div>
                <div className={styles.addressBtnInner}>
                    <div className={styles.addressBtn}>
                        <Button
                            onClick={showAddressInp}
                            value={isShowAddressInput?"Show existing address":"Add new address"}
                        />
                    </div>
                </div>
                {isShowAddressInput && <div className={styles.addressInput}>
                    <Input label={"Address"} value={address} readonly={false} setValue={setAddress}/>
                </div>}
                {Object.entries(emailInfo).length !== 0 &&
                    <div className={styles.sendBlock}>
                        <h6 className={styles.titleArea}>Subject:</h6>
                            <div className={styles.subjTextAreaInner}>
                            <textarea
                                className={styles.subjectTextArea}
                                value={emailSubject}
                                onChange={(e)=>setEmailSubject(e.target.value)}
                            >
                            </textarea>
                            </div>
                        <h6 className={styles.titleArea}>Body:</h6>
                        <div className={styles.textAreaInner}>
                            <textarea
                                className={styles.textArea}
                                value={emailBody}
                                onChange={(e)=>setEmailBody(e.target.value)}
                            >
                            </textarea>
                        </div>
                        <div>
                            <div className={styles.sendBtn}>
                                <Button onClick={sendEmail} value={"Send"}/>
                            </div>
                        </div>
                    </div>
                }
            </form>
        </div>
    );
};

export default React.memo(SendEmail);