import {call, takeEvery, put} from 'redux-saga/effects';
import {loginRequest, verifyRequest} from "./requests";
import {AuthActionCreators} from "./action-creators";
import {AuthActionEnum, IVerifyUser} from "./types";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";

export type VerifyResponse = {
    accessToken: string,
    refreshToken: string
    userId: number,
    username: string,
    fullName: string,
    email: string,
    forceUpdatePassword: boolean
}

function* tryLogin({payload}: any) {
    try {
        yield put (AuthActionCreators.setAuthLoading(true))
        yield put(AuthActionCreators.setAuthError(""))
        const resp: VerifyResponse | null = yield call(loginRequest, payload.username, payload.password)
        if (resp) {
            localStorage.removeItem("iptv-token")
            localStorage.setItem("iptv-token", resp.accessToken)
            yield put(AuthActionCreators.setIsAuth(true))
            yield put(AuthActionCreators.setIsFirstStep(true))
            yield put (AuthActionCreators.setAuthLoading(false))
            return
        }
        yield put(AuthActionCreators.setUsername(payload.username))
        yield put(AuthActionCreators.setIsFirstStep(true))
        yield put (AuthActionCreators.setAuthLoading(false))
    } catch (e) {
        yield put (AuthActionCreators.setAuthLoading(false))
        if (e instanceof AxiosError) {
            yield put(AuthActionCreators.setAuthError(e.response?.data.response))
        }
    }
}

function* tryLogout() {
    try {
        yield put(AuthActionCreators.setIsAuth(false))
        yield put(AuthActionCreators.setIsFirstStep(false))
    } catch (e: any) {
        if (e instanceof Error) {
            toastr.error('IGameMedia', e.message)
        }
    }
}

function* tryVerify({payload}: IVerifyUser) {
    try {
        yield put(AuthActionCreators.setAuthError(""))
        const resp: VerifyResponse = yield call(verifyRequest, payload.username, payload.verificationCode, payload.staySignedIn)
        yield put(AuthActionCreators.setUserInfo(resp))
        yield put(AuthActionCreators.setIsAuth(true))
        localStorage.setItem("iptv-token", resp.accessToken)
        localStorage.setItem("iptv-refresh", resp.refreshToken)

    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', `${e.response?.data.response}`,)
        }
    }
}

export default function* authSagas(): any {
    yield takeEvery(AuthActionEnum.TRY_LOGIN, tryLogin);
    yield takeEvery(AuthActionEnum.VERIFY_USER, tryVerify);
    yield takeEvery(AuthActionEnum.LOGOUT, tryLogout);
}
