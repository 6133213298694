import {useEffect, useCallback, useRef} from "react";

export const useDebounce = (fn: Function, deps: any, msDelay: number) => {
    const callback = useRef();
    const timeout = useRef();

    const createDebounce = useCallback(() => {
        timeout.current && clearTimeout(timeout.current);
        // @ts-ignore
        timeout.current = setTimeout(() => {
            // @ts-ignore
            callback.current();
        }, msDelay);
    }, [msDelay]);

    const clearTimeOut = useCallback(() => {
        timeout.current && clearTimeout(timeout.current);
    }, []);

    useEffect(() => {
        // @ts-ignore
        callback.current = fn;
    }, [fn]);

    useEffect(() => {
        createDebounce();
        return clearTimeOut;
    }, [deps]);
};