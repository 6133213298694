import {FixturesAction, FixturesActionEnum, IFilterItems, IFixturesData, IFixturesState} from "./types";
import moment from "moment";
import {addDays} from "date-fns";

const dateFrom = new Date()
const dateTo = new Date(moment(addDays(dateFrom, 3)).toDate())
const defaultState: IFixturesState = {
    fixtures: [],
    filterOptions: {
        sports: {} as IFilterItems,
        sportsSelected: [],
        competitions: {} as IFilterItems,
        regions: {} as IFilterItems,
        competitionsSelected: [],
        regionsSelected: []
    },
    filterValues: {
        dateRange: {
            from:{
                year:dateFrom.getFullYear(),
                month:dateFrom.getMonth() + 1,
                day:dateFrom.getDate()
            },to:{
                year:dateTo.getFullYear(),
                month:dateTo.getMonth() + 1,
                day:dateTo.getDate()
            }
            },
        competitionIds: [],
        sportIds: [],
        regionIds: []
    },
    isLoading: false
}

export const fixturesReducer = (state = defaultState, action: FixturesAction): IFixturesState => {
    switch (action.type) {
        case FixturesActionEnum.SET_FIXTURES: {
            const {
                data,
                regions,
                sports,
                sportsSelected,
                regionsSelected,
                competitions,
                competitionsSelected
            } = action.payload
            const fixtures = data.map(item => ({date: item.date, items: item.items}))
            return {
                ...state,
                fixtures: fixtures,
                filterOptions: {
                    ...state.filterOptions,
                    sports,
                    regions,
                    competitions,
                    competitionsSelected,
                    sportsSelected,
                    regionsSelected
                }
            }
        }
        case FixturesActionEnum.SET_FIXTURE_FILTER: {
            const {name, options} = action.payload
            return {...state, filterValues: {...state.filterValues, [name]: options}}
        }
        case FixturesActionEnum.SET_FILTER_DATE_RANGE: {
            if (action.payload?.from && action.payload.to) {
                return {
                    ...state,
                    filterValues: {...state.filterValues, dateRange:action.payload}
                }
            } else {
                return {...state}
            }
        }
        case FixturesActionEnum.SET_FIXTURES_LOADER: {
            return {...state, isLoading: action.payload}
        }
        case FixturesActionEnum.CLEAR_FIXTURES_FILTER: {
            const defaultFilter = {
                dateRange: {
                    from:{
                        year:dateFrom.getFullYear(),
                        month:dateFrom.getMonth() + 1,
                        day:dateFrom.getDate()
                    },to:{
                        year:dateTo.getFullYear(),
                        month:dateTo.getMonth() + 1,
                        day:dateTo.getDate()
                    }
                },
                competitionIds: [],
                sportIds: [],
                regionIds: []
            }
            return {...state, filterValues: defaultFilter}
        }
        case FixturesActionEnum.CREATE_EVENTS_FROM_FIXTURES_SUCCESS: {
            const currentPricingIds = action.payload
            const allFixtures = [] as IFixturesData[]
            state.fixtures.forEach(fixture=>{
                fixture.items = fixture.items.filter(item => !currentPricingIds.includes(item.fixtureId))
                allFixtures.push(fixture)
            })
            return {...state,fixtures:allFixtures}
        }
        default:
            return state
    }
}