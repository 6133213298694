import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Title from "../../../../components/Title";
import styles from './style.module.scss'
import {ICompetition} from "../../../../store/reducers/competitions/types";
import {IInputOption} from "../../../../store/reducers/fixtures/types";
import {useOutsideClick} from "../../../../hooks/useOutsideClick";
import FixtureContextMenu from "../../../Fixtures/FixturesTable/ContextMenu/FixtureContextMenu";

interface ICompetitionTableProps {
    competitions: ICompetition[];
    compTypes: IInputOption[]
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; competitionId: string; }>>
    setIsOpenModal: Dispatch<SetStateAction<boolean>>

}

const CompetitionTable: React.FC<ICompetitionTableProps> = ({
    competitions,
    compTypes,
    setEditInfo,
    setIsOpenModal,
    }) => {

    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
    const contextMenuRef = useRef<any>(null)
    const [selectedCompetitionInfo, setSelectedCompetitionInfo] = useState({fixtureId: "", competitionId: ""})
    const [selectedCellInfo, setSelectedCellInfo] = useState({competitionId:""})

    useEffect(() => {
        // @ts-ignore
        document.addEventListener('contextmenu', openContextMenu);
        return () => {
            // @ts-ignore
            document.removeEventListener('contextmenu', openContextMenu);
        }
    }, [selectedCellInfo])

    const closeMenu = () => {
        setIsOpenMenu(false)
    }
    useOutsideClick(contextMenuRef, closeMenu)

    const getCompName = (id: string) => {
        const findCompetition = compTypes.find(comp => comp.id === id)
        if (findCompetition) {
            return findCompetition.name
        }
    }

    const handleRowClick = async (event: any, fixtureId: string, competitionId: string) => {
        event.preventDefault()
        const currentCompId = event.target.className.split(" ")[0]
        if(currentCompId !== selectedCellInfo.competitionId) {
            return
        }
        await setIsOpenMenu(true)
        setSelectedCompetitionInfo({fixtureId, competitionId})
        const clickX = event.clientX;
        const clickY = event.clientY;
        const screenW = window.innerWidth;
        const screenH = window.innerHeight;
        const rootW = contextMenuRef.current!.offsetWidth;
        const rootH = contextMenuRef.current!.offsetHeight;
        const right = (screenW - clickX) > rootW;
        const left = !right;
        const top = (screenH - clickY) > rootH;
        const bottom = !top
        contextMenuRef.current.style.left = 0
        contextMenuRef.current.style.top = 0

        if (right) {
            contextMenuRef.current.style.left = `${clickX + 5}px`;
        }

        if (left) {
            contextMenuRef.current.style.left = `${clickX - rootW - 5}px`;
        }

        if (top) {
            contextMenuRef.current.style.top = `${clickY + 5}px`;
        }

        if (bottom) {
            contextMenuRef.current.style.top = `${clickY - rootH - 5}px`;
        }
    }


    const openEditModal = (id: string) => {
        setEditInfo({isEdit: true, competitionId: id})
        setIsOpenModal(true)
    }

    const getRowClass = (competitionId: string, selectedCellId: string) => {
        if (competitionId === selectedCellId) {
            return `${styles.tableRow} ${styles.blue}`
        }
        else {
            return `${styles.tableRow}`
        }

    }

    const openContextMenu = (e:React.MouseEvent) => {
        if(selectedCellInfo.competitionId) {
            return handleRowClick(e, "",selectedCellInfo.competitionId)
        }
    }

    return (
        <div>
            {isOpenMenu && <div ref={contextMenuRef} className={styles.fixtureMenu}>
                <FixtureContextMenu selectedFixtureInfo={selectedCompetitionInfo}/>
            </div>}
            <div className={styles.wrapper}>
                <div className={'tableTitle'}>
                    <Title value={'Edit & Link Competitions'}/>
                </div>
                <div className={styles.tableWrapper}>
                    <table className={styles.auditTable}>
                        <thead className={styles.tableHeadWrapper}>
                        <tr className={styles.tableRow}>
                            <th className={styles.tableHeader}>Id</th>
                            <th className={styles.tableHeader}>Name</th>
                            <th className={styles.tableHeader}>Data provider</th>
                            <th className={styles.tableHeader}>Linked Comps</th>
                            <th className={styles.tableHeader}>Future Events</th>
                            <th className={styles.tableHeader}>Sport</th>
                            <th className={styles.tableHeader}>Region</th>
                            <th className={styles.tableHeader}>Comp.Type</th>
                            <th className={styles.tableHeader}>Gender</th>
                            <th className={styles.tableHeader}>Last Updated</th>
                            <th className={styles.tableHeader}>Archive</th>
                            <th className={styles.tableHeader}/>
                        </tr>
                        </thead>
                        <tbody className={styles.tableBodyWrapper}>
                        {competitions.map(comp => {
                            return (
                                <tr
                                    onClick={()=>setSelectedCellInfo({competitionId: comp.id})}
                                    className={getRowClass(comp.id,selectedCellInfo.competitionId)}
                                    key={comp.id}>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>
                                        <div className={styles.textOverflow}><span>{comp.id}</span></div>
                                    </td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{comp.name}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{"No"}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{"No"}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{comp.futureEventsCount}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{comp.sportName}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{comp.countryName}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{getCompName(comp.type.toString())}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{comp.belongsToWomen ? "Women" : "Men"}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{comp.lastUpdated}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>{"No"}</td>
                                    <td className={`${comp.id} ${styles.tableDescription}`}>
                                        <div className={styles.controlBtns}>
                                            <button
                                                onClick={() => openEditModal(comp.id)}
                                                className={styles.updateBtn}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default React.memo(CompetitionTable);
