import {client} from "../../../services/api-servise";
import { ICreatedCompetition,  IUpdatedCompetition} from "./types";

export const getCompetitionsRequest = async (queryString:string): Promise<any> => {
    const competitions = await client.get(`/api/Management/competitions/get?${queryString}`)
    return competitions.data.data
}

export const getCompetitionsInit = async (): Promise<any> => {
    const init = await client.get('/api/Management/competitions/init')
    return init.data
}

export const createCompetitionRequest = async (payload:ICreatedCompetition): Promise<any> => {
    const competition = await client.post('/api/Competition',payload.newCompetition )
    return competition.data
}

export const updateCompetitionRequest = async (payload:IUpdatedCompetition): Promise<any> => {
    const competition = await client.put('/api/Competition',payload.newCompetition )
    return competition.data
}



