import {Day} from "react-calendar-datetime-picker";
import moment from "moment/moment";

export const formatRangeDate = (date: Day) => {
    let dateToFormat = {...date};
    if (dateToFormat.month) {
        dateToFormat.month = dateToFormat.month - 1;
    }
    return moment(dateToFormat).add(1, 'days').utc().format();
}
