import {call, takeEvery, put,} from 'redux-saga/effects';
import {
    ICreateSportTiming,
    IDeleteSportTiming, IGetSportTimings, IRestoreSportTiming, ISportTiming, IUpdateSportTiming,
    SportTimingsActionEnum
} from "./types";
import {SportTimingActionCreators} from "./action-creators";
import {
    createSportTimingRequest, deleteSportTimingRequest,
    getSportTimingRequest, restoreSportTimingRequest,
    updateSportTimingRequest
} from "./requests";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";

function* tryGetSportTimings({payload}: IGetSportTimings) {
    try {
        const resp: ISportTiming[] = yield call(getSportTimingRequest, payload)
        yield put(SportTimingActionCreators.setSportTiming(resp))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryCreateSportTiming({payload}: ICreateSportTiming) {
    try {
        const resp: ISportTiming = yield call(createSportTimingRequest, payload)
        yield put(SportTimingActionCreators.createSportTimingSuccess(resp))
        toastr.success('IGameMedia', 'Sport duration has been created',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryUpdateSportTiming({payload}: IUpdateSportTiming) {
    try {
        const resp: ISportTiming = yield call(updateSportTimingRequest, payload)
        yield put(SportTimingActionCreators.updateSportTimingSuccess(resp))
        toastr.success('IGameMedia', 'Sport duration has been updated',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryDeleteSportTiming({payload}: IDeleteSportTiming) {
    try {
        yield call(deleteSportTimingRequest, payload)
        yield put(SportTimingActionCreators.deleteTiming(payload))
        toastr.success('IGameMedia', 'Sport duration has been deleted',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryRestoreSportTiming({payload}: IRestoreSportTiming) {
    try {
        yield call(restoreSportTimingRequest, payload)
        yield put(SportTimingActionCreators.restoreSportTimingSuccess(payload))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

export default function* sportTimingsSagas(): any {
    yield takeEvery(SportTimingsActionEnum.GET_SPORT_TIMINGS, tryGetSportTimings);
    yield takeEvery(SportTimingsActionEnum.CREATE_SPORT_TIMING, tryCreateSportTiming);
    yield takeEvery(SportTimingsActionEnum.UPDATE_SPORT_TIMING, tryUpdateSportTiming);
    yield takeEvery(SportTimingsActionEnum.DELETE_SPORT_TIMING, tryDeleteSportTiming);
    yield takeEvery(SportTimingsActionEnum.RESTORE_SPORT_TIMING, tryRestoreSportTiming);
}