
import {IGetFixturesResp} from "./saga";
import {Range} from "react-calendar-datetime-picker";

export interface IFixturesState {
    fixtures: IFixturesData[]
    filterOptions: {
        sports:IFilterItems
        sportsSelected: string[],
        regions: IFilterItems
        regionsSelected: string[],
        competitions: IFilterItems
        competitionsSelected: string[],
    },
    filterValues: IFixturesFilter
    isLoading:boolean
}
export interface IFilterItems {
    items: IInputOption[]
}
export interface IInputOption {
    id: string,
    name: string
}
export interface IFixturesData {
    date:string,
    items: IFixture[]
}
export interface IFixture {
    competitionId: string;
    fixtureId: string
    startDate:string
    endDate: string
    information: string
    description: string
    competitionName: string
    sportName: string
    status:string
    modifierUsername: string
    createdDate: string
    region:string
}
export interface IFixturesFilter {
    dateRange: Range
    sportIds: string[]
    competitionIds: string[]
    regionIds: string[]
}

export interface IFixture {
    fixtureID:string
    startTime:string
    endTime:string
    information:string
    description:string,
    competition:string
    sport:string
    status:string
    modBy:string
    createdDate:string
    updateTimeWTU:string
}

export enum FixturesActionEnum {
    GET_FIXTURES = "GET_FIXTURES",
    SET_FIXTURES = "SET_FIXTURES",
    SET_FIXTURE_FILTER = "SET_FIXTURE_FILTER",
    SET_FILTER_DATE_RANGE = "SET_FILTER_DATE_RANGE",
    SET_FIXTURES_LOADER = "SET_FIXTURES_LOADER",
    CLEAR_FIXTURES_FILTER = "CLEAR_FIXTURES_FILTER",
    CREATE_EVENTS_FROM_FIXTURES = "CREATE_EVENTS_FROM_FIXTURES",
    CREATE_EVENTS_FROM_FIXTURES_SUCCESS = "CREATE_EVENTS_FROM_FIXTURES_SUCCESS"
}


export interface IGetFixtures {
    type: FixturesActionEnum.GET_FIXTURES,
    payload: string
}

export interface ISetFixtures {
    type: FixturesActionEnum.SET_FIXTURES,
    payload: IGetFixturesResp
}

export interface ISetFixtureFilter {
    type:FixturesActionEnum.SET_FIXTURE_FILTER,
    payload: {
        options:any[],
        name:string
    }
}
export interface ISetFilterDateRange {
    type: FixturesActionEnum.SET_FILTER_DATE_RANGE,
    payload: Range
}

export interface ISetFixturesLoader {
    type: FixturesActionEnum.SET_FIXTURES_LOADER,
    payload: boolean
}

export interface IClearFilter {
    type: FixturesActionEnum.CLEAR_FIXTURES_FILTER,
}
export interface ICreateEventsFromFixtures {
    type: FixturesActionEnum.CREATE_EVENTS_FROM_FIXTURES
    payload:string[]
}
export interface ICreateEventsFromFixturesSuccess {
    type: FixturesActionEnum.CREATE_EVENTS_FROM_FIXTURES_SUCCESS
    payload:string[]
}


export type FixturesAction =   ISetFixtures | ISetFixtureFilter |
    ISetFilterDateRange|ISetFixturesLoader |IClearFilter|ICreateEventsFromFixturesSuccess