import {IDay} from "react-calendar-datetime-picker/dist/type";
import moment from "moment";

export const formatDate = (date: IDay | undefined):string | undefined => {
    if (date) {
        const selectedMoment = {
            year: date?.year,
            month: date?.month - 1,
            day: date?.day,
            hour: date?.hour,
            minute: date?.minute
        }
        return moment(selectedMoment).format()
    }
}

 export const reFormatDate =(date:string | undefined) => {
    const currentDateObj = moment(date).toObject()
    return {
        year: currentDateObj.years,
        month: currentDateObj.months + 1,
        day: currentDateObj.date,
        hour: currentDateObj.hours,
        minute: currentDateObj.minutes
    }

}