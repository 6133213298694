import React from 'react';
import './style.scss'

const BtnLoader: React.FC = () => {
    return (
        <div className={"loader-inner"}>
            <div className={"loader"}/>
        </div>
    );
};

export default BtnLoader