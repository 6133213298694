import React, {Dispatch, SetStateAction, useState} from 'react';
import Title from "../../../../components/Title";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import Checkbox from "../../../../components/Checkbox";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {ChannelsActionCreators} from "../../../../store/reducers/channels/action-creators";
import {useDebounce} from "../../../../helpers/useDebounce";
import styles from './style.module.scss'
import {ReactComponent as Sort} from "../../../../img/tableSort.svg";

interface IChannelTableProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; channelId: number; }>>
    openConfirmMenu: () => void
}
const ChannelTable:React.FC<IChannelTableProps> = ({setIsOpenModal,setEditInfo,openConfirmMenu}) => {
    const dispatch = useDispatch()
    const {channels} = useTypedSelector(state => state.channelsReducer)
    const [searchValue, setSearchValue] = useState<string>('');
    const [isAscending, setIsAscending] = useState(true);
    const onOpenConfirmMenu = (channelId:number) => {
        setEditInfo({isEdit:false,channelId})
        openConfirmMenu()
    }

    const [initialized, setInitialized] = useState<boolean>(false);

    const onSortChannelByName = () => {
        setIsAscending(!isAscending)
        const payload = {
            channelIds: [],
            keyword: searchValue,
            hasToBeAvailable: true,
            sortingModel: {
                property: "name",
                isAscending: !isAscending
            }
        }
        dispatch(ChannelsActionCreators.getChannels(payload))

    }

    const openEditForm = (channelId:number) => {
        setEditInfo({isEdit:true,channelId})
        setIsOpenModal(true)
    }

    const restoreDeletedChannel = (channelId:number) => {
        dispatch(ChannelsActionCreators.restoreChannel(channelId))
    }

    const findChannelByName = () => {
        if(initialized){
            const payload = {
                channelIds: [],
                keyword: searchValue,
                hasToBeAvailable: true
            }
            dispatch(ChannelsActionCreators.getChannels(payload))
        }
        setInitialized(true)
    }

    useDebounce(findChannelByName,searchValue,500)

    return (
        <div className={styles.wrapper}>
            <div className={styles.tableTitle}>
                <Title value={'Saved Servers'}/>
                <div className={styles.tableControls}>
                    <div>
                        <Input
                            label={"Search"}
                            placeholder={"Enter server's name"}
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            readonly={false}
                        />
                    </div>
                    <div className="regularBtn">
                        <Button onClick={() => setIsOpenModal(true)} value={'Create'}/>
                    </div>
                </div>
            </div>
            <table className={styles.decodersTable}>
                <thead className={styles.tableHeadWrapper}>
                <tr className={styles.tableRow} key={1}>
                    <th onClick={()=>onSortChannelByName()} className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                        Server Name
                        <Sort className={isAscending?`${styles.tableSortAsc}`:`${styles.tableSort}`}/>
                    </th>
                    <th className={styles.tableHeaderDeleted}>Deleted</th>
                    <th className={styles.tableHeaderDeleted}/>
                </tr>
                </thead>
                <tbody className={styles.tableBodyWrapper}>
                {channels.map(channel => {
                    return (
                        <tr className={styles.tableRow} key={channel.id}>
                            <td className={styles.tableDescDecoder}>{channel.name}</td>
                            <td className={styles.tableDescDeleted}>
                                <div
                                    className={styles.decodersCheckbox}>
                                    <Checkbox
                                        isChecked={channel.isDeleted}
                                    />
                                </div>
                            </td>
                            <td className={styles.tableDescDecoder}>
                                <div className={styles.controlBtns}>
                                    <button
                                        onClick={() => openEditForm(channel.id)}
                                        className={styles.updateBtn}
                                    />
                                    {!channel.isDeleted
                                        ?
                                        <button
                                            onClick={() => onOpenConfirmMenu(channel.id)}
                                            className={styles.removeBtn}
                                        />
                                        :
                                        <button
                                            onClick={() => restoreDeletedChannel(channel.id)}
                                            className={styles.undeleteBtn}>
                                            Restore
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
};

export default ChannelTable;
