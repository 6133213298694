import {Navigate} from "react-router-dom";
import React from "react";

interface IProps {
    children: any
}

const ProtectedRoute: React.FC<IProps> = ({children}) => {
    const user = localStorage.getItem("iptv-token")
    if (!user) {
        return <Navigate to="/login"/>;
    }
    return children;
};

export default React.memo(ProtectedRoute);
