import {client} from "../../../services/api-servise";

export const getDecodersRequest = async (queryString:string): Promise<any> => {
    const decoders = await client.get(`/api/Management/decoders/get${queryString}`, )
    return decoders.data
}

export const createDecoderRequest = async (payload:{name:string}): Promise<any> => {
    const decoder = await client.post('/api/Decoder',payload )
    return decoder.data
}

export const updateDecoderRequest = async (payload:{name:string,id:number}): Promise<any> => {
    const decoder = await client.put('/api/Decoder',payload )
    return decoder.data
}
export const deleteDecoderRequest = async (payload:number): Promise<any> => {
    const decoder = await Promise.all([client.delete(`/api/Decoder/${payload}`)])
    return decoder
}

export const toggleDeleteDecoderRequest = async (payload:number): Promise<any> => {
    const decoder = await client.delete(`api/Decoder/ToggleDelete?id=${payload}`)
    return decoder.data
}



