import {
    UsersActionEnum,
    IGetUsers,
    ISetUsers,
    IUser,
    IGetUsersRoles,
    ISetUsersRoles,
    IUsersRoles,
    IAddingUser,
    IAddUser,
    IUpdatingUser,
    IUpdateUser,
    IDeleteUser,
    IDeleteUserSuccess,
    IGetSearchUsers,
    IResetPassword,
    IConfirmResetPassword, IUpdateUserSuccess
} from './types';


export const UsersActionCreators = {
    getUsers: (): IGetUsers => ({type: UsersActionEnum.GET_USERS}),
    getSearchUsers: (payload: string): IGetSearchUsers => ({type: UsersActionEnum.GET_SEARCH_USERS, payload}),
    setUsers: (payload: IUser[]): ISetUsers => ({type: UsersActionEnum.SET_USERS, payload}),
    setUsersLoader:(payload:boolean) => ({type:UsersActionEnum.SET_USERS_LOADER,payload}),
    getUsersRoles: (): IGetUsersRoles => ({type: UsersActionEnum.GET_USERS_ROLES}),
    setUsersRoles: (payload: IUsersRoles): ISetUsersRoles => ({type: UsersActionEnum.SET_USERS_ROLES, payload}),
    addUser: (payload: IAddingUser): IAddUser => ({type: UsersActionEnum.ADD_USER, payload}),
    updateUser: (payload: IUpdatingUser): IUpdateUser => ({type: UsersActionEnum.UPDATE_USER, payload}),
    updateUserSuccess: (payload: IUpdatingUser): IUpdateUserSuccess => ({type: UsersActionEnum.UPDATE_USER_SUCCESS, payload}),
    deleteUser: (payload: number): IDeleteUser => ({type: UsersActionEnum.DELETE_USER, payload}),
    deleteUserSuccess: (payload: number): IDeleteUserSuccess => ({type: UsersActionEnum.DELETE_USER_SUCCESS, payload}),
    resetPassword: (payload: string): IResetPassword => ({type: UsersActionEnum.RESET_PASSWORD, payload}),
    confirmResetPassword: (payload: string): IConfirmResetPassword => ({
        type: UsersActionEnum.CONFIRM_RESET_PASSWORD,
        payload
    })
}