import React from 'react';
import {Route, Routes, useSearchParams} from "react-router-dom";
import {privateRouters} from "../route";
import Header from "./Header";
import Login from "../pages/Login/Login";
import ProtectedRoute from "./PrivateRouters/PrivateRouter";
import Fixtures from "../pages/Fixtures/Fixtures";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import ReduxToastr from "react-redux-toastr";
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import './Toastr/style.scss'

const AppRouter: React.FC = () => {
    const user = localStorage.getItem("iptv-token");

    const [searchParams] = useSearchParams();
    const change = searchParams.get('change')

    return (
        <>
            {change ? null : <Header/>}
            <Routes>
                {user ? <Route index element={<Fixtures/>}/> :
                    <Route index element={<Login/>}/>} {privateRouters.map(route => {
                return <Route key={route.path} path={route.path}
                              element={<ProtectedRoute children={<route.element/>}/>}/>;
            })}
                <Route path="/login" element={change ? <ResetPassword/> : <Login/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
            </Routes>

            <ReduxToastr
                className="toastr"
                timeOut={4000}
                position="top-right"
                transitionIn="fadeIn"
                transitionOut="fadeOut"
                progressBar
            />
        </>
    )
}

export default AppRouter;
