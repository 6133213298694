import styles from './style.module.scss'
import Title from "../../../../components/Title";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import React, {Dispatch, SetStateAction, useEffect} from "react";
import {DecodersActionCreators} from "../../../../store/reducers/decoders/action-creators";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {Controller, useForm} from "react-hook-form";

interface IDecodersModalProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    editInfo: { isEdit: boolean, decoderId: number }
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; decoderId: number; }>>
}

const DecodersModal: React.FC<IDecodersModalProps> = ({setIsOpenModal, setEditInfo, editInfo}) => {
    const dispatch = useDispatch()
    const {decoders} = useTypedSelector(state => state.decodersReducer)
    const {handleSubmit, control, getValues,reset, setValue, formState: {errors},} = useForm({
        defaultValues: {
            decoder: "",
        }
    });

    const closeModal = () => {
        setIsOpenModal(false)
        setEditInfo({isEdit: false, decoderId: 0})
        reset()
    }

    const setDefaultInputValue = () => {
        const currentDecoder = decoders.find(dec => dec.id === editInfo.decoderId)
        if (currentDecoder) {
            setValue("decoder",currentDecoder.name)
        }
    }

    useEffect(() => {
        if (editInfo.isEdit) {
            setDefaultInputValue()
        }
    }, [editInfo.isEdit])

    const addDecoder = () => {
        const payload = {name: getValues("decoder")}
        closeModal()
        dispatch(DecodersActionCreators.createDecoder(payload))
    }
    const updateDecoder = () => {
        const payload = {
            id: editInfo.decoderId,
            name: getValues("decoder")
        }
        closeModal()
        dispatch(DecodersActionCreators.updateDecoder(payload))
    }
    return (
        <div className={styles.decodersModal}>
            <form
                onSubmit={editInfo.isEdit ? handleSubmit(updateDecoder) : handleSubmit(addDecoder)}
                className={styles.modalForm}
            >
                <button
                    onClick={closeModal}
                    className={styles.closeBtn}
                />
                <Title value={editInfo.isEdit ? 'Update Decoder' : 'Add decoder'}/>
                <div className="largeSelect mt15">
                    <Controller
                        name="decoder"
                        control={control}
                        rules={{required: true}}
                        render={({field: {onChange, value}}) =>  <Input
                            value={value}
                            setValue={onChange}
                            label={'Decoder'}
                            readonly={false}
                        />}
                    />
                </div>
                {errors.decoder?.type === 'required' && <p className={styles.formError}>Decoder is required field</p>}
                <div className={styles.addDecoderBtn}>
                    <Button
                        type={"submit"}
                        value={editInfo.isEdit ? 'Update Decoder' : 'Add decoder +'}
                    />
                </div>
            </form>
        </div>
    );
};

export default React.memo(DecodersModal);