import {ISportTimingsState, SportTimingAction, SportTimingsActionEnum} from "./types";

const defaultState: ISportTimingsState = {
    sportTimings: []
}

export const sportTimingsReducer = (state = defaultState, action: SportTimingAction): ISportTimingsState => {

    switch (action.type) {
        case SportTimingsActionEnum.SET_SPORT_TIMINGS: {
            return {...state, sportTimings: action.payload}
        }

        case SportTimingsActionEnum.CREATE_SPORT_TIMING_SUCCESS: {
            const allSportTimings = state.sportTimings.map(sport => sport)
            allSportTimings.unshift(action.payload)
            return {...state, sportTimings: allSportTimings}
        }

        case SportTimingsActionEnum.UPDATE_SPORT_TIMING_SUCCESS: {
            const updatedSportTimings = state.sportTimings.map(sport => sport.id === action.payload.id ? action.payload : sport)
            return {...state, sportTimings: updatedSportTimings}
        }

        case SportTimingsActionEnum.DELETE_TIMING: {
            const updatedSportTimings = state.sportTimings.map(sport => {
                if (sport.id === action.payload) {
                    sport.isDeleted = true
                    return sport
                }
                return sport
            })
            return {...state, sportTimings: updatedSportTimings}
        }

        case SportTimingsActionEnum.RESTORE_SPORT_TIMING_SUCCESS: {
            const updatedSportTimings = state.sportTimings.map(sport => {
                if (sport.id === action.payload) {
                    sport.isDeleted = false
                    return sport
                }
                return sport
            })
            return {...state, sportTimings: updatedSportTimings}
        }

        default:
            return state
    }
}