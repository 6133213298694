import styles from './context-menu.module.scss'
import ToggleSwitchBtn from "../../../components/Button/Toggle-Switch";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {ScheduleActionCreators} from "../../../store/reducers/schedule/action-creators";
import {useNavigate} from "react-router-dom";
import {ISchedule} from '../../../store/reducers/schedule/types';

interface IContextMenuProps {
    editCellInfo: { eventId: number, compId: string, fixtureId: string }
    setIsOpenMailForm: Dispatch<SetStateAction<boolean>>
    setIsOpenEditModal: () => void
    setEditCellInfo: Dispatch<React.SetStateAction<{ eventId: number, compId: string, fixtureId: string, event: ISchedule | null }>>
    setIsOpenMenu: (flag: boolean) => void
}

const ContextMenu: React.FC<IContextMenuProps> = ({
    editCellInfo,
    setIsOpenMailForm,
    setIsOpenEditModal,
    setEditCellInfo,
    setIsOpenMenu
    }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [verified, setVerified] = useState(false)
    const [isInternal, setIsInternal] = useState(false)
    const [isProtected, setIsProtected] = useState(false)
    const [canceled, setCanceled] = useState(false)
    const {groupedSchedules} = useTypedSelector(state => state.scheduleReducer)

    useEffect(() => {
        setDefaultValues()
    }, [])

    const setDefaultValues = () => {
        const allSchedules = collectAllSchedule()
        const editEvent = allSchedules.find(event => event.eventId === editCellInfo.eventId)
        if (editEvent) {
            setVerified(editEvent.verified)
            setIsInternal(editEvent.isInternal)
            setIsProtected(editEvent.isProtected)
            setCanceled(editEvent.isCanceled)
        }
    }

    const collectAllSchedule = () => {
        let allSchedules: ISchedule[] = []
        groupedSchedules.forEach(schedule => {
            const currentSchedules = schedule.schedules
            currentSchedules.forEach(currentSchedule => {
                allSchedules.push(currentSchedule)
            })
        })
        return allSchedules
    }

    const setVerify = (checked: boolean) => {
        setVerified(checked)
        dispatch(ScheduleActionCreators.setIsVerify({i: editCellInfo.eventId, b: checked}))
    }

    const setProtection = (checked: boolean) => {
        setIsProtected(checked)
        dispatch(ScheduleActionCreators.setProtection({i: editCellInfo.eventId, b: checked}))
    }

    const setInternal = (checked: boolean) => {
        setIsInternal(checked)
        dispatch(ScheduleActionCreators.setInternal({i: editCellInfo.eventId, b: checked}))
    }

    const cancelEvent = (checked: boolean) => {
        setCanceled(checked)
        dispatch(ScheduleActionCreators.cancelEvent({i: editCellInfo.eventId, b: checked}))
    }

    const expireEvent = () => {
        dispatch(ScheduleActionCreators.expireEvent(editCellInfo.eventId))
    }

    const removeEvent = () => {
        dispatch(ScheduleActionCreators.removeEvent(editCellInfo.eventId))
        setEditCellInfo({eventId: 0, compId: "", fixtureId: "", event: null})
        setIsOpenMenu(false)

    }

    const duplicateEvent = () => {
        dispatch(ScheduleActionCreators.duplicateEvent(editCellInfo.eventId))
    }
    const openEmailForm = () => {
        setIsOpenMailForm(true)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }
    return (
        <ul onClick={(e) => e.stopPropagation()} className={styles.contextMenu}>
            <li onClick={setIsOpenEditModal} className={styles.item}>Edit</li>
            <li onClick={openEmailForm} className={styles.item}>Send Email</li>
            <li onClick={() => setVerify(!verified)} className={styles.item}>
                <span>Verify</span>
                <ToggleSwitchBtn
                    checked={verified}
                    height={17}
                    width={40}
                    handleDiameter={12}
                />
            </li>
            <li onClick={() => setProtection(!isProtected)} className={styles.item}>
                <span>Protection</span>
                <ToggleSwitchBtn
                    checked={isProtected}
                    height={17}
                    width={40}
                    handleDiameter={12}
                />
            </li>
            <li onClick={() => setInternal(!isInternal)} className={styles.item}>
                <span>Internal Schedule</span>
                <ToggleSwitchBtn
                    checked={isInternal}
                    setChecked={setInternal}
                    height={17}
                    width={40}
                    handleDiameter={12}
                />

            </li>
            <li onClick={duplicateEvent} className={styles.item}>Duplicate</li>
            <li onClick={expireEvent} className={styles.item}>Expire</li>
            <li onClick={removeEvent} className={styles.item}>Remove</li>
            <li onClick={() => cancelEvent(!canceled)} className={styles.item}>
                Cancel
                <ToggleSwitchBtn
                    checked={canceled}
                    setChecked={setCanceled}
                    height={17}
                    width={40}
                    handleDiameter={12}
                />
            </li>
            {editCellInfo.fixtureId && <li
                onClick={() => navigate(`/audits/fixture/${editCellInfo.fixtureId}`)}
                className={styles.item}>Fixture Audit Trail
            </li>}
            <li
                onClick={() => navigate(`/audits/event/${editCellInfo.eventId}`)}
                className={styles.item}>Event Audit Trail
            </li>
            <li
                onClick={() => navigate(`/audits/competition/${editCellInfo.compId}`)}
                className={styles.item}>Competition Audit Trail
            </li>
        </ul>
    )
}


export default React.memo(ContextMenu);