export interface ISportTiming {
    id: string,
    name: string,
    duration: number,
    isDeleted: boolean
}

export interface ISportTimingsState {
    sportTimings: ISportTiming[]
}

export interface ICreateSportTimingBody {
    name: string,
    durationInMinutes: number
}

export enum SportTimingsActionEnum {
    GET_SPORT_TIMINGS = "GET_SPORT_TIMINGS",
    SET_SPORT_TIMINGS = "SET_SPORT_TIMINGS",
    CREATE_SPORT_TIMING = "CREATE_SPORT_TIMING",
    CREATE_SPORT_TIMING_SUCCESS = "CREATE_SPORT_TIMING_SUCCESS",
    UPDATE_SPORT_TIMING = "UPDATE_SPORT_TIMING",
    UPDATE_SPORT_TIMING_SUCCESS = "UPDATE_SPORT_TIMING_SUCCESS",
    DELETE_SPORT_TIMING = "DELETE_SPORT_TIMING",
    DELETE_TIMING = "DELETE_TIMING",
    RESTORE_SPORT_TIMING = "RESTORE_SPORT_TIMING",
    RESTORE_SPORT_TIMING_SUCCESS = "RESTORE_SPORT_TIMING_SUCCESS"
}

export interface IGetSportTimingsPayload {
    keyword: string,
    sportIds: string[],
    usedInSchedule: true
    sortingModel: {
        property: string,
        isAscending: boolean
    },
}

export interface IUpdateSportTimingPayload {
    id: string,
    durationInMinutes: number,
    name: string
}

export interface IGetSportTimings {
    type: SportTimingsActionEnum.GET_SPORT_TIMINGS
    payload: IGetSportTimingsPayload | {}
}

export interface ISetSportTimings {
    type: SportTimingsActionEnum.SET_SPORT_TIMINGS
    payload: ISportTiming[]
}

export interface ICreateSportTiming {
    type: SportTimingsActionEnum.CREATE_SPORT_TIMING
    payload: ICreateSportTimingBody
}

export interface ICreateSportTimingSuccess {
    type: SportTimingsActionEnum.CREATE_SPORT_TIMING_SUCCESS
    payload: ISportTiming
}

export interface IUpdateSportTiming {
    type: SportTimingsActionEnum.UPDATE_SPORT_TIMING
    payload: IUpdateSportTimingPayload
}

export interface IUpdateSportTimingSuccess {
    type: SportTimingsActionEnum.UPDATE_SPORT_TIMING_SUCCESS
    payload: ISportTiming
}

export interface IDeleteSportTiming {
    type: SportTimingsActionEnum.DELETE_SPORT_TIMING
    payload: string
}

export interface IDeleteTiming {
    type: SportTimingsActionEnum.DELETE_TIMING
    payload: string
}

export interface IRestoreSportTiming {
    type: SportTimingsActionEnum.RESTORE_SPORT_TIMING
    payload: string
}

export interface IRestoreSportTimingSuccess {
    type: SportTimingsActionEnum.RESTORE_SPORT_TIMING_SUCCESS
    payload: string
}


export type SportTimingAction = ISetSportTimings | IDeleteTiming |
    ICreateSportTimingSuccess | IUpdateSportTimingSuccess | IRestoreSportTimingSuccess