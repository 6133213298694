import {IInputOption} from "../fixtures/types";
import {IGetEventsResp} from "./saga";

export interface IEvent {
    fixtureId: string,
    eventId: number,
    startTime: string,
    endTime: string,
    channelId: number,
    channelName: string,
    broadcasterId: number,
    broadcasterName: string,
    decoderId: number,
    decoderName: string,
    sportId: string,
    sportName: string,
    competitionId: string,
    competitionName: string,
    countryId: string,
    countryName: string,
    home: string,
    away: string,
    info: string,
    description: string,
    package: string,
    isVerified: boolean,
    isProtected: boolean,
    isCanceled: boolean,
    lastModifierUserId: number,
    lastModifierUsername: string,
    lastModifyDate: string,
    endDate: string,
    startDate: string,
    group: string,
    isInternal: boolean,
    round: string,
    isManualEvent: boolean,
    season: string
}


export interface IEventsInit {
    sports: {
        items: IInputOption[]
    },
    regions: {
        items: IInputOption[]
    }
    competitions: {
        items: IInputOption[]
    }

}

export interface IEventFixture {
    competitionName: string
    createdDate: string
    description: string
    endDate: string
    fixtureId: string
    information: string
    modifierUsername: string
    sportName: string
    startDate: string
    status: string
}

export interface ICreatedEvent {
    competitionId: string,
    broadcasterId: number,
    decoderId: number,
    channelId: number,
    season: string,
    round: string,
    group: string,
    description: string,
    home: string,
    away: string,
    startDate: string | undefined,
    endDate: string | undefined,
    isVerified: boolean,
    isInternal: boolean,
    isProtected: boolean,
    sportId: string,
    countryId: string
}

export interface IUpdatedEvent {
    eventId: number
    isVerified:boolean
    isInternal:boolean
    home: string
    away: string
    season: string
    round: string
    group: string
    description: string
    finishDate: string | undefined
    endDate: string | undefined
    startDate: string | undefined
    broadcasterId: number | null
    decoderId: number | null
    channelId: number | null
    competitionId: string
}

export interface IEventsInfo {
    hasNextPage: false
    hasPreviousPage: false
    items: IEvent[]
    pageIndex: 1
    totalCount: null
    totalPages: 0
}


export interface IEventState {
    events: IEventsInfo
    init: IEventsInit
    eventsFixtures: IEventFixture[]
    fixturesCount: number
}

export enum EventActionEnum {
    GET_EVENTS = "GET_EVENTS",
    SET_EVENTS = "SET_EVENTS",
    CREATE_EVENT = "CREATE_EVENT",
    DELETE_EVENT = "DELETE_EVENT",
    UPDATE_EVENT = "UPDATE_EVENT",
    MULTI_CREATE_EVENTS = "MULTI_CREATE_EVENTS",
    DELETE_EVENTS_FIXTURE = "DELETE_EVENTS_FIXTURE",
}

export interface IGetEvents {
    type: EventActionEnum.GET_EVENTS
    queryString: string
}

export interface ISetEvents {
    type: EventActionEnum.SET_EVENTS
    payload: IGetEventsResp
}

export interface ICreateEvent {
    type: EventActionEnum.CREATE_EVENT
    payload: {
        event: ICreatedEvent,
        queryString: string
    }
}

export interface IMultiCreateEvents {
    type: EventActionEnum.MULTI_CREATE_EVENTS
    payload: {
        fixtures: string[],
        queryString: string
    }
}

export interface IDeleteEventsFixture {
    type: EventActionEnum.DELETE_EVENTS_FIXTURE
    payload: string[]
}

export interface IUpdateEvent {
    type: EventActionEnum.UPDATE_EVENT
    payload: {
        event: IUpdatedEvent,
        queryString: string
    }
}

export interface IDeleteEvent {
    type: EventActionEnum.DELETE_EVENT
    payload: {
        eventId: number,
        queryString: string
    }
}


export type EventsAction = ISetEvents | IDeleteEventsFixture