import {DecodersAction, DecodersActionEnum, IDecodersState} from "./types";

const defaultState: IDecodersState = {
    decoders: []
}

export const decodersReducer = (state = defaultState, action: DecodersAction): IDecodersState => {

    switch (action.type) {
        case DecodersActionEnum.SET_DECODERS: {
            return {...state, decoders: action.payload}
        }
        case DecodersActionEnum.DELETE_DECODER_SUCCESS: {
            const decoders = state.decoders.map(dec => dec.id === action.payload ? {...dec, isDeleted: true} : dec)
            return {...state, decoders}
        }
        case DecodersActionEnum.TOGGLE_DELETE_DECODER_SUCCESS: {
            const decoders = state.decoders.map(dec => dec.id === action.payload.id ? action.payload : dec)
            return {...state,decoders}
        }
        default:
            return state
    }
}