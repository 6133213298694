import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import Title from "../../../../components/Title";
import Input from "../../../../components/Input";
import SelectInput from "../../../../components/SelectInput";
import {SingleValue} from "react-select";
import Button from "../../../../components/Button";
import {useDispatch} from "react-redux";
import TimeCalendar from "../../../../components/TimeCalendar/TimeCalendar";
import {IInputOption} from "../../../../store/reducers/fixtures/types";
import {client} from "../../../../services/api-servise";
import {IEvent, IUpdatedEvent} from "../../../../store/reducers/event/types";
import styles from './style.module.scss'
import CreatableSelectInp from "../../../../components/CreatableSelect/CreatableSelect";
import {IDay} from "react-calendar-datetime-picker/dist/type";
import {formatDate, reFormatDate} from "../../../../helpers/TimePickerHelper";
import {EventsActionCreator} from "../../../../store/reducers/event/action-creators";
import moment from "moment";
import ToggleSwitchBtn from "../../../../components/Button/Toggle-Switch";

interface IEventUpdateModalProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    filter: { sportId: SingleValue<string>, regionId: SingleValue<string>, competitionId: SingleValue<string> }
    getQueryString: (filter: { sportId: string, regionId: string, compId: string }) => string
    events: IEvent[]
    editEventId: number
}

interface IInitFormOptions {
    broadcasters: {
        items: IInputOption[]
    },
    decoders: {
        items: IInputOption[]
    },
    servers: {
        items: IInputOption[]
    }
    seasons: string []
    rounds: string[]
}

const EventUpdateModal: React.FC<IEventUpdateModalProps> = ({
        setIsOpenModal,
        filter,
        getQueryString,
        editEventId,
        events
    }) => {
    const dispatch = useDispatch()
    const [sport] = useState<SingleValue<any>>(filter.sportId)
    const [region] = useState<SingleValue<any>>(filter.regionId)
    const [competition] = useState<SingleValue<any>>(filter.competitionId)
    const [season, setSeason] = useState<SingleValue<any>>("")
    const [round, setRound] = useState<SingleValue<any>>("")
    const [group, setGroup] = useState("")
    const [description, setDescription] = useState("")
    const [home, setHome] = useState('')
    const [away, setAway] = useState('')
    const [startDate, setStartDate] = useState<IDay | undefined>(undefined);
    const [endDate, setEndDate] = useState<IDay | undefined>(undefined);
    const [broadcaster, setBroadcaster] = useState<SingleValue<any>>([])
    const [decoder, setDecoder] = useState<SingleValue<any>>([])
    const [server, setServer] = useState<SingleValue<any>>([])
    const [isInternal, setIsInternal] = useState(false)
    const [isVerified, setIsVerified] = useState(false)
    const [isOpenStartDate, setIsOpenStartDate] = useState(false)
    const [isOpenEndDate, setIsOpenEndDate] = useState(false)
    const [initOptions, setInitOptions] = useState({} as IInitFormOptions)
    const [isValidEndTime, setIsValidEndTime] = useState(true);

    useEffect(() => {
        setDefaultFormValue()
    }, [])

    const setDefaultFormValue = async () => {
        const currentEvent = events.find(event => event.eventId === editEventId)
        if (currentEvent) {
            const initOptions: IInitFormOptions = await getInitOptions()
            const decoder = initOptions.decoders.items.find(decoder => decoder.id === currentEvent.decoderId?.toString())
            const broadcaster = initOptions.broadcasters.items.find(broadcaster => broadcaster.id === currentEvent.broadcasterId?.toString())
            const server = initOptions.servers.items.find(server => server.id === currentEvent.channelId?.toString())
            const season = initOptions.seasons.find(season => season === currentEvent.season)
            const round = initOptions.rounds.find(round => round === currentEvent.round)
            setSeason({value: season, label: season})
            setRound({value: round, label: round})
            setGroup(currentEvent.group)
            setDescription(currentEvent.description)
            setHome(currentEvent.home)
            setAway(currentEvent.away)
            setStartDate(reFormatDate(currentEvent.startDate))
            setEndDate(reFormatDate(currentEvent.endDate))
            setIsInternal(currentEvent.isInternal)
            setIsVerified(currentEvent.isVerified)
            setDecoder({value: decoder?.id, label: decoder?.name})
            setBroadcaster({value: broadcaster?.id, label: broadcaster?.name})
            setServer({value: server?.id, label: server?.name})
        }
    }

    const getChannels = async (decoderId: number) => {
        try {
            const channels = await client.get(`/api/Event/Channels?decoderId=${decoderId}`)
            setInitOptions({...initOptions, servers: channels.data.channels})
        } catch (e) {
            console.log(e)
        }
    }

    const getDecoders = async (channelId: number) => {
        try {
            const decoders = await client.get(`/api/Event/Decoders?channelId=${channelId}`)
            setInitOptions({...initOptions, decoders: decoders.data.decoders})
        } catch (e) {
            console.log(e)
        }
    }

    const onChangeDecoder = (e: SingleValue<any>) => {
        setDecoder(e)
        getChannels(e.value)

    }

    const onChangeServer = (e: SingleValue<any>) => {
        setServer(e)
        getDecoders(e.value)

    }

    const clearForm = () => {
        setSeason("")
        setRound("")
        setGroup("")
        setDescription("")
        setIsInternal(false)
        setIsVerified(false)
        setServer([])
        setDecoder([])
        setBroadcaster([])
        setHome("")
        setAway("")
    }

    const getInitOptions = async () => {
        try {
            const inputsValues = await client.get(`/api/Event/Init?competitionId=${competition.value}`)
            setInitOptions(inputsValues.data)
            return inputsValues.data
        } catch (e) {
            console.log(e)
        }
    }
    
    const checkIsCorrectEndTime = (startDate: IDay | undefined, endDate: IDay | undefined): boolean => {
        const start = moment(formatDate(startDate))
        const end = moment(formatDate(endDate))
        const diff = moment.duration(end.diff(start)).asMilliseconds();
        setIsValidEndTime(false)
        return !(diff === 0 || diff < 0);

    }

    const updateEvent = () => {
        if (!checkIsCorrectEndTime(startDate, endDate)) {
            return
        }
        const event: IUpdatedEvent = {
            competitionId: competition.value,
            eventId: editEventId,
            home,
            away,
            season: season.label,
            round: round.label,
            group,
            description,
            isVerified,
            isInternal,
            finishDate: formatDate(endDate),
            startDate: formatDate(startDate),
            endDate: formatDate(endDate),
            broadcasterId:Number(broadcaster.value) === 0 ? null : Number(broadcaster.value),
            decoderId:Number(decoder.value) === 0 ? null : Number(decoder.value),
            channelId:Number(server.value) === 0? null : Number(server.value),
        }

        const mainFilter = {
            sportId: sport?.value,
            regionId: region?.value,
            compId: competition?.value
        }

        const payload = {
            event,
            queryString: getQueryString(mainFilter)
        }
        clearForm()
        setIsOpenModal(false)
        dispatch(EventsActionCreator.updateEvent(payload))
    }

    const formatOptions = useCallback((options: IInputOption[]) => {
        if (options) {
            const allOptions =  options?.map(opt => ({value: opt?.id, label: opt?.name}))
            allOptions.unshift({value: "0", label: "Blank"})
            return allOptions
        } else {
            return []
        }
    }, [initOptions])

    const formatStringOptions = useCallback((options: string[]) => {
        if (!options) return [];

        return [{ value: "0", label: "Blank" }, ...options.map(opt => ({ value: opt, label: opt }))];    
    }, [])

    const closeCalendars = () => {
        setIsOpenStartDate(false)
        setIsOpenEndDate(false)
    }

    return (
        <div onClick={closeCalendars} className={styles.eventsModal}>
            <div className={styles.modalForm}>
                <button
                    onClick={() => setIsOpenModal(false)}
                    className={styles.closeBtn}
                />
                <div className={styles.modalTitle}>
                    <Title value={'Update Event'}/>
                </div>
                <div className={styles.addEventWrapper}>
                    <div className={styles.inputsColumn}>
                        <div className={styles.inputWrapper}>
                            <SelectInput
                                value={broadcaster}
                                onChange={setBroadcaster}
                                label={'Broadcaster'}
                                name={'Broadcaster'}
                                searchable={true}
                                options={formatOptions(initOptions?.broadcasters?.items)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <SelectInput
                                value={decoder}
                                onChange={onChangeDecoder}
                                label={'Decoder'}
                                name={'Decoder'}
                                searchable={true}
                                options={formatOptions(initOptions?.decoders?.items)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <SelectInput
                                value={server}
                                onChange={onChangeServer}
                                label={'Server'}
                                name={'Server'}
                                searchable={true}
                                options={formatOptions(initOptions?.servers?.items)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <CreatableSelectInp
                                value={season}
                                onChange={setSeason}
                                label={'Season'}
                                name={'Season'}
                                searchable={true}
                                options={formatStringOptions(initOptions?.seasons)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <CreatableSelectInp
                                value={round}
                                onChange={setRound}
                                label={'Round'}
                                name={'Round'}
                                searchable={true}
                                options={formatStringOptions(initOptions?.rounds)}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <Input
                                value={group}
                                setValue={setGroup}
                                label={'Group'}
                                readonly={false}
                            />
                        </div>
                    </div>

                    <div className={styles.inputsColumn}>
                        <div className={`${styles.inputWrapper} ${styles.inputDescription}`}>
                            <Input
                                value={description}
                                setValue={setDescription}
                                label={'Description'}
                                readonly={false}
                            />
                        </div>
                        <div className={`${styles.inputWrapper} ${styles.homeAwayWrapper}`}>
                            <div className={styles.inputSmallWrapper}>
                                <Input
                                    value={home}
                                    setValue={setHome}
                                    label={'Home'}
                                    readonly={false}
                                />
                            </div>
                            <div className={styles.inputSmallWrapper}>
                                <Input
                                    value={away}
                                    setValue={setAway}
                                    label={'Away'}
                                    readonly={false}
                                />
                            </div>
                        </div>
                        <div className={styles.inputWrapper}>
                            <TimeCalendar
                                calendarClass={styles.timeCalendarWrapper}
                                currentDate={startDate}
                                setCurrentDate={setStartDate}
                                label={"Start Date & Time"}
                                isOpen={isOpenStartDate}
                                setIsOpen={setIsOpenStartDate}
                            />
                        </div>
                        <div className={styles.inputWrapper}>
                            <TimeCalendar
                                calendarClass={styles.timeCalendarWrapper}
                                currentDate={endDate}
                                setCurrentDate={setEndDate}
                                label={"End Date & Time"}
                                isOpen={isOpenEndDate}
                                setIsOpen={setIsOpenEndDate}
                            />
                        </div>
                        {!isValidEndTime &&
                            <p className={styles.formError}>End Date can’t early than Start Date</p>
                        }
                        <div className={styles.inputWrapper}>
                            <div className={styles.buttonsWrapper}>
                                <div className={styles.radioControls}>
                                    <div onClick={()=>setIsInternal(!isInternal)} className={styles.radioScheduleBtn}>
                                        <ToggleSwitchBtn
                                            checked={isInternal}
                                        />
                                        <div className={styles.radioScheduleLabel}>
                                            <h6>Internal</h6>
                                            <h6>Schedule</h6>
                                        </div>
                                    </div>
                                    <div onClick={()=>setIsVerified(!isVerified)} className={styles.radioVerifyBtn}>
                                        <ToggleSwitchBtn
                                            checked={isVerified}
                                        />
                                        <div className={styles.radioVerifyLabel}>
                                            <h6>Verify</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.btnGroupWrapper}>
                                    <div className={styles.btnWrapper}>
                                        <Button onClick={()=>setIsOpenModal(false)} value={'Cancel'}/>
                                    </div>
                                    <div className={`${styles.btnWrapper} ${styles.btnAdd}`}>
                                        <Button onClick={updateEvent} value={'Update'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(EventUpdateModal);