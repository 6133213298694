export interface IUpdatingBody {
    userId: number,
    roleId: number,
}

export interface IUpdatingUser {
    updatingBody: IUpdatingBody
    roleName: string,
}

export interface IAddingUser {
    email: string,
    roleId: number
}

export interface IUserRole {
    id: string,
    name: string
}

export interface IUsersRoles {
    items: IUserRole[]
}

export interface IUser {
    email: string,
    lastLoginDate: null | string,
    loginLocation: null | string,
    role: string,
    userId: number,
}

export interface IUsersState {
    users: IUser[],
    roles: IUserRole[],
    isLoading: boolean
}

export enum UsersActionEnum {
    GET_USERS = "GET_USERS",
    GET_SEARCH_USERS = "GET_SEARCH_USERS",
    SET_USERS = "SET_USERS",
    SET_USERS_LOADER = "SET_USERS_LOADER",
    GET_USERS_ROLES = "GET_USERS_ROLES",
    SET_USERS_ROLES = "SET_USERS_ROLES",
    ADD_USER = "ADD_USER",
    UPDATE_USER = "UPDATE_USER",
    UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
    DELETE_USER = "DELETE_USER",
    DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
    RESET_PASSWORD = "RESET_PASSWORD",
    CONFIRM_RESET_PASSWORD = "CONFIRM_RESET_PASSWORD"
}


export interface IGetUsers {
    type: UsersActionEnum.GET_USERS
}

export interface IGetSearchUsers {
    type: UsersActionEnum.GET_SEARCH_USERS
    payload: string
}

export interface ISetUsers {
    type: UsersActionEnum.SET_USERS
    payload: IUser[]
}

export interface ISetUsersLoader {
    type: UsersActionEnum.SET_USERS_LOADER,
    payload: boolean
}

export interface IGetUsersRoles {
    type: UsersActionEnum.GET_USERS_ROLES
}

export interface ISetUsersRoles {
    type: UsersActionEnum.SET_USERS_ROLES
    payload: IUsersRoles
}

export interface IAddUser {
    type: UsersActionEnum.ADD_USER
    payload: IAddingUser
}

export interface IUpdateUser {
    type: UsersActionEnum.UPDATE_USER
    payload: IUpdatingUser
}

export interface IUpdateUserSuccess {
    type: UsersActionEnum.UPDATE_USER_SUCCESS
    payload: IUpdatingUser
}

export interface IDeleteUser {
    type: UsersActionEnum.DELETE_USER
    payload: number
}

export interface IDeleteUserSuccess {
    type: UsersActionEnum.DELETE_USER_SUCCESS
    payload: number
}

export interface IResetPassword {
    type: UsersActionEnum.RESET_PASSWORD
    payload: string
}

export interface IConfirmResetPassword {
    type: UsersActionEnum.CONFIRM_RESET_PASSWORD
    payload: string
}


export type UsersAction = ISetUsers| ISetUsersLoader | ISetUsersRoles  | IUpdateUserSuccess | IDeleteUserSuccess