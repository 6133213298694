import React from "react";
import styles from './auditEventTable.module.scss';
import Checkbox from "../../../components/Checkbox";
import {IEventAudit} from "../../../store/reducers/audit/types";
import moment from "moment/moment";


interface IAuditEventTableProps {
    eventAudit: IEventAudit[]
}

const AuditEventTable: React.FC<IAuditEventTableProps> = ({eventAudit}) => {

    const highlightField = (fieldName: string, modifiedFields: string[]) => {
        let isModifiedField = false
        modifiedFields?.forEach((field) => {
            if (field === fieldName) {
                isModifiedField = true
            }
        })
        return isModifiedField ? styles.modifiedField : ""
    }
    return (
        <div className={styles.wrapper}>
            <table className={styles.auditTable}>
                <thead className={styles.tableHeadWrapper}>
                <tr className={styles.tableRow}>
                    <th className={styles.tableHeader}>WTSchedule Event ID</th>
                    <th className={styles.tableHeader}>Fixture ID</th>
                    <th className={styles.tableHeader}>Cobain CH. ID</th>
                    <th className={styles.tableHeader}>Cobain CH. Name</th>
                    <th className={styles.tableHeader}>Decoder ID</th>
                    <th className={styles.tableHeader}>Decoder Name</th>
                    <th className={styles.tableHeader}>Verified</th>
                    <th className={styles.tableHeader}>Cancelled</th>
                    <th className={styles.tableHeader}>Internal Schedule</th>
                    <th className={styles.tableHeader}>Created Timestamp</th>
                    <th className={styles.tableHeader}>Mod. By</th>
                    <th className={styles.tableHeader}>WTUpdate Time</th>
                </tr>
                </thead>
                <tbody className={styles.tableBodyWrapper}>
                {eventAudit?.map((audit, index) => {
                    return (
                        <tr className={styles.tableRow} key={index + 1}>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("Id", audit.modifiedFields)}
                                >
                                    {audit.snapshot.id}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("FixtureId", audit.modifiedFields)}
                                 >
                                    {audit.snapshot.fixtureId}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("ChannelId", audit.modifiedFields)}
                                 >
                                    {audit.snapshot.channelId}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("Channel", audit.modifiedFields)}
                                 >
                                    {audit.channelName?audit.channelName:""}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("DecoderId", audit.modifiedFields)}
                                 >
                                    {audit.decoderId}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("DecoderName", audit.modifiedFields)}
                                 >
                                   {audit.decoderName}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("IsVerified", audit.modifiedFields)}
                                 >
                                   <Checkbox isChecked={audit.snapshot.isVerified}/>
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("IsCanceled", audit.modifiedFields)}
                                 >
                                    <Checkbox isChecked={audit.snapshot.isCanceled}/>
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                 <span
                                     className={highlightField("IsInternal", audit.modifiedFields)}
                                 >
                                   <Checkbox isChecked={audit.snapshot.isInternal}/>
                                </span>
                            </td>
                            <td className={styles.tableDescription}>{moment(audit.snapshot.createDate).format("YYYY-MM-DD:HH:mm")}</td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("ModifierUsername", audit.modifiedFields)}
                                >
                                    {audit.modifierUsername}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>{moment(audit.modifyDate).format("YYYY-MM-DD:HH:mm")}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>

    );
};

export default React.memo(AuditEventTable);
