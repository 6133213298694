import {IFixturesFilter} from "../../../store/reducers/fixtures/types";

export const getQueryString =(filterValues:IFixturesFilter,sortingString:string) => {
    const {dateRange,regionIds,sportIds,competitionIds} = filterValues
    const getStringFromFilterValue = (query:string,values:any[]) => {
        if (values.length > 0) {
            let queryString = ''
            values.forEach(item=>{
                queryString+= `&${query}=${item.value}`
            })
            return queryString
        }
        return ''
    }
    return `StartDate=${dateRange?.from?.year}-${dateRange?.from?.month}-${dateRange?.from?.day}&EndDate=${dateRange?.to?.year}-${dateRange?.to?.month}-${dateRange?.to?.day}${getStringFromFilterValue("SportIds", sportIds)}${getStringFromFilterValue("RegionIds", regionIds)}${getStringFromFilterValue("CompetitionIds", competitionIds)}${sortingString}`
}