import React from "react";
import Select, { SingleValue} from 'react-select';
import './selectInput.scss';


interface ISelectInputProps {
    label?: string
    searchable: boolean
    options: any[]
    name: string
    onChange: (e: SingleValue<any>, name: string) => void
    value: SingleValue<any>
    isRequired?:boolean
}

const TableSelect: React.FC<ISelectInputProps> = ({options, searchable, label, name, value, onChange}) => {
    return (
        <div onClick={(e)=>e.stopPropagation()}>
            <label className={'inputLabel'}>{label}</label>
            <Select
                menuPosition={"fixed"}
                value={value}
                isMulti={false}
                onChange={(e) => onChange(e,name)}
                classNamePrefix={'table-select'}
                defaultValue={[]}
                options={options}
                isSearchable={searchable}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#DEEBFF',
                        primary50: '#DEEBFF',
                        primary: '#495464',
                        neutral0: 'white'
                    },
                })}
            />
        </div>
    )
}

export default React.memo(TableSelect);
