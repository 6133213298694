import {IInputOption} from "../fixtures/types";

export interface IBroadcaster {
    id: number,
    name: string,
    countryId: number | null,
    countryName: null | string,
    definitionName: null | string,
    definition: number,
    sourceUrl: string,
    isLatent: boolean
}
export interface ICreatingBroadcaster {
    name:string
    countryId:string
    isLatent:boolean
    definition:number|null
    sourceUrl:string
}
export interface IUpdatedBroadcaster {
    id:number
    name:string
    countryId:string
    isLatent:boolean
    definition:number|null
    sourceUrl:string
}
export interface IBroadcastersInit {
    regions: {
        items: IInputOption[]
    },
    definitions: {
        items: IInputOption[]
    }

}
export interface IBroadcastersState {
    init:IBroadcastersInit
    broadcasters:IBroadcaster[]
}

export enum BroadcastersActionEnum {
    GET_BROADCASTERS = "GET_BROADCASTERS",
    SET_BROADCASTERS = "SET_BROADCASTERS",
    CRETE_BROADCASTER = "CRETE_BROADCASTER",
    SET_BROADCASTERS_INIT = "SET_BROADCASTERS_INIT",
    UPDATE_BROADCASTER = "UPDATE_BROADCASTER",
    DELETE_BROADCASTER = "DELETE_BROADCASTER",
    DELETE_BROADCASTER_SUCCESS = "DELETE_BROADCASTER_SUCCESS"
}

export interface IGetBroadcasters {
    type: BroadcastersActionEnum.GET_BROADCASTERS
    queryString:string
}

export interface ISetBroadcasters {
    type: BroadcastersActionEnum.SET_BROADCASTERS
    payload: any[]
}

export interface ICreateBroadcaster {
    type: BroadcastersActionEnum.CRETE_BROADCASTER
    payload :ICreatingBroadcaster
}
export interface ISetBroadcasterInit {
    type: BroadcastersActionEnum.SET_BROADCASTERS_INIT
    payload :IBroadcastersInit
}

export interface IUpdateBroadcaster {
    type: BroadcastersActionEnum.UPDATE_BROADCASTER
    payload :IUpdatedBroadcaster
}

export interface IDeleteBroadcaster {
    type: BroadcastersActionEnum.DELETE_BROADCASTER
    payload :number
}
export interface IDeleteBroadcasterSuccess {
    type: BroadcastersActionEnum.DELETE_BROADCASTER_SUCCESS
    payload :number
}

export interface ISetBroadcasterInit {
    type: BroadcastersActionEnum.SET_BROADCASTERS_INIT
    payload :IBroadcastersInit
}


export type BroadcasterAction = ISetBroadcasters | ISetBroadcasterInit | IDeleteBroadcasterSuccess