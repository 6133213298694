import React, {useEffect, useState} from "react";
import Title from "../../components/Title";
import Button from "../../components/Button";
import ScheduleTable from "./ScheduleTable";
import {MultiValue, SingleValue} from "react-select";
import MultiSelectInput from "../../components/MultiSelect";
import SelectInput from "../../components/SelectInput";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import SendEmail from "./SendEmail/SendEmail";
import RangeCalendar from "../../components/RangeCalendar/RangeCalendar";
import {Range} from "react-calendar-datetime-picker";
import {ISchedule} from "../../store/reducers/schedule/types";
import EditEventModal from "./EditEventModal/EditEventModal";
import Input from "../../components/Input";
import {useScheduleSearch} from "./ScheduleHooks/useScheduleSearch";
import {formatOptions} from "../../helpers/formatSelectOptions";
import {getDefaultDateRange} from "./ScheduleHooks/getDefaultDateRange";
import {useScheduleSorting} from "./ScheduleHooks/useScheduleSorting";
import {useGetSchedules} from "./ScheduleHooks/useGetSchedules";
import {useGetTodayTomorrowEvents} from "./ScheduleHooks/useGetTodayTomorrowEvents";
import styles from './style.module.scss';
import {useAccordionsState} from "./ScheduleHooks/useCreateAccordeonsState";
import {useDispatch} from "react-redux";
import {getQueryString} from "./ScheduleHooks/getQueryString";
import {ScheduleActionCreators} from "../../store/reducers/schedule/action-creators";
import {client} from "../../services/api-servise";
import {formatRangeDate} from "./ScheduleHooks/formatRangeDate";
import {useTableFilters} from "./ScheduleHooks/useTableFilters";


export type ScheduleSortingField = "CompetitionName" | "ChannelName" | "DecoderName" | "Region" | "Sport" | "StartTime";

export interface ICellInfo {
    eventId: number,
    compId: string,
    fixtureId: string,
    event: ISchedule | null
}

const Schedule: React.FC = () => {
    const [range, setRange] = useState<Range>(getDefaultDateRange());
    const [sport, setSport] = useState<MultiValue<any> | SingleValue<any>>([])
    const [groupBy, setGroupBy] = useState<SingleValue<{ value: number, label: string }>>({value: 1, label: "Date"})
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const [isOpenMailForm, setIsOpenMailForm] = useState(false)
    const [isOpenEditForm, setIsOpenEditForm] = useState(false)
    const [editableEvent, setEditableEvent] = useState(0);
    const [editCellInfo, setEditCellInfo] = useState<ICellInfo>({
        eventId: 0,
        compId: "",
        fixtureId: "",
        event: null
    })
    const [editCellInfoGroup, setEditCellInfoGroup] = useState<ICellInfo[] | null>(null);
    const {groupByChoices, sports} = useTypedSelector(state => state.scheduleReducer)
    const {sortingFieldsProps, currentSortingField, sortingString, createSortingString} = useScheduleSorting()
    const {groupedSchedules} = useGetSchedules(range, sport, groupBy, sortingString)
    const {searchValue, searchResult, onSearch} = useScheduleSearch(groupedSchedules)
    const {getTomorrowEvents, getTodayEvents} = useGetTodayTomorrowEvents(setRange)
    const {toggleAccordion, checkIsExpanded, collapseAll, expandAll} = useAccordionsState(groupedSchedules);
    const {filtersState, handleTableFilters} = useTableFilters(range, sport, groupBy, sortingString);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(ScheduleActionCreators.getFiltersOptions())
    }, [])

    const setFilterDateRange = (dateRange: Range) => {
        setRange(dateRange)
    }

    const openEditModal = () => {
        setIsOpenEditForm(true)
        setIsOpenMenu(false)
    }

    const closeRightClickMenu = () => {
        setIsOpenMenu(false)
        setEditCellInfo({
            eventId: 0,
            compId: "",
            fixtureId: "",
            event: null
        })
        setEditCellInfoGroup(null)
    }

    const refreshPage = () => {
        setEditCellInfo({
            eventId: 0,
            compId: "",
            fixtureId: "",
            event: {} as ISchedule | null
        })
        setEditCellInfoGroup(null);
        setEditableEvent(0)

        if (getQueryString(range, sport, groupBy, sortingString).includes('undefined')) {
            return
        } else {
            dispatch(ScheduleActionCreators.getSchedules(getQueryString(range, sport, groupBy, sortingString)))
        }
    }

    const downloadCSV = async () => {
        const sportsIds = sport.map((sportItem: any) => sportItem.value);

        if (range && groupBy) {
            const payload = {
                keyword: searchValue,
                groupById: groupBy.value,
                sportIds: sportsIds,
                broadcasterIds: null,
                decoderIds: null,
                serverIds: null,
                competitionIds: null,
                countryIds: null,
                startTime: formatRangeDate(range.from),
                endTime: formatRangeDate(range.to),
                pageIndex: 0,
                pageSize: 0,
                sorting: {
                    property: null,
                    isAscending: true
                }
            }
            const resp = await client.post(`/api/Schedule/SchedulesCsv`, payload)
            const blob = new Blob([resp.data], {type: 'text/csv'});
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'schedule.csv');
            document.body.appendChild(link);
            link.click();
        }
    }

    return (
        <div onClick={() => closeRightClickMenu()} className={'container'}>
            {isOpenEditForm &&
                <EditEventModal
                    setEditCellInfo={setEditCellInfo}
                    setIsOpenModal={setIsOpenEditForm}
                    event={editCellInfo.event}
                    editEventId={editCellInfo.eventId}
                />
            }
            <div className={'mt64'}></div>
            <div className="flexAlignEnd mt15">
                <div className={styles.calendar}>
                    <RangeCalendar
                        defaultRange={range}
                        setRange={setFilterDateRange}
                    />
                </div>

                <div className="regularBtn mr20">
                    <Button onClick={getTodayEvents} value={'Today'}/>
                </div>

                <div className="regularBtn mr20">
                    <Button onClick={getTomorrowEvents} value={'Tomorrow'}/>
                </div>

                <div className="regularInput-Select">
                    <div className="regularInput-Select mr20">
                        <MultiSelectInput
                            name={"Sport"}
                            handlerOnChange={setSport}
                            searchable={true}
                            options={formatOptions(sports.items)}
                            label={'Sport'}/>
                    </div>
                </div>
                <div className="regularInput-Select">
                    <div className="regularInput-Select mr20">
                        <Input
                            placeholder={"Search..."}
                            label={"Search"}
                            value={searchValue}
                            onChange={(e) => onSearch(e.target.value)}
                            readonly={false}
                        />
                    </div>
                </div>
                <button
                    className={`${styles.smallBtn} ${styles.svgBtn}`}
                    onClick={downloadCSV}/>
                <button
                    className={`${styles.smallBtn} ${styles.refreshBtn}`}
                    onClick={refreshPage}/>
            </div>
            <div className="schedule mt60">
                <Title value={'Schedule'}/>
                <div className="flexAlignEnd mt15">
                    <div className="regularInput-Select">
                        <SelectInput
                            value={groupBy}
                            name={"groupBy"}
                            onChange={setGroupBy}
                            searchable={true}
                            options={formatOptions(groupByChoices.items)}
                            label={'Group by'}/>
                    </div>

                    <div className="regularBtn">
                        <Button
                            onClick={collapseAll}
                            value={'Collapse All Groups'}
                        />
                    </div>
                    <div className="regularBtn">
                        <Button
                            onClick={expandAll}
                            value={'Expand All Groups'}
                        />
                    </div>
                </div>
            </div>
            <div onClick={() => setIsOpenMenu(false)}>
                <div className={styles.scheduleTableWrapper}>
                    <ScheduleTable
                        checkIsExpanded={checkIsExpanded}
                        toggleAccordion={toggleAccordion}
                        isOpenMailForm={isOpenMailForm}
                        isOpenMenu={isOpenMenu}
                        setIsOpenMailForm={setIsOpenMailForm}
                        openEditModal={openEditModal}
                        createSortingString={createSortingString}
                        currentSortingField={currentSortingField}
                        sortingFieldsProps={sortingFieldsProps}
                        editCellInfo={editCellInfo}
                        setEditCellInfo={setEditCellInfo}
                        setIsOpenMenu={setIsOpenMenu}
                        items={searchValue ? searchResult : groupedSchedules}
                        filtersState={filtersState}
                        handleTableFilters={handleTableFilters}
                        editCellInfoGroup={editCellInfoGroup}
                        setEditCellInfoGroup={setEditCellInfoGroup}
                        editableEvent={editableEvent}
                        setEditableEvent={setEditableEvent}

                    />
                </div>
            </div>
            {isOpenMailForm &&
                <SendEmail
                    setIsOpenMenu={setIsOpenMenu}
                    eventId={editCellInfo.eventId}
                    setIsOpenMailForm={setIsOpenMailForm}
                />}
        </div>
    );
};

export default React.memo(Schedule);
