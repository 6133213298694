import React from 'react';
import styles from './style.module.scss';

interface ICheckboxProps {
    isChecked?: boolean
}

const Checkbox: React.FC<ICheckboxProps> = ({isChecked}) => {

    return (
        <label>
            <input
                checked={isChecked}
                type="checkbox"
                className={styles.realCheckbox}
                readOnly={true}
            />
            <span className={styles.customCheckbox}/>
        </label>
    );
}


export default React.memo(Checkbox);
