import {IBroadcaster} from "../../../../store/reducers/broadcasters/types";
import React, {useEffect, useState} from "react";

export const useBroadcasterSearch =(broadcasters:IBroadcaster[])=> {
    const [searchResult, setSearchResult] = useState<IBroadcaster[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const findBroadcasterByName = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
        const filteredBroadcaster = broadcasters.filter(broadcaster => broadcaster.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setSearchResult(filteredBroadcaster)
    }
    useEffect(() => {
        if(searchValue&&broadcasters){
            const filteredBroadcaster = broadcasters.filter(broadcaster => broadcaster.name.toLowerCase().includes(searchValue.toLowerCase()))
            setSearchResult(filteredBroadcaster)
        }
    }, [broadcasters]);

    return {searchResult,findBroadcasterByName,searchValue}
}