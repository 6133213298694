import styles from "../scheduleTable.module.scss";
import React, {Dispatch, RefObject, SetStateAction, useCallback, useEffect, useState} from "react";
import {ISchedule, IUpdateEvents} from "../../../../store/reducers/schedule/types";
import {useDispatch} from "react-redux";
import {ScheduleActionCreators} from "../../../../store/reducers/schedule/action-creators";
import moment from "moment/moment";
import {IInputOption} from "../../../../store/reducers/fixtures/types";
import {SingleValue} from "react-select";
import TableSelect from "../../../../components/TableSelect";
import {ICellInfo} from "../../Schedule";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";

interface IScheduleTableAccordionProps {
    id: string
    events: ISchedule[]
    date: string
    setIsOpenMenu: (flag: boolean) => void
    editCellInfo: ICellInfo
    setEditCellInfo: Dispatch<React.SetStateAction<{
        eventId: number,
        compId: string,
        fixtureId: string,
        event: ISchedule | null
    }>>
    contextMenuRef: RefObject<HTMLDivElement>
    editableEvent: number
    setEditableEvent: Dispatch<SetStateAction<number>>
    toggleAccordion: (id: string) => void
    checkIsExpanded: (id: string) => boolean
    editCellInfoGroup: ICellInfo[] | null
    setEditCellInfoGroup: Dispatch<React.SetStateAction<ICellInfo[] | null>>
    isCommandTab: boolean
}

const ScheduleTableAccordion: React.FC<IScheduleTableAccordionProps> = ({
                                                                            events,
                                                                            date,
                                                                            setIsOpenMenu,
                                                                            editCellInfo,
                                                                            setEditCellInfo,
                                                                            contextMenuRef,
                                                                            editableEvent,
                                                                            setEditableEvent,
                                                                            toggleAccordion,
                                                                            checkIsExpanded,
                                                                            id,
                                                                            editCellInfoGroup,
                                                                            setEditCellInfoGroup,
                                                                            isCommandTab
                                                                        }) => {
    const dispatch = useDispatch()
    const [decoder, setDecoder] = useState<SingleValue<any>>([])
    const [server, setServer] = useState<SingleValue<any>>([])
    const [broadcaster, setBroadcaster] = useState<SingleValue<any>>([])
    const [isReadySelect, setIsReadySelect] = useState(false);
    const {broadcasters, servers, decoders} = useTypedSelector(state => state.scheduleReducer);

    const clearSelectLists = () => {
        setDecoder([])
        setServer({})
        setBroadcaster([])
    }

    const setDefaultFormValue = (event: ISchedule) => {
        if (event) {
            const decoder = decoders.find(decoder => decoder.id === event.decoderId?.toString())
            const server = servers.find(server => server.id === event.channelId?.toString())
            const broadcaster = broadcasters.find(broadcaster => broadcaster.id === event.broadcasterId?.toString())
            if (server) {
                setServer({value: server?.id, label: server?.name})
            }
            if (decoder) {
                setDecoder({value: decoder?.id, label: decoder?.name})
            }
            if (broadcaster) {
                setBroadcaster({value: broadcaster?.id, label: broadcaster?.name})
            }
            if (server || decoder || broadcaster) {
                setIsReadySelect(true)
            } else {
                setIsReadySelect(true)
            }

        }
    }

    useEffect(() => {
        document.addEventListener('contextmenu', handleContextMenu);
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        }
    }, [editCellInfo, events, editCellInfoGroup])

    const handleContextMenu = (event: any) => {
        if (!editCellInfoGroup) {
            event.preventDefault();
            const currentEventId = event.target.className.split(" ")[0]
            const currentEvent = events.find(event => event.eventId === Number(currentEventId))
            if (currentEvent) {
                setIsReadySelect(false)
                setEditableEvent(0)
                setEditCellInfo({
                    eventId: currentEvent.eventId,
                    compId: currentEvent.competitionId,
                    fixtureId: currentEvent.fixtureId,
                    event: currentEvent
                })
                setIsOpenMenu(true)
                const clickX = event.clientX;
                const screenW = window.innerWidth;
                const rootW = 235;
                const right = (screenW - clickX) > rootW;
                const left = !right;
                // @ts-ignore
                contextMenuRef!.current.style.left = 0
                // @ts-ignore
                contextMenuRef!.current.style.top = `400px`
                if (right) {
                    // @ts-ignore
                    contextMenuRef.current.style.left = `${clickX + 5}px`;
                }
                if (left) {
                    // @ts-ignore
                    contextMenuRef.current.style.left = `${clickX - rootW - 50}px`;
                }
            }
        }
    }

    const getRowClass = (eventId: number, editCellId: number, schedule: ISchedule) => {
        if (editCellInfoGroup) {
            const editCellInfo = editCellInfoGroup.find(cellInfo => cellInfo.eventId === eventId)
            if (editCellInfo) return `${styles.tableRow} ${styles.blue}`
        }
        if (eventId === editCellId) {
            return `${styles.tableRow} ${styles.blue}`
        }
        if (schedule.isCanceled) {
            return `${styles.tableRow} ${styles.red}`
        }
        if (schedule.isInternal) {
            return `${styles.tableRow} ${styles.lightBlue}`
        }
        if (!schedule.verified) {
            return `${styles.tableRow} ${styles.yellow}`
        }
    }

    const setNewEventEndDate = (isIncrease: boolean) => {
        if (editCellInfo.eventId) {
            dispatch(ScheduleActionCreators.setEventEndDate({i: editCellInfo.eventId, b: isIncrease}))
        }
    }
    const formatTime = (time: string | undefined) => {
        if (time) {
            return moment(time).utc(true).format("YYYY-MM-DD: HH:mm")
        } else {
            return
        }
    }
    const formatOptions = useCallback((options: IInputOption[]) => {
        if (options) {
            const allOptions = options?.map(opt => ({value: opt?.id, label: opt?.name}))
            allOptions.unshift({value: "0", label: "Blank"})
            return allOptions
        } else {
            return []
        }
    }, [])

    const startEdit = (e: React.MouseEvent, eventId: number, competitionId: string, event: ISchedule) => {
        setEditCellInfo({
            eventId: event.eventId,
            compId: event.competitionId,
            fixtureId: event.fixtureId,
            event
        })
        e.stopPropagation()
        clearSelectLists()
        setEditableEvent(eventId)
        setDefaultFormValue(event)
    }

    const onChangeBroadcaster = (e: SingleValue<any>) => {
        setBroadcaster(e)
        if (editCellInfoGroup) {
            const updatedEvents: IUpdateEvents = {
                eventIds: editCellInfoGroup.map(event => event.eventId),
                broadcasterId: Number(e.value)
            };
            dispatch(ScheduleActionCreators.updateSeveralEvent(updatedEvents))
        } else {
            const updatedEvent = events.find(event => event.eventId === editableEvent)
            if (updatedEvent) {
                updatedEvent.broadcasterId = Number(e.value)
                updateEvent(updatedEvent)
            }
        }
        setIsReadySelect(false);
    }

    const onChangeDecoder = (e: SingleValue<any>) => {
        setDecoder(e)
        if (editCellInfoGroup) {
            const updatedEvents: IUpdateEvents = {
                eventIds: editCellInfoGroup.map(event => event.eventId),
                decoderId: Number(e.value)
            };
            dispatch(ScheduleActionCreators.updateSeveralEvent(updatedEvents))
        } else {
            const updatedEvent = events.find(event => event.eventId === editableEvent)
            if (updatedEvent) {
                updatedEvent.decoderId = Number(e.value)
                updateEvent(updatedEvent)
            }
        }
        setIsReadySelect(false);
    }

    const onChangeServer = (e: SingleValue<any>) => {
        setServer(e)
        if (editCellInfoGroup) {
            const updatedEvents: IUpdateEvents = {
                eventIds: editCellInfoGroup.map(event => event.eventId),
                channelId: Number(e.value)
            };
            dispatch(ScheduleActionCreators.updateSeveralEvent(updatedEvents))
        } else {
            const updatedEvent = events.find(event => event.eventId === editableEvent)
            if (updatedEvent) {
                updatedEvent.channelId = Number(e.value)
                updateEvent(updatedEvent)
            }
        }
        setIsReadySelect(false);
    }

    const updateEvent = (event: ISchedule) => {
        const updatedEvent = {
            competitionId: event!.competitionId,
            eventId: event.eventId,
            home: event.home,
            away: event.away,
            season: event.season,
            round: event.round,
            group: event.group,
            description: event.description,
            finishDate: event.endTime,
            endDate: event.endTime,
            startDate: event.startTime,
            isVerified: event.verified,
            isInternal: event.isInternal,
            broadcasterId: Number(event.broadcasterId) === 0 ? null : event.broadcasterId,
            decoderId: Number(event.decoderId) === 0 ? null : event.decoderId,
            channelId: Number(event.channelId) === 0 ? null : event.channelId,
        }
        dispatch(ScheduleActionCreators.editScheduleEvent(updatedEvent))
    }

    const onRowClick = (event: ISchedule, e: React.MouseEvent) => {
        e.stopPropagation();
        const editCellInfo = {
            eventId: event.eventId,
            compId: event.competitionId,
            fixtureId: event.fixtureId,
            event
        }

        if (isCommandTab && editCellInfoGroup) {
            const currentEvent = editCellInfoGroup.find(existingEvent => existingEvent.eventId === event.eventId);

            if (currentEvent) {
                const updatedData = editCellInfoGroup.filter(event => event.eventId !== currentEvent.eventId)
                setEditCellInfoGroup(updatedData)
            } else {
                setEditCellInfoGroup([...editCellInfoGroup, editCellInfo])
            }

        } else if (isCommandTab) {
            setEditCellInfoGroup([editCellInfo])
        } else {
            setEditCellInfo(editCellInfo)
            setEditableEvent(0);
        }
    }

    const outsideClickTableClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        setEditCellInfo({
            eventId: 0,
            compId: "",
            fixtureId: "",
            event: {} as ISchedule | null
        })
        setEditCellInfoGroup(null);
        setEditableEvent(0);
    }

    return (
        <>
            <tbody onClick={(e) => outsideClickTableClick(e)} className={styles.tableDateItemsRow}>
            <tr className={styles.tableAccordion} onClick={() => toggleAccordion(id)}>
                <td
                    className={checkIsExpanded(id)
                        ?
                        `${styles.tableDateActive}`
                        :
                        `${styles.tableDate}`}>
                    {date}
                </td>
                <td className={styles.tableItems}>Total items {events.length}</td>
            </tr>
            </tbody>
            {checkIsExpanded(id) && <tbody className={styles.tableBody}>
            {events.map(event => {
                return (
                    <tr
                        onClick={(e) => onRowClick(event, e)}
                        className={getRowClass(event.eventId, editCellInfo.eventId, event)}
                        key={event.eventId}
                        id={`${event.eventId}`}
                    >
                        <td className={`${event.eventId} ${styles.tableDescription}`}>{formatTime(event.startTime)}</td>
                        <td className={`${event.eventId} ${styles.tableDescription}`}>
                            <div className={`${event.eventId} ${styles.endTimeCell}`}>
                                <div className={`${event.eventId}`}>{formatTime(event.endTime)}</div>
                                <div>
                                    <button
                                        onClick={() => setNewEventEndDate(false)}
                                        className={styles.timeBtn}>
                                        -
                                    </button>
                                    <button
                                        onClick={() => setNewEventEndDate(true)}
                                        className={styles.timeBtn}>
                                        +
                                    </button>
                                </div>
                            </div>
                        </td>
                        {editableEvent === event.eventId && isReadySelect ?
                            <td className={`${event.eventId} ${styles.tableDescription}`}>
                                <div className={styles.inputWrapper}>
                                    <TableSelect
                                        value={broadcaster}
                                        onChange={onChangeBroadcaster}
                                        name={'Broadcaster'}
                                        searchable={true}
                                        options={formatOptions(broadcasters)}
                                    />
                                </div>
                            </td>
                            :
                            <td
                                onClick={(e) => startEdit(e, event.eventId, event.competitionId, event)}
                                className={`${event.eventId} ${styles.tableDescription} ${styles.editableCell}`}>
                                {event.broadcasterName ? event.broadcasterName : "-------"}
                            </td>
                        }

                        {editableEvent === event.eventId && isReadySelect ?
                            <td className={`${event.eventId} ${styles.tableDescription}`}>
                                <div className={styles.inputWrapper}>
                                    <TableSelect
                                        value={server}
                                        onChange={onChangeServer}
                                        name={'Server'}
                                        searchable={true}
                                        options={formatOptions(servers)}
                                    />
                                </div>
                            </td>
                            :
                            <td
                                onClick={(e) => startEdit(e, event.eventId, event.competitionId, event)}
                                className={`${event.eventId} ${styles.tableDescription} ${styles.editableCell}`}>
                                {event.channelName ? event.channelName : "-------"}
                            </td>
                        }

                        {editableEvent === event.eventId && isReadySelect ?
                            <td className={`${event.eventId} ${styles.tableDescription}`}>
                                <div className={styles.inputWrapper}>
                                    <TableSelect
                                        value={decoder}
                                        onChange={onChangeDecoder}
                                        name={'Decoder'}
                                        searchable={true}
                                        options={formatOptions(decoders)}
                                    />
                                </div>
                            </td>
                            :
                            <td
                                onClick={(e) => startEdit(e, event.eventId, event.competitionId, event)}
                                className={`${event.eventId} ${styles.tableDescription} ${styles.editableCell}`}>
                                {event.decoderName ? event.decoderName : "-------"}
                            </td>
                        }

                        <td className={`${event.eventId} ${styles.tableDescription}`}>{event.sport}</td>
                        <td className={`${event.eventId} ${styles.tableDescription}`}>{event.competitionName}</td>
                        <td className={`${event.eventId} ${styles.tableDescription}`}>{event.region}</td>
                        <td className={`${event.eventId} ${styles.tableDescription}`}>{event.home}</td>
                        <td className={`${event.eventId} ${styles.tableDescription}`}>{event.away}</td>
                        <td className={`${event.eventId} ${styles.tableDescription}`}>{event.info}</td>
                    </tr>
                )
            })}
            </tbody>
            }
        </>
    )
}

export default React.memo(ScheduleTableAccordion);
