import styles from './index.module.scss'
import React from "react";

interface ITitleProps {
    value : string
}

const Title: React.FC <ITitleProps> = ({value}) => {

    return (
        <div className={styles.title}>
            <h3 className={styles.titleText}>
                {value}
            </h3>
        </div>
    );
};

export default React.memo(Title);
