import React from "react";
import CreatableSelect from 'react-select/creatable';
import './selectInput.scss';
import {SingleValue} from "react-select";


interface ISelectInputProps {
    label: string
    searchable: boolean
    options: any[]
    name: string
    onChange: (e: SingleValue<any>, name: string) => void
    value: SingleValue<any>
    isRequired?:boolean
}

const CreatableSelectInp: React.FC<ISelectInputProps> = ({options, searchable, label, name, value, onChange}) => {
    return (
        <div>
            <label className={'inputLabel'}>{label}</label>
            <CreatableSelect
                value={value}
                onChange={(e) => onChange(e,name)}
                classNamePrefix={'select'}
                defaultValue={[]}
                options={options}
                isSearchable={searchable}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: '#DEEBFF',
                        primary50: '#DEEBFF',
                        primary: '#495464',
                        neutral0: 'white'
                    },
                })}
            />
        </div>
    )
}

export default React.memo(CreatableSelectInp);
