import {call, takeEvery, put,} from 'redux-saga/effects';
import {
    AuditActionEnum, ICompetitionAudit,
    IEventAudit, IFixtureAudit,
    IGetAuditFixture,
    IGetCompetitionAudit,
    IGetEventAudit
} from "./types";
import {getCompetitionAuditRequest, getEventAuditRequest, getFixtureAuditRequest} from "./requests";
import {AuditActionCreators} from "./action-creators";
import {AxiosError} from "axios";
import {toastr} from "react-redux-toastr";

function* tryGetFixtureAudit({fixtureId}:IGetAuditFixture) {
    try {
        const fixtureAudit: IFixtureAudit[] = yield call(getFixtureAuditRequest,fixtureId)
        yield put(AuditActionCreators.setFixtureAudit(fixtureAudit))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryGetCompetitionAudit({competitionId}:IGetCompetitionAudit) {
    try {
        const competitionAudit: ICompetitionAudit[] = yield call(getCompetitionAuditRequest,competitionId)
        yield put(AuditActionCreators.setCompetitionAudit(competitionAudit))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryGetEventAudit({eventId}:IGetEventAudit) {
    try {
        const eventAudit: IEventAudit[] = yield call(getEventAuditRequest,eventId)
        yield put(AuditActionCreators.setEventAudit(eventAudit))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}


export default function* auditSaga(): any {
    yield takeEvery(AuditActionEnum.GET_AUDIT_FIXTURE, tryGetFixtureAudit);
    yield takeEvery(AuditActionEnum.GET_COMPETITION_AUDIT, tryGetCompetitionAudit);
    yield takeEvery(AuditActionEnum.GET_EVENT_AUDIT, tryGetEventAudit);
}