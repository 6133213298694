import {SortingField} from "../Fixtures";
import {useState} from "react";
export const useSortFixtures = () => {
    const [currentSortingField, setCurrentSortingField] = useState("")
    const [sortingString, setSortingString] = useState("")
    const [sortingFieldsProps, setSortingFieldsProps] = useState(
        {CompetitionName:true,SportName:true,Status:true,ModeBy:true,Description:true})
    const createSortingString = (sortingField:SortingField) => {

        setCurrentSortingField(sortingField)
        const isAscending = sortingFieldsProps[sortingField]
        if (currentSortingField === sortingField){
            const sortingString = `&Sorting.Property=${sortingField}&Sorting.IsAscending=${!isAscending}`
            setSortingFieldsProps({...sortingFieldsProps,[sortingField]:!sortingFieldsProps[sortingField]})
            return setSortingString(sortingString)
        }
        const sortString = `&Sorting.Property=${sortingField}&Sorting.IsAscending=${isAscending}`
        setSortingString(sortString)
    }
    return {currentSortingField,sortingString,sortingFieldsProps,createSortingString}
}
