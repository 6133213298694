import './management.scss';
import React from 'react';
import {Route, Routes} from "react-router-dom";
import {RouterNames} from "../../route";
import Decoders from "./Decoders/Decoders";
import Broadcasters from "./Broadcasters/Broadcasters";
import CompetitionSearch from "./CompetitionSearch/CompetitionSearch";
import Events from "./Events/Events";
import SportTimings from "./SportTimings/SportTimings";
import Channel from "./Channel/Channel";

const Management: React.FC = () => {

    return (
        <div className={'container'}>
            <Routes>
                <Route
                    path={RouterNames.DECODERS}
                    element={<Decoders/>}
                />
                <Route
                    path={RouterNames.BROADCASTERS}
                    element={<Broadcasters/>}
                />
                <Route
                    path={RouterNames.EVENTS}
                    element={<Events/>}
                />
                <Route
                    path={RouterNames.COMPETITIONS}
                    element={<CompetitionSearch/>}
                />
                <Route
                    path={RouterNames.SPORT_TIMINGS}
                    element={<SportTimings/>}
                />
                <Route
                    path={RouterNames.SERVERS}
                    element={<Channel/>}
                />
            </Routes>
        </div>
    );
};

export default Management
