import {client} from "../../../services/api-servise";
import {IEmailBody, IUpdateEvents} from "./types";


export const getScheduleRequest = async (queryString: string): Promise<any> => {
    const fixturesResponse = await client.get(`/api/Schedule?${queryString}`)
    return fixturesResponse.data
}

export const setVerifyRequest = async (eventInfo: { i: number, b: boolean }) => {
    const resp = await client.put(`/api/Schedule/Verification`, eventInfo)
    return resp.data
}

export const setProtectionRequest = async (eventInfo: { i: number, b: boolean }) => {
    const resp = await client.put(`/api/Schedule/Protection`, eventInfo)
    return resp.data
}

export const setInternalRequest = async (eventInfo: { i: number, b: boolean }) => {
    const resp = await client.put(`/api/Schedule/Internal`, eventInfo)
    return resp.data
}

export const cancelEventRequest = async (eventInfo: { i: number, b: boolean }) => {
    const resp = await client.put(`/api/Schedule/Cancel`, eventInfo)
    return resp.data
}

export const expireEventRequest = async (eventId: number) => {
    const resp = await client.put(`/api/Schedule/Expire`, eventId)
    return resp.data
}

export const removeEventRequest = async (eventId: number) => {
    const resp = await client.delete(`/api/Schedule/Remove?eventId=${eventId}`)
    return resp.data
}


export const duplicateEventRequest = async (eventId: number) => {
    const resp = await client.post(`/api/Schedule/Duplicate`, eventId)
    return resp.data
}

export const setEventEndDateRequest = async (eventInfo: { i: number, b: boolean }) => {
    const resp = await client.put(`/api/Schedule/EndDate`, eventInfo)
    return resp.data
}


export const getEmailTemplateRequest = async () => {
    const resp = await client.get(`/api/Schedule/EmailTemplateInit`)
    return resp.data
}


export const sendEmailRequest = async (body: IEmailBody) => {
    const resp = await client.post(`/api/Schedule/SendEmail`, body)
    return resp.data
}

export const getFiltersOptions = async () => {
    const resp = await client.get(`/api/Schedule/FilterOptions`)
    return resp.data
}

export const updateSeveralEvents = async (body: IUpdateEvents) => {
    const resp = await client.post(`api/Schedule/Update`, body)
    return resp.data
}
