import {combineReducers, applyMiddleware, compose} from 'redux';
import {legacy_createStore as createStore} from 'redux'
import createSagaMiddleware from 'redux-saga';
import {authReducer} from "./reducers/auth/reducer";
import {rootSaga} from "../root-saga";
import {fixturesReducer} from "./reducers/fixtures/reducer";
import {scheduleReducer} from "./reducers/schedule/reducer";
import {broadcastersReducer} from "./reducers/broadcasters/reducer";
import {decodersReducer} from "./reducers/decoders/reducer";
import {competitionsReducer} from "./reducers/competitions/reducer";
import {eventsReducer} from "./reducers/event/reducer";
import {auditReducer} from "./reducers/audit/reducer";
import {usersReducer} from "./reducers/userManagement/reducer";
import {reducer as toastrReducer} from 'react-redux-toastr'
import {sportTimingsReducer} from "./reducers/sportTimings/reducer";
import {channelsReducer} from "./reducers/channels/reducer";

declare global {
    interface Window { // eslint-disable-line
        // eslint-disable-next-line no-undef
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    // eslint-disable-next-line no-mixed-operators
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({trace: true, traceLimit: 25} as any) || compose;

const reducers = combineReducers({
    authReducer,
    fixturesReducer,
    scheduleReducer,
    broadcastersReducer,
    decodersReducer,
    competitionsReducer,
    eventsReducer,
    auditReducer,
    usersReducer,
    sportTimingsReducer,
    channelsReducer,
    toastr: toastrReducer

})

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
export const store = createStore(reducers, composeEnhancers(applyMiddleware(...middlewares)))
export type RootStore = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

sagaMiddleware.run(rootSaga);
