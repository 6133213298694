import Title from "../../../components/Title";
import React, {useEffect, useState} from "react";
import Button from "../../../components/Button";
import EventsTable from "./EventsTable/EventsTable";
import SelectInput from "../../../components/SelectInput";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {SingleValue} from "react-select";
import {EventsActionCreator} from "../../../store/reducers/event/action-creators";
import EventsFixtureTable from "./EventsFixtureTable/EventsFixtureTable";
import EventsManualModal from "./EventManualModal/EventsManualModal";
import WarningModal from "../../../components/WarningModal/WarningModal";
import EventUpdateModal from "./EventUpdateModal/EventUpdateModal";
import {replaceCountFixtures} from "./helpers/replaceCountFixtures";
import styles from './style.module.scss'
import {formatOptions} from "../../../helpers/formatSelectOptions";


const Events: React.FC = () => {
    const dispatch = useDispatch()
    const {init, events, eventsFixtures} = useTypedSelector(state => state.eventsReducer)
    const [sportId, setSportId] = useState<SingleValue<any>>('')
    const [regionId, setRegionId] = useState<SingleValue<any>>('')
    const [competitionId, setCompetitionId] = useState<SingleValue<any>>('')
    const [isOpenManualForm, setIsOpenManualForm] = useState(false)
    const [isOpenUpdateForm, setIsOpenUpdateForm] = useState(false)
    const [eventsPageNumber, setEventsPageNumber] = useState(1)
    const [fixturePageNumber] = useState(1)
    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const [editInfo, setEditInfo] = useState({isEdit: false, eventId: 0})

    useEffect(() => {
        const filterValues = {
            sportId: sportId?.value,
            regionId: regionId?.value,
            compId: competitionId?.value
        }
        dispatch(EventsActionCreator.getEvents(getQueryString(filterValues)))
    }, [sportId, regionId, eventsPageNumber, dispatch])


    const clearFilter = () => {
        setSportId("")
        setRegionId("")
        setCompetitionId("")
    }

    const getQueryString = (filter: { sportId: string, regionId: string, compId: string }) => {
        const {compId, regionId, sportId} = filter
        const getStringFromFilterValue = (query: string, value: any) => {
            if (value) {

                return `${query}=${value}&`
            }
            return ""
        }
        if (compId && regionId && sportId) {
            return `EventsPageNumber=${eventsPageNumber}&EventsPageSize=5&FixturesPageNumber=${fixturePageNumber}&FixturesPageSize=5&${getStringFromFilterValue("SportIds", sportId)}${getStringFromFilterValue("RegionIds", regionId)}${getStringFromFilterValue("CompetitionIds", compId)}`
        } else {
            return `${getStringFromFilterValue("SportIds", sportId)}${getStringFromFilterValue("RegionIds", regionId)}${getStringFromFilterValue("CompetitionIds", compId)}`
        }

    }


    const deleteEvent = () => {
        const filter = {
            sportId: sportId?.value,
            regionId: regionId?.value,
            compId: competitionId?.value
        }
        const payload = {
            eventId: editInfo.eventId,
            queryString: getQueryString(filter)
        }
        dispatch(EventsActionCreator.deleteEvent(payload))
        setCompetitionId({
            ...competitionId, label: replaceCountFixtures(competitionId.label, eventsFixtures.length + 1
            )
        })
    }
    const openUpdateModal = () => {
        setIsOpenUpdateForm(true)
    }

    const handleSetSportId = (sportId: any) => {
        setSportId(sportId)
        setRegionId("")
        setCompetitionId("")
        setEventsPageNumber(1)
    }

    const handleSetRegionId = (regionId: any) => {
        setCompetitionId("")
        setRegionId(regionId)
        setEventsPageNumber(1)
    }

    const handleSetCompetitionId = (e: SingleValue<any>) => {
        const filterValues = {
            sportId: sportId?.value,
            regionId: regionId?.value,
            compId: e?.value
        }
        setCompetitionId(e)
        setEventsPageNumber(1)
        dispatch(EventsActionCreator.getEvents(getQueryString(filterValues)))
    }
    const queryStringForFixtureTable = () => {
        const filterValues = {
            sportId: sportId?.value,
            regionId: regionId?.value,
            compId: competitionId?.value
        }
        return getQueryString(filterValues)
    }
    return (
        <>
            {isOpenManualForm
                &&
                <EventsManualModal
                    setIsOpenModal={setIsOpenManualForm}
                    filter={{sportId, regionId, competitionId}}
                    getQueryString={getQueryString}
                />
            }

            {
                isOpenUpdateForm &&
                <EventUpdateModal
                    setIsOpenModal={setIsOpenUpdateForm}
                    filter={{sportId, regionId, competitionId}}
                    getQueryString={getQueryString}
                    events={events.items ? events.items : []}
                    editEventId={editInfo.eventId}
                />
            }
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <Title
                        value={'Events'}
                    />
                </div>
            </div>
            <div className={styles.filter}>
                <div className={styles.filterLeftSide}>
                    <div className="regularInput-Select mr20">
                        <SelectInput
                            value={sportId}
                            onChange={(e) => handleSetSportId(e)}
                            name={'sportId'}
                            searchable={true}
                            options={formatOptions(init?.sports?.items)}
                            label={'Sport'}/>
                    </div>
                    <div className="regularInput-Select mr20">
                        <SelectInput
                            value={regionId}
                            onChange={(e) => handleSetRegionId(e)}
                            name={'regionId'}
                            searchable={true}
                            options={formatOptions(init?.regions?.items)}
                            label={'Region'}/>
                    </div>
                    <div className="regularInput-Select mr20">
                        <SelectInput
                            value={competitionId}
                            onChange={handleSetCompetitionId}
                            name={"typeId"}
                            searchable={true}
                            options={formatOptions(init?.competitions?.items)}
                            label={'Competition'}/>
                    </div>
                    <div className={styles.clearFilterBtn}>
                        <Button
                            onClick={() => clearFilter()}
                            value={'Clear Filter'}
                        />
                    </div>
                </div>
                <div className={styles.filterRightSide}>
                    {events.items && <div className="regularBtn">
                        <Button
                            onClick={() => setIsOpenManualForm(true)}
                            value={'Create'}
                        />
                    </div>}
                </div>
            </div>
            <EventsTable
                openUpdateModal={openUpdateModal}
                openConfirmMenu={setIsOpenConfirm}
                setEditInfo={setEditInfo}
                totalPages={events.totalPages}
                events={events.items ? events.items : []}
                eventsPageNumber={eventsPageNumber}
                setEventsPageNumber={setEventsPageNumber}
            />
            {isOpenConfirm &&
                <WarningModal
                    modalText={"Do you want to delete event ?"}
                    action={deleteEvent}
                    setIsOpenModal={setIsOpenConfirm}
                />
            }
            <EventsFixtureTable
                queryString={queryStringForFixtureTable()}
                competitionId={competitionId}
                updateCompetitionId={setCompetitionId}
                fixtures={eventsFixtures ? eventsFixtures : []}
            />
        </>
    )
};

export default React.memo(Events);
