import {AuthAction, AuthActionEnum, IAuthState, UserInfo} from "./types";

const defaultState: IAuthState = {
    isAuth: false,
    username: "",
    isFirstStep: false,
    error: "",
    userInfo:{} as UserInfo,
    loading:false
}

export const authReducer = (state = defaultState, action: AuthAction): IAuthState => {

    switch (action.type) {
        case AuthActionEnum.SET_AUTH: {
            return {...state, isAuth: action.payload}
        }
        case AuthActionEnum.SET_USERNAME: {
            return {...state,username:action.payload}
        }
        case AuthActionEnum.SET_IS_FIRST_STEP:{
            return {...state,isFirstStep:action.payload}
        }
        case AuthActionEnum.SET_USER_INFO:{
            return {...state,userInfo:action.payload}
        }
        case AuthActionEnum.SET_AUTH_ERROR: {
            return {...state,error:action.payload}
        }
        case AuthActionEnum.SET_AUTH_LOADING:{
            return {...state,loading:action.payload}
        }
        default:
            return state
    }
}