export interface IChannel {
    id: number,
    name: string
    isDeleted: boolean
}

export interface IChannelsState {
    channels: IChannel[]
}

export interface IGetChannelsPayload {
    channelIds: number[],
    keyword: string,
    hasToBeAvailable: true
    sortingModel: {
        property: string,
        isAscending: boolean
    }
}

export enum ChannelsActionEnum {
    GET_CHANNELS = "GET_CHANNELS",
    SET_CHANNELS = "SET_CHANNELS",
    CREATE_CHANNEL = "CREATE_CHANNEL",
    CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS",
    UPDATE_CHANNEL = "UPDATE_CHANNEL",
    UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS",
    DELETE_CHANNEL = "DELETE_CHANNEL",
    DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS",
    RESTORE_CHANNEL = "RESTORE_CHANNEL",
    RESTORE_CHANNEL_SUCCESS = "RESTORE_CHANNEL_SUCCESS"
}

export interface IGetChannels {
    type: ChannelsActionEnum.GET_CHANNELS
    payload: IGetChannelsPayload | {}
}

export interface ISetChannels {
    type: ChannelsActionEnum.SET_CHANNELS
    payload: IChannel[]
}

export interface ICreateChannel {
    type: ChannelsActionEnum.CREATE_CHANNEL
    payload: { name: string }
}

export interface ICreateChannelSuccess {
    type: ChannelsActionEnum.CREATE_CHANNEL_SUCCESS
    payload: IChannel
}

export interface IUpdateChannel {
    type: ChannelsActionEnum.UPDATE_CHANNEL
    payload: { name: string, id: number }
}

export interface IUpdateChannelSuccess {
    type: ChannelsActionEnum.UPDATE_CHANNEL_SUCCESS
    payload: IChannel
}

export interface IDeleteChannel {
    type: ChannelsActionEnum.DELETE_CHANNEL
    payload: number
}

export interface IDeleteChannelSuccess {
    type: ChannelsActionEnum.DELETE_CHANNEL_SUCCESS
    payload: number
}

export interface IRestoreChannel {
    type: ChannelsActionEnum.RESTORE_CHANNEL
    payload: number
}

export interface IRestoreChannelSuccess {
    type: ChannelsActionEnum.RESTORE_CHANNEL_SUCCESS
    payload: number
}

export type ChannelAction = ISetChannels | ICreateChannelSuccess
    | IDeleteChannelSuccess | IRestoreChannelSuccess | IUpdateChannelSuccess