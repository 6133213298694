import {UsersActionEnum, IUsersState, UsersAction} from "./types";


const defaultState: IUsersState = {
    users: [],
    roles: [],
    isLoading: false
}

export const usersReducer = (state = defaultState, action: UsersAction): IUsersState => {

    switch (action.type) {
        case UsersActionEnum.SET_USERS: {
            return {...state, users: action.payload}
        }
        case UsersActionEnum.SET_USERS_LOADER: {
            return {...state, isLoading: action.payload}
        }
        case UsersActionEnum.SET_USERS_ROLES: {
            return {...state, roles: action.payload.items}
        }
        case UsersActionEnum.UPDATE_USER_SUCCESS: {
            const updatingUsers = state.users.map(user => (
                user.userId === action.payload.updatingBody.userId ?
                    {...user, role: action.payload.roleName}
                    :
                    user
            ))
            return {...state, users: updatingUsers}
        }
        case UsersActionEnum.DELETE_USER_SUCCESS: {
            const users = state.users.filter(user => user.userId !== action.payload)
            return {...state, users}
        }
        default:
            return state
    }
}