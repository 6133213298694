import {AuditFixtureAction, AuditActionEnum, IAuditFixtureState} from "./types";

const defaultState: IAuditFixtureState = {
    fixtureAudit:[],
    eventAudit: [],
    competitionAudit:[]
}

export const auditReducer = (state = defaultState, action: AuditFixtureAction): IAuditFixtureState => {
    switch (action.type) {
        case AuditActionEnum.SET_FIXTURE_AUDIT: {
            return {...state, fixtureAudit: action.payload}
        }
        case AuditActionEnum.SET_EVENT_AUDIT: {
            return {...state,eventAudit:action.payload}
        }
        case AuditActionEnum.SET_COMPETITION_AUDIT: {
            return {...state, competitionAudit:action.payload}
        }
        default:
            return state
    }
}