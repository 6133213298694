import {ChannelAction, ChannelsActionEnum, IChannelsState} from "./types";

const defaultState: IChannelsState = {
    channels: []
}

export const channelsReducer = (state = defaultState, action: ChannelAction): IChannelsState => {
    switch (action.type) {
        case ChannelsActionEnum.SET_CHANNELS: {
            return {...state, channels: action.payload}
        }

        case ChannelsActionEnum.CREATE_CHANNEL_SUCCESS: {
            const allChanel = state.channels.slice()
            allChanel.unshift(action.payload)
            return {...state, channels: allChanel}
        }

        case ChannelsActionEnum.UPDATE_CHANNEL_SUCCESS: {
            const allChannel = state.channels.map(channel => channel.id === action.payload.id ? action.payload : channel)
            return {...state, channels: allChannel}
        }

        case ChannelsActionEnum.DELETE_CHANNEL_SUCCESS: {
            const allChanel = state.channels.map(channel => {
                if (channel.id === action.payload) {
                    channel.isDeleted = true
                    return channel
                }
                return channel
            })
            return {...state, channels: allChanel}
        }

        case ChannelsActionEnum.RESTORE_CHANNEL_SUCCESS: {
            const allChanel = state.channels.map(channel => {
                if (channel.id === action.payload) {
                    channel.isDeleted = false
                    return channel
                }
                return channel
            })
            return {...state, channels: allChanel}
        }

        default:
            return state
    }
}