import React, {useEffect} from 'react';
import Input from "../../components/Input";
import Button from "../../components/Button";
import styles from './style.module.scss'
import footerLogo from "../../img/new_logo.svg";
import {Controller, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {UsersActionCreators} from "../../store/reducers/userManagement/action-creators";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {AuthActionCreators} from "../../store/reducers/auth/action-creators";

const ResetPassword:React.FC = () => {

    const {handleSubmit, control, getValues, formState: {errors},} = useForm({
        defaultValues: {
            password: "",
            confirmPassword: ""
        }
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const confirmToken = searchParams.get('ct')
        if (confirmToken) {
            localStorage.removeItem("iptv-token");
            localStorage.setItem("iptv-token", confirmToken);
        }
    }, [])

    const onSubmit = () => {
        const payload = getValues("password");
        dispatch(UsersActionCreators.confirmResetPassword(payload));

        setTimeout(() => {
            localStorage.removeItem("iptv-token");
            navigate('/login');
        }, 1000)
    }

    const checkIsMatchPassword = () => {
        return getValues("password") === getValues("confirmPassword")
    }

    return (
        <div className={styles.formInner}>
            <form onSubmit={handleSubmit(onSubmit)} className={styles.resetPasswordForm}>
                <h1 className={styles.formTitle}>Enter your new password:</h1>

                <div className={styles.inputInner}>
                    <div className={styles.formInput}>
                        <Controller name="password"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field: {onChange, value}}) =>
                                        <Input
                                            readonly={false}
                                            label={"Password"}
                                            value={value}
                                            type="password"
                                            setValue={onChange}/>}/>
                    </div>
                    {errors.password?.type === 'required' &&
                        <p className={styles.formError}>Password is required field</p>}
                </div>

                <div className={styles.inputInner}>
                    <div className={styles.formInput}>
                        <Controller name="confirmPassword"
                                    control={control}
                                    rules={{validate: {missMatch: checkIsMatchPassword,}}}
                                    render={({field: {onChange, value}}) =>
                                        <Input
                                            readonly={false} label={"Confirm Password"}
                                            value={value} type="password"
                                            setValue={onChange}/>}/>
                    </div>
                    {errors.confirmPassword?.type === 'missMatch' &&
                        <p className={styles.formError}>Passwords mismatch</p>}
                </div>

                <div className={styles.submitBtn}>
                    <Button type={"submit"} value={"Submit"}/>
                </div>
                <div className={styles.formFooter}>
                    <div>
                        <img src={footerLogo} alt="logo"/>
                    </div>
                </div>
            </form>
        </div>
    );
};
export default React.memo(ResetPassword);
