export interface IDecoder {
    id: number,
    name: string
    isDeleted: boolean
}

export interface IDecodersState {
    decoders: IDecoder[]
}

export enum DecodersActionEnum {
    GET_DECODERS = "GET_DECODERS",
    SET_DECODERS = "SET_DECODERS",
    CREATE_DECODER = "CREATE_DECODER",
    UPDATE_DECODER = "UPDATE_DECODER",
    DELETE_DECODER = "DELETE_DECODER",
    DELETE_DECODER_SUCCESS = "DELETE_DECODER_SUCCESS",
    TOGGLE_DELETE_DECODER = "TOGGLE_DELETE_DECODER",
    TOGGLE_DELETE_DECODER_SUCCESS = "TOGGLE_DELETE_DECODER_SUCCESS"
}

export interface IGetDecoders {
    type: DecodersActionEnum.GET_DECODERS
    queryString:string
}

export interface ISetDecoders {
    type: DecodersActionEnum.SET_DECODERS
    payload: IDecoder[]
}

export interface ICreateDecoder {
    type: DecodersActionEnum.CREATE_DECODER
    payload: { name: string }
}

export interface IUpdateDecoder {
    type: DecodersActionEnum.UPDATE_DECODER
    payload: { name: string, id: number }
}

export interface IDeleteDecoder {
    type: DecodersActionEnum.DELETE_DECODER
    payload: number
}
export interface IDeleteDecoderSuccess {
    type: DecodersActionEnum.DELETE_DECODER_SUCCESS
    payload: number
}

export interface IToggleDeleteDecoder {
    type: DecodersActionEnum.TOGGLE_DELETE_DECODER
    payload: number
}
export interface IToggleDeleteDecoderSuccess {
    type: DecodersActionEnum.TOGGLE_DELETE_DECODER_SUCCESS
    payload: IDecoder
}


export type DecodersAction = ISetDecoders | IDeleteDecoderSuccess |IToggleDeleteDecoderSuccess