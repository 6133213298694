import {client} from "../../../services/api-servise";
import {ICreateSportTimingBody, IUpdateSportTimingPayload} from "./types";

export const getSportTimingRequest = async (payload: any): Promise<any> => {
    const sportTimings = await client.post('/api/Sport/Search', payload)
    return sportTimings.data
}

export const createSportTimingRequest = async (payload: ICreateSportTimingBody): Promise<any> => {
    const sportTiming = await client.post('/api/Sport/Create', payload)
    return sportTiming.data
}

export const updateSportTimingRequest = async (payload: IUpdateSportTimingPayload): Promise<any> => {
    const sportTiming = await client.put('/api/Sport/Update', payload)
    return sportTiming.data
}

export const deleteSportTimingRequest = async (payload: string): Promise<any> => {
    const sportTiming = await client.delete(`api/Sport/Delete?id=${payload}`)
    return sportTiming.data
}

export const restoreSportTimingRequest = async (payload: string): Promise<any> => {
    const sportTiming = await client.put(`api/Sport/Restore?id=${payload}`)
    return sportTiming.data
}









