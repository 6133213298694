import React, {useEffect, useState} from 'react';
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {BroadcastersActionCreators} from "../../../store/reducers/broadcasters/action-creators";
import BroadcasterModal from "./BroadcasterModal/BroadcasterModal";
import WarningModal from "../../../components/WarningModal/WarningModal";
import ManagementTable from "../ManagementTable";
import {useBroadcasterSearch} from "./BroadcastersHooks/useBroadcasterSearch";


const Broadcasters: React.FC = () => {
    const [isOpenModal, setIsOpenModal] = useState(false)
    const {broadcasters} = useTypedSelector(state => state.broadcastersReducer)
    const [editInfo, setEditInfo] = useState({isEdit: false, broadcasterId: 0})
    const [isOpenConfirm, setIsOpenConfirm] = useState(false)
    const {findBroadcasterByName, searchValue, searchResult} = useBroadcasterSearch(broadcasters)
    const dispatch = useDispatch()

    useEffect(() => {
        const queryString = "?SortingModel.Property=name&SortingModel.IsAscending=true"
        dispatch(BroadcastersActionCreators.getBroadcasters(queryString))
    }, [])


    const deleteBroadcaster = () => {
        dispatch(BroadcastersActionCreators.deleteBroadcaster(editInfo.broadcasterId))
    }

    const openConfirmMenu = () => {
        setIsOpenConfirm(true)
    }

    return (
        <div>
            <div className={'flexAlignStart mt64'}>
                <ManagementTable
                    findBroadcasterByName={findBroadcasterByName}
                    searchValue={searchValue}
                    searchResult={searchResult}
                    openConfirmMenu={openConfirmMenu}
                    setEditInfo={setEditInfo}
                    setIsOpenModal={setIsOpenModal}
                    broadcasters={broadcasters}
                />
            </div>
            {isOpenModal &&
                <BroadcasterModal
                    setIsOpenModal={setIsOpenModal}
                    editInfo={editInfo}
                    setEditInfo={setEditInfo}
                />
            }
            {isOpenConfirm &&
                <WarningModal
                    setIsOpenModal={setIsOpenConfirm}
                    action={deleteBroadcaster}
                    modalText={"Do you want to delete a broadcaster?"}
                />
            }
        </div>
    );
};

export default React.memo(Broadcasters);
