import {NavLink, useNavigate} from "react-router-dom";
import React from "react";
import styles from './index.module.scss';
import {AuthActionCreators} from "../../../store/reducers/auth/action-creators";
import {useDispatch} from "react-redux";

interface IDropDownMenuProps {
    links: IDropdownLink[]
    onCloseMenu: (flag: boolean) => void
}

interface IDropdownLink {
    part: string,
    text: string,
}

const DropDownMenu: React.FC<IDropDownMenuProps> = ({links, onCloseMenu}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const logout = () => {
        localStorage.removeItem("iptv-token")
        localStorage.removeItem("iptv-refresh")
        dispatch(AuthActionCreators.logout())
        navigate("/login")
    }

    return (
        <div className={styles.dropdownMenu}>
            {links.map((link, index) => {
                return <NavLink
                    onClick={link.text === 'LOGOUT' ? logout : () => onCloseMenu(false)}
                    className={(navData) => (navData.isActive ? `${styles.active}` : `${styles.link}`)}
                    to={link.text === 'LOGOUT' ? '/login' : link.part} key={index}>
                    {link.text}
                </NavLink>
            })}
        </div>
    );
};

export default React.memo(DropDownMenu);