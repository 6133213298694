import {Range} from "react-calendar-datetime-picker";
import {MultiSelectValue} from "./useGetSchedules";
import {IFiltersState} from "./useTableFilters";

export const getQueryString = (range: Range,
                               sport: MultiSelectValue,
                               groupBy: MultiSelectValue,
                               sortingString: string,
                               filtersState?: IFiltersState) => {

    const getStringFromFilterValue = (query: string, value: any) => {
        if (value) {
            return `&${query}=${value}`
        }
        return []
    }
    const getFilterSportValues = () => {
        let sportValues: string = ""
        sport.forEach((sport: { value: string; }) => {
            sportValues += `&SportIds=${sport.value}`
        })
        if (sportValues.length > 0) {
            return sportValues
        } else {
            return ""
        }
    }

    const getTableFiltersValues = () => {
        let queryString: string = '';

        if (filtersState) {
            if (filtersState.broadcaster.length) {
                filtersState.broadcaster.forEach(broadcasterItem => {
                    queryString += `&BroadcasterIds=${broadcasterItem.value}`
                })
            }

            if (filtersState.server.length) {
                filtersState.server.forEach(serverItem => {
                    queryString += `&ServerIds=${serverItem.value}`
                })
            }

            if (filtersState.decoder.length) {
                filtersState.decoder.forEach(decoderItem => {
                    queryString += `&DecoderIds=${decoderItem.value}`
                })
            }

            if (filtersState.sport.length) {
                filtersState.sport.forEach(sportItem => {
                    queryString += `&SportIds=${sportItem.value}`
                })
            }

            if (filtersState.competition.length) {
                filtersState.sport.forEach(competitionItem => {
                    queryString += `&CompetitionIds=${competitionItem.value}`
                })
            }

            if (filtersState.country.length) {
                filtersState.sport.forEach(countryItem => {
                    queryString += `&CountryIds=${countryItem.value}`
                })
            }
        }

        return queryString
    }

    return `StartTime=${range?.from?.year}-${range?.from?.month}-${range?.from?.day}&EndTime=${range?.to?.year}-${range?.to?.month}-${range?.to?.day}${getStringFromFilterValue("GroupById", groupBy.value)}${getFilterSportValues()}${sortingString}${filtersState ? getTableFiltersValues() : ''}`
}
