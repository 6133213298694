import Title from "../../../components/Title";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AuditEventTable from "./AuditEventTable";
import {AuditActionCreators} from "../../../store/reducers/audit/action-creators";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {toastr} from "react-redux-toastr";

export const AuditEvent =() => {
    const {id} = useParams<{id:string}>()
    const dispatch = useDispatch();
    const {eventAudit} = useTypedSelector(state=>state.auditReducer)
    const [eventId, setEventId] = useState("")

    useEffect(()=>{
        if (id !== ":id" && id){
            getAuditEvent(id)
            setEventId(id)
        }
    },[id])

    const getAuditEvent =(eventId:string) => {
        if (eventId){
            dispatch(AuditActionCreators.getEventAudit(eventId))
        }
        else toastr.warning("IGame","Enter event id")
    }

    return (
        <div className={'container'}>
            <div className="mt64">
                <Title value={'Event Audit Trail'}/>
            </div>
            <div className="flexAlignEnd">
                <div className="largeSelect mt15">
                    <Input
                        setValue={setEventId}
                        value={eventId}
                        label={'Event'}
                        readonly={false}/>
                </div>
                <div className="regularBtn">
                    <Button onClick={()=>getAuditEvent(eventId)} value={'View Audit Trail'}/>
                </div>
            </div>
            <div className={'mt60'}>
                <AuditEventTable eventAudit={eventAudit}/>

            </div>
        </div>
    )
}

export default React.memo(AuditEvent);
