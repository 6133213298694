
import React, {useEffect, useState} from "react";
import {IDecoder} from "../../../../store/reducers/decoders/types";

export const useDecodersSearch = (decoders:IDecoder[]) => {
    const [searchResult, setSearchResult] = useState<IDecoder[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const findDecoderByName = (e:React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
        const filteredDecoders = decoders.filter(decoder => decoder.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setSearchResult(filteredDecoders)
    }
    useEffect(() => {
       if(searchValue&&decoders){
           const filteredDecoders = decoders.filter(decoder => decoder.name.toLowerCase().includes(searchValue.toLowerCase()))
           setSearchResult(filteredDecoders)
       }
    }, [decoders]);

    return {searchResult,findDecoderByName,searchValue}
}