import {useEffect} from "react";

 export function useOutsideHeaderClick(ref,ignoreRef,outsideClickHandler) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ignoreRef.current && ignoreRef.current.contains(event.target)) {
                return
            }
            if (ref.current && !ref.current.contains(event.target)) {
                outsideClickHandler()
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}