import React from "react";
import styles from './auditFixtureTable.module.scss'
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import moment from "moment/moment";

const AuditTable: React.FC = () => {
    const {fixtureAudit} = useTypedSelector(state => state.auditReducer)

    const highlightField = (fieldName: string, modifiedFields: string[]) => {
        let isModifiedField = false
        modifiedFields?.forEach((field) => {
            if (field === fieldName) {
                isModifiedField = true
            }
        })
        return isModifiedField ? styles.modifiedField : ""
    }

    return (
        <div className={styles.wrapper}>
            <table className={styles.auditTable}>
                <thead className={styles.tableHeadWrapper}>
                <tr className={styles.tableRow} key={1}>
                    <th className={styles.tableHeader}>Fixture</th>
                    <th className={styles.tableHeader}>Start Time</th>
                    <th className={styles.tableHeader}>End Time</th>
                    <th className={styles.tableHeader}>Information</th>
                    <th className={styles.tableHeader}>Description</th>
                    <th className={styles.tableHeader}>Sport Name</th>
                    <th className={styles.tableHeader}>Season</th>
                    <th className={styles.tableHeader}>Round</th>
                    <th className={styles.tableHeader}>Created TimeStamp</th>
                    <th className={styles.tableHeader}>Mod.By</th>
                    <th className={styles.tableHeader}>Schedule Time</th>
                </tr>
                </thead>
                <tbody className={styles.tableBodyWrapper}>
                {fixtureAudit.map(audit => {
                    return (
                        <tr className={styles.tableRow} key={audit.id}>
                            <td className={styles.tableDescription}>{audit.snapshot.id}</td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("StartTime", audit.modifiedFields)}
                                >
                                    {audit.snapshot.startTime}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("EndTime", audit.modifiedFields)}
                                >
                                    {audit.snapshot.endTime}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("Information", audit.modifiedFields)}
                                >
                                  {audit.snapshot.information}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("Description", audit.modifiedFields)}
                                >
                                  {audit.snapshot.description}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("SportName", audit.modifiedFields)}
                                >
                                    {audit.sportName}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("Season", audit.modifiedFields)}
                                >
                                    {audit.snapshot.season}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("Round", audit.modifiedFields)}
                                >
                                    {audit.snapshot.round}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("ModifyDate", audit.modifiedFields)}
                                >
                                  {moment(audit.modifyDate).format("YYYY-MM-DD:HH:mm")}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("ModifierUsername", audit.modifiedFields)}
                                >
                                  {audit.modifierUsername}
                                </span>
                            </td>
                            <td className={styles.tableDescription}>
                                <span
                                    className={highlightField("WtUpdate", audit.modifiedFields)}
                                >
                                {audit.wtUpdate && moment(audit.wtUpdate).format("YYYY-MM-DD:HH:mm")}
                                </span>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>

    );
};

export default React.memo(AuditTable);
