import {
    ICancelEvent,
    IDuplicateEvent,
    IDuplicateEventSuccess,
    IEditEvent,
    IEmailBody,
    IEmailTemplateInit,
    IExpireEvent,
    IGetEmailTemplate, IGetFilersOptionsResp, IGetFiltersOptions,
    IGetSchedule,
    IRemoveEvent,
    IRemoveSuccess,
    ISchedule,
    ISendEmail,
    ISetEmailTemplate,
    ISetEndDate, ISetFiltersOptions,
    ISetInternal,
    ISetIsVerify,
    ISetProtection,
    ISetScheduledFixtures, ISetSeveralEvents,
    IUpdateEvent, IUpdateEvents, IUpdateSeveralEvents,
    ScheduleActionEnum
} from "./types";

import {IGetScheduleResp} from "./saga";
import {IUpdatedEvent} from "../event/types";

export const ScheduleActionCreators = {
    getSchedules: (queryString:string): IGetSchedule => ({type:ScheduleActionEnum.GET_SCHEDULED_FIXTURES,queryString}),

    setSchedulesFixtures: (payload:IGetScheduleResp):ISetScheduledFixtures => ({type: ScheduleActionEnum.SET_SCHEDULED_FIXTURES, payload: payload}),

    setIsVerify:(payload:{ i: number, b: boolean }): ISetIsVerify => ({type:ScheduleActionEnum.SET_IS_VERIFY,payload}),

    setProtection:(payload:{ i: number, b: boolean }): ISetProtection => ({type:ScheduleActionEnum.SET_PROTECTION,payload}),

    setInternal:(payload:{ i: number, b: boolean }): ISetInternal => ({type:ScheduleActionEnum.SET_INTERNAL,payload}),

    updateEvent:(payload:ISchedule): IUpdateEvent => ({type:ScheduleActionEnum.UPDATE_SCHEDULE_EVENT,payload}),

    cancelEvent:(payload:{ i: number, b: boolean }): ICancelEvent => ({type:ScheduleActionEnum.CANCEL_EVENT,payload}),

    expireEvent:(payload:number): IExpireEvent => ({type:ScheduleActionEnum.EXPIRE_EVENT,payload}),

    removeEvent:(payload:number): IRemoveEvent => ({type:ScheduleActionEnum.REMOVE_EVENT,payload}),

    removeSuccess:(payload:number): IRemoveSuccess => ({type:ScheduleActionEnum.REMOVE_SUCCESS,payload}),

    duplicateEvent:(payload:number): IDuplicateEvent => ({type:ScheduleActionEnum.DUPLICATE_EVENT,payload}),

    duplicateEventSuccess:(payload:ISchedule): IDuplicateEventSuccess => ({type:ScheduleActionEnum.DUPLICATE_EVENT_SUCCESS,payload}),

    setEventEndDate:(payload:{ i: number, b: boolean }): ISetEndDate => ({type:ScheduleActionEnum.SET_END_DATE,payload}),

    getEmailTemplate:():IGetEmailTemplate => ({type:ScheduleActionEnum.GET_EMAIL_TEMPLATE}),

    setEmailTemplate:(payload:IEmailTemplateInit): ISetEmailTemplate => ({type:ScheduleActionEnum.SET_EMAIL_TEMPLATE,payload}),

    sendEmail:(payload:IEmailBody): ISendEmail => ({type:ScheduleActionEnum.SEND_EMAIL,payload}),

    setScheduleLoader:(payload:boolean) => ({type:ScheduleActionEnum.SET_SCHEDULE_LOADER,payload}),

    editScheduleEvent:(payload:IUpdatedEvent):IEditEvent=>({type:ScheduleActionEnum.EDIT_SCHEDULE_EVENT,payload}),

    updateSeveralEvent:(payload:IUpdateEvents): IUpdateSeveralEvents => ({type:ScheduleActionEnum.EDIT_SEVERAL_SCHEDULE_EVENTS,payload}),

    setSeveralEvents: (payload: ISchedule[]):ISetSeveralEvents =>({type: ScheduleActionEnum.SET_SEVERAL_SCHEDULE_EVENTS, payload}),

    getFiltersOptions: (): IGetFiltersOptions => ({type:ScheduleActionEnum.GET_FILTERS_OPTIONS}),

    setFiltersOptions: (payload:IGetFilersOptionsResp):ISetFiltersOptions => ({type: ScheduleActionEnum.SET_FILTERS_OPTIONS, payload}),
}
