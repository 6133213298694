import React, {useEffect, useState} from 'react';
import SportTimingsTable from "./SportTimingsTable/SportTimingsTable";
import SportTimingsForm from "./SportTimingsForm/SportTimingsForm";
import WarningModal from "../../../components/WarningModal/WarningModal";
import {useDispatch} from "react-redux";
import styles from './style.module.scss'
import {SportTimingActionCreators} from "../../../store/reducers/sportTimings/action-creators";

const SportTimings = () => {
    const dispatch = useDispatch()
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [editInfo, setEditInfo] = useState({isEdit: false, sportId: ""})
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
    useEffect(() => {
        dispatch(SportTimingActionCreators.getSportTimings({}))
    }, []);

    const openConfirmMenu = () => {
        setIsOpenConfirmModal(true)
    }

    const deleteSportTiming = () => {
        dispatch(SportTimingActionCreators.deleteSportTiming(editInfo.sportId))
    }

    return (
        <div className={styles.sportTimingsPage}>
            {isOpenForm &&
                <SportTimingsForm
                    editInfo={editInfo}
                    setEditInfo={setEditInfo}
                    setIsOpenModal={setIsOpenForm}
                />
            }
            <SportTimingsTable
                setIsOpenModal={setIsOpenForm}
                setEditInfo={setEditInfo}
                openConfirmMenu={openConfirmMenu}
            />
            {isOpenConfirmModal &&
                <WarningModal
                    modalText={"Do you want to delete sport timing ?"}
                    action={deleteSportTiming}
                    setIsOpenModal={setIsOpenConfirmModal}
                />
            }
        </div>
    );
};

export default SportTimings;
