import React, {Dispatch, useState} from "react";
import Title from "../../../../components/Title";
import {IEventFixture} from "../../../../store/reducers/event/types";
import Checkbox from "../../../../components/Checkbox";
import {useDispatch} from "react-redux";
import {EventsActionCreator} from "../../../../store/reducers/event/action-creators";
import Button from "../../../../components/Button";
import {SingleValue} from "react-select";
import {replaceCountFixtures} from "../helpers/replaceCountFixtures";
import styles from './managementTable.module.scss';


interface ITableProps {
    fixtures: IEventFixture[]
    updateCompetitionId: Dispatch<SingleValue<any>>
    competitionId: SingleValue<any>
    queryString: string
}

const EventsFixtureTable: React.FC<ITableProps> = ({fixtures, updateCompetitionId, competitionId, queryString}) => {
    const [selectedFixtures, setSelectedFixtures] = useState<IEventFixture[]>([])
    const dispatch = useDispatch()
    const selectFixture = (e: React.MouseEvent, id: string) => {
        e.preventDefault();
        e.stopPropagation();
        const selectedFixture = fixtures.find(fixture => fixture.fixtureId === id)
        if (selectedFixture) {
            const existSelectedFixture = selectedFixtures.find(fixture => fixture.fixtureId === selectedFixture.fixtureId)
            if (existSelectedFixture) {
                const allSelectedFixture = selectedFixtures.filter(fixture => fixture.fixtureId !== existSelectedFixture.fixtureId)
                return setSelectedFixtures(allSelectedFixture)
            } else {
                return setSelectedFixtures([...selectedFixtures, selectedFixture])
            }
        }
    }

    const isChecked = (fixtureId: string) => {
        const isChecked = selectedFixtures.find(fixture => fixture.fixtureId === fixtureId)
        return !!isChecked
    }

    const selectAllFixture = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (selectedFixtures.length === fixtures.length) {
            return setSelectedFixtures([])
        } else {
            setSelectedFixtures(fixtures)
        }

    }

    const createEvents = () => {
        const creatingFixtures: string[] = []
        selectedFixtures.forEach(fixture => {
            creatingFixtures.push(fixture.fixtureId)
        })
        const payload = {
            fixtures:creatingFixtures,
            queryString
        }
        dispatch(EventsActionCreator.multiCreateEvents(payload))
        setSelectedFixtures([])
        updateCompetitionId({
            ...competitionId,
            label: replaceCountFixtures(competitionId.label, fixtures.length - selectedFixtures.length)
        })
    }

    return (
        <div className={styles.wrapperEventsFixture}>
            <div className={styles.titleInner}>
                <div className={'tableTitle'}>
                    <Title value={'Fixtures'}/>
                </div>
                {selectedFixtures.length > 0 && <div className={styles.createBtn}>
                    <Button
                        onClick={createEvents}
                        value={"Create"}

                    />
                </div>}
            </div>
            <div className={styles.tableWrapper}>
                <table className={styles.auditTable}>
                    <thead className={styles.tableHeadWrapper}>
                    <tr className={styles.tableRow}>
                        <th className={styles.tableHeader}>Fixture</th>
                        <th className={styles.tableHeader}>Start Time</th>
                        <th className={styles.tableHeader}>End Time</th>
                        <th className={styles.tableHeader}>Information</th>
                        <th className={styles.tableHeader}>Description</th>
                        <th className={styles.tableHeader}>Competition</th>
                        <th className={styles.tableHeader}>Sport</th>
                        <th className={styles.tableHeader}>Status</th>
                        <th className={styles.tableHeader}>Mod. By</th>
                        <th className={styles.tableHeader}>Created</th>
                        <th className={styles.tableHeader}>
                            <div
                                onClick={(e) => selectAllFixture(e)}
                                className={styles.selectAll}>
                                <span className={styles.selectAllText}>Select all</span>
                                <Checkbox isChecked={selectedFixtures.length === fixtures.length}/>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody className={styles.tableBodyWrapper}>
                    {fixtures?.map(item => {
                        return (
                            <tr className={styles.tableRow} key={item.fixtureId}>
                                <td className={styles.tableDescription}>{item.fixtureId}</td>
                                <td className={styles.tableDescription}>{item.startDate}</td>
                                <td className={styles.tableDescription}>{item.endDate}</td>
                                <td className={styles.tableDescription}>{item.information}</td>
                                <td className={styles.tableDescription}>{item.description}</td>
                                <td className={styles.tableDescription}>{item.competitionName}</td>
                                <td className={styles.tableDescription}>{item.sportName}</td>
                                <td className={styles.tableDescription}>{item.status}</td>
                                <td className={styles.tableDescription}>{item.modifierUsername}</td>
                                <td className={styles.tableDescription}>{item.createdDate}</td>
                                <td className={styles.tableDescriptionTextEnd}>
                                    <div onClick={(e) => selectFixture(e, item.fixtureId)}>
                                        <Checkbox isChecked={isChecked(item.fixtureId)}/>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default React.memo(EventsFixtureTable)
