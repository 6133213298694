import {call, takeEvery, put,} from 'redux-saga/effects';
import {EventsActionCreator} from "./action-creators";
import {
    EventActionEnum,
    ICreateEvent,
    IDeleteEvent,
    IEventsInfo,
    IGetEvents, IMultiCreateEvents, IUpdateEvent
} from "./types";
import {
    createEventRequest,
    deleteEventRequest,
    getEventsRequest,
    multiCreateEventsRequest,
    updateEventRequest
} from "./requests";
import {IInputOption} from "../fixtures/types";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";

export interface IGetEventsResp {
    sports: {
        items: IInputOption[]
    },
    regions: {
        items: IInputOption[]
    },
    competitions: {
        items: IInputOption[]
    },
    sportsSelected: [
        string
    ],
    regionsSelected: [
        string
    ],
    competitionsSelected: [
        string
    ],
    events: IEventsInfo,
    fixtures:[]
    fixturesCount:number
}

function* tryGetEvents({queryString}: IGetEvents) {
    try {
        const formatQueryString = queryString.substring(0, queryString.length - 1)
        const resp: IGetEventsResp= yield call(getEventsRequest,formatQueryString)
        yield put(EventsActionCreator.setEvents(resp))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryCreateEvent({payload:{event,queryString}}: ICreateEvent) {
    try {
        yield call(createEventRequest,event)
        yield put(EventsActionCreator.getEvents(queryString))
        toastr.success('IGameMedia', 'Event has been created',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryMultiCreateEvents({payload:{fixtures,queryString}}: IMultiCreateEvents) {
    try {
        yield call(multiCreateEventsRequest,fixtures)
        yield put(EventsActionCreator.deleteEventsFixture(fixtures))
        yield put(EventsActionCreator.getEvents(queryString))
        toastr.success('IGameMedia', 'Events has been created',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryUpdateEvent({payload:{event,queryString}}: IUpdateEvent) {
    try {
        yield call(updateEventRequest,event)
        yield put(EventsActionCreator.getEvents(queryString))
        toastr.success('IGameMedia', 'Event has been updated',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryDeleteEvent({payload:{eventId,queryString}}: IDeleteEvent) {
    try {
        yield call(deleteEventRequest,eventId)
        yield put(EventsActionCreator.getEvents(queryString))
        toastr.success('IGameMedia', 'Event has been deleted',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}


export default function* eventsSaga(): any {
    yield takeEvery(EventActionEnum.GET_EVENTS, tryGetEvents);
    yield takeEvery(EventActionEnum.CREATE_EVENT, tryCreateEvent);
    yield takeEvery(EventActionEnum.UPDATE_EVENT, tryUpdateEvent);
    yield takeEvery(EventActionEnum.DELETE_EVENT, tryDeleteEvent);
    yield takeEvery(EventActionEnum.MULTI_CREATE_EVENTS, tryMultiCreateEvents);
}