export interface IResetPasswordData {
    newPassword: string,
    encryptedValue: string
}

export type UserInfo = {
    accessToken: string,
    userId: number,
    username: string,
    fullName: string,
    email: string,
    forceUpdatePassword: boolean
}

export interface IAuthState {
    isAuth: boolean
    username: string
    isFirstStep: boolean
    error: string
    userInfo: UserInfo
    loading:boolean
}

export enum AuthActionEnum {
    SET_AUTH = "SET_AUTH",
    TRY_LOGIN = "TRY_LOGIN",
    LOGOUT = "LOGOUT",
    SET_USERNAME = "SET_USERNAME",
    SET_IS_FIRST_STEP = "SET_IS_FIRST_STEP",
    VERIFY_USER = "VERIFY_USER",
    SET_USER_INFO = "SET_USER_INFO",
    SET_AUTH_ERROR = "SET_AUTH_ERROR",
    SET_AUTH_LOADING = "SET_AUTH_LOADING"
}

export interface ISetAuth {
    type: AuthActionEnum.SET_AUTH
    payload: boolean
}

export interface ISetAuthLoading {
    type: AuthActionEnum.SET_AUTH_LOADING
    payload: boolean
}

export interface ISetUserInfo {
    type: AuthActionEnum.SET_USER_INFO
    payload: UserInfo
}

export interface ILogin {
    type: AuthActionEnum.TRY_LOGIN
    payload: { username: string, password: string }
}


export interface ILogout {
    type: AuthActionEnum.LOGOUT
}

export interface ISetUsername {
    type: AuthActionEnum.SET_USERNAME
    payload: string
}

export interface ISetIsFirstStep {
    type: AuthActionEnum.SET_IS_FIRST_STEP
    payload: boolean
}

export interface IVerifyUser {
    type: AuthActionEnum.VERIFY_USER
    payload: { username: string, verificationCode: string, staySignedIn: boolean }
}

export interface ISetAuthError {
    type: AuthActionEnum.SET_AUTH_ERROR
    payload: string
}

export type AuthAction = ISetAuth | ISetUsername | ISetIsFirstStep | ISetUserInfo | ISetAuthError| ISetAuthLoading
