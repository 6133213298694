import {
    ICreateSportTiming,
    ICreateSportTimingBody,
    ICreateSportTimingSuccess,
    IDeleteSportTiming,
    IDeleteTiming,
    IGetSportTimings,
    IGetSportTimingsPayload,
    IRestoreSportTiming, IRestoreSportTimingSuccess,
    ISetSportTimings,
    ISportTiming,
    IUpdateSportTiming,
    IUpdateSportTimingPayload,
    IUpdateSportTimingSuccess,
    SportTimingsActionEnum,
} from "./types";

export const SportTimingActionCreators = {
    getSportTimings: (payload:IGetSportTimingsPayload|{}):IGetSportTimings => ({type:SportTimingsActionEnum.GET_SPORT_TIMINGS,payload}),
    setSportTiming: (payload:ISportTiming[]):ISetSportTimings => ({type:SportTimingsActionEnum.SET_SPORT_TIMINGS,payload}),
    createSportTiming: (payload:ICreateSportTimingBody):ICreateSportTiming => ({type:SportTimingsActionEnum.CREATE_SPORT_TIMING,payload}),
    createSportTimingSuccess: (payload:ISportTiming):ICreateSportTimingSuccess => ({type:SportTimingsActionEnum.CREATE_SPORT_TIMING_SUCCESS,payload}),
    updateSportTiming: (payload:IUpdateSportTimingPayload):IUpdateSportTiming => ({type:SportTimingsActionEnum.UPDATE_SPORT_TIMING,payload}),
    updateSportTimingSuccess: (payload:ISportTiming):IUpdateSportTimingSuccess => ({type:SportTimingsActionEnum.UPDATE_SPORT_TIMING_SUCCESS,payload}),
    deleteSportTiming: (payload:string):IDeleteSportTiming => ({type:SportTimingsActionEnum.DELETE_SPORT_TIMING,payload}),
    deleteTiming: (payload:string):IDeleteTiming => ({type:SportTimingsActionEnum.DELETE_TIMING,payload}),
    restoreSportTiming: (payload:string):IRestoreSportTiming => ({type:SportTimingsActionEnum.RESTORE_SPORT_TIMING,payload}),
    restoreSportTimingSuccess: (payload:string):IRestoreSportTimingSuccess => ({type:SportTimingsActionEnum.RESTORE_SPORT_TIMING_SUCCESS,payload})
}