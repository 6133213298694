import {BroadcasterAction, BroadcastersActionEnum, IBroadcastersInit, IBroadcastersState} from "./types";

const defaultState: IBroadcastersState = {
    init: {} as IBroadcastersInit,
    broadcasters:[]
}

export const broadcastersReducer = (state = defaultState, action: BroadcasterAction): IBroadcastersState => {

    switch (action.type) {
        case BroadcastersActionEnum.SET_BROADCASTERS: {
            return {...state,broadcasters: action.payload}
        }
        case BroadcastersActionEnum.SET_BROADCASTERS_INIT: {
            return {...state,init: action.payload}
        }
        case BroadcastersActionEnum.DELETE_BROADCASTER_SUCCESS: {
            const broadcasters = state.broadcasters.filter(broadcaster=>broadcaster.id !== action.payload)
            return {...state,broadcasters}
        }
        default:
            return state
    }
}