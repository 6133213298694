import axios from "axios";
import {toastr} from "react-redux-toastr";
import {auth} from "./auth-service";

export const client = axios.create({
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Content-Encoding': 'identity'
    },
    baseURL: process.env.REACT_APP_API_ENDPOINT
})
client.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('iptv-token');
        if (token) { // @ts-ignore
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
)

client.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config
        if (error.response.status === 401 && !error.config._isRetry) {
            originalRequest._isRetry = true
            const accessToken = await refreshToken();
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return client.request(originalRequest);
        }
        if (error.response.status === 400 && !error.config._isRetry) {
            return Promise.reject(error)
        }
        if(error.response.status === 400|| error.response.status > 400){
            return Promise.reject(error)
        }
    }
)

const refreshToken = async () => {
    const accessToken = localStorage.getItem("iptv-token");
    const refreshToken = localStorage.getItem("iptv-refresh");

    try {
        const refreshResp = await auth.post(`api/Account/refresh`, {accessToken, refreshToken})
        if (refreshResp.status === 200) {
            localStorage.setItem('iptv-token', refreshResp.data.accessToken);
            localStorage.setItem('iptv-refresh', refreshResp.data.refreshToken);
            return refreshResp.data.accessToken;
        }
        if (refreshResp.status === 400) {
            toastr.error('IGameMedia', 'Your session expired',)
            localStorage.removeItem('iptv-token');
            localStorage.removeItem('iptv-refresh');
            return  window.location.href = `/login`

        }

    } catch (e) {
        localStorage.removeItem('iptv-token');
        localStorage.removeItem('iptv-refresh');
        return window.location.href = `/login`
    }
}


