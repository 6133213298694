import {useEffect, useState} from "react";
import {ICompetition} from "../../../../store/reducers/competitions/types";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";

export const useCompetitionSearch = () => {
    const [searchString, setSearchString] = useState("")
    const [competitionsByName, setCompetitionsByName] = useState<ICompetition[]>([])
    const {competitions} = useTypedSelector(state => state.competitionsReducer)
    const findCompetitionsByName = () => {
        const allCompByName = competitions.filter(comp=> {
            return  comp.name.toLowerCase().includes(searchString.toLowerCase())
        })
        setCompetitionsByName(allCompByName)
    }

    useEffect(() => {
        if(searchString) {
            findCompetitionsByName()
        }
    },[searchString,competitions])
    return {setSearchString,competitionsByName,searchString}
}