import React, {Dispatch, SetStateAction} from "react";
import styles from './style.module.scss'
import Checkbox from "../../../../components/Checkbox";
import {IEvent} from "../../../../store/reducers/event/types";
import moment from "moment/moment";
import Pagination from "../../../../components/Pagination/Pagination";


interface IEventsTableProps {
    events: IEvent[]
    setEventsPageNumber: Dispatch<SetStateAction<number>>
    eventsPageNumber: number
    totalPages: number
    openConfirmMenu: Dispatch<SetStateAction<boolean>>
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; eventId: number; }>>
    openUpdateModal: () => void

}

const EventsTable: React.FC<IEventsTableProps> = ({

      events,
      setEventsPageNumber,
      eventsPageNumber,
      totalPages,
      setEditInfo,
      openConfirmMenu,
      openUpdateModal
    }) => {

    const openConfirm = (eventId: number) => {
        setEditInfo({isEdit: false, eventId})
        openConfirmMenu(true)
    }
    const openUpdateForm = (eventId: number) => {
        openUpdateModal()
        setEditInfo({isEdit:true,eventId})
    }
    return (
        <div className={styles.wrapper}>
            <table className={styles.eventsTable}>
                <thead className={styles.tableHeadWrapper}>
                <tr className={styles.tableRow}>
                    <th className={styles.tableHeader}>Start</th>
                    <th className={styles.tableHeader}>Home</th>
                    <th className={styles.tableHeader}>Away</th>
                    <th className={styles.tableHeader}>Description</th>
                    <th className={styles.tableHeader}>Server</th>
                    <th className={styles.tableHeader}>Broadcaster</th>
                    <th className={styles.tableHeader}>Decoder</th>
                    <th className={styles.tableHeader}>
                        <Checkbox isChecked={false}/>
                    </th>
                    <th className={styles.tableHeader}/>
                </tr>
                </thead>
                <tbody className={styles.tableBodyWrapper}>
                {events?.map(event => {
                    return (
                        <tr className={styles.tableRow} key={event.eventId}>
                            <td className={styles.tableDescription}>{moment(event.startDate).format("YYYY-MM-DD : HH:mm")}</td>
                            <td className={styles.tableDescription}>{event.home}</td>
                            <td className={styles.tableDescription}>{event.away}</td>
                            <td className={styles.tableDescription}>{event.description}</td>
                            <td className={styles.tableDescription}>{event.channelName}</td>
                            <td className={styles.tableDescription}>{event.broadcasterName}</td>
                            <td className={styles.tableDescription}>{event.decoderName}</td>
                            <td className={styles.tableDescription}>
                                <Checkbox isChecked={event.isManualEvent}/>
                            </td>
                            <td className={styles.tableDescDecoder}>
                                <div className={styles.controlBtns}>
                                    <button
                                        onClick={()=>openUpdateForm(event.eventId)}
                                        className={styles.updateBtn}
                                    />
                                    <button
                                        onClick={() => openConfirm(event.eventId)}
                                        className={styles.removeBtn}
                                    />
                                </div>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            {totalPages !== 0 &&
            <Pagination
                totalPages={totalPages}
                setPageNumber={setEventsPageNumber}
                pageNumber={eventsPageNumber}
            />}
        </div>
    );
};

export default React.memo(EventsTable);
