import jwtDecode from 'jwt-decode';

export interface IAuthUserInfo {
    aud: string
    email: string
    exp: string
    id: string
    iss: string
    nbf: string
    role: string
}

export const getUserInfo = () => {
    const token = localStorage.getItem("iptv-token")
    if (token) {
        return jwtDecode<IAuthUserInfo>(token)
    }
}