import {client} from "../../../services/api-servise";

export const getFixtureAuditRequest = async(fixtureId:string):Promise<any>=> {
    const audit = await client.get(`/api/Audits/fixture/${fixtureId}`)
    return audit.data
}

export const getCompetitionAuditRequest = async(competitionId:string):Promise<any>=> {
    const audit = await client.get(`/api/Audits/competition/${competitionId}`)
    return audit.data
}

export const getEventAuditRequest = async(eventId:string):Promise<any>=> {
    const audit = await client.get(`/api/Audits/event/${eventId}`)
    return audit.data
}