import {client} from "../../../services/api-servise";
import {IAddingUser, IUpdatingBody} from "./types";


export const getUsersRequest = async (): Promise<any> => {
    const users = await client.get('/api/User')
    return users.data
}

export const getSearchUsersRequest = async (payload: string): Promise<any> => {
    const user = await client.get(`/api/User?${payload}`)
    return user.data
}

export const getUsersRolesRequest = async (): Promise<any> => {
    const roles = await client.get('/api/User/roles')
    return roles.data
}

export const addUserRequest = async (payload: IAddingUser): Promise<any> => {
    const user = await client.post('/api/User', payload)
    return user.data
}

export const updateUserRequest = async (payload: IUpdatingBody): Promise<any> => {
    const user = await client.put('/api/User', payload)
    return user.data
}

export const deleteUserRequest = async (payload: number): Promise<any> => {
    const user = await client.delete(`/api/User/hard-delete?userId=${payload}`)
    return user.data
}

export const resetPasswordRequest = async (payload: string): Promise<any> => {
    const user = await client.get(`/api/User/reset-password?email=${payload}`)
    return user.data
}

export const confirmResetPasswordRequest = async (payload: string): Promise<any> => {
    const user = await client.put(`/api/User/confirm/reset-password?password=${payload}`)
    return user.data
}