import {useEffect, useState} from "react";
import {IGroupedSchedule} from "../../../store/reducers/schedule/saga";
export interface IAccordionState {
    accId:string,
    isExpand: boolean
}
export const useAccordionsState = (groupedSchedules:IGroupedSchedule[]) => {
    const [accordionsState, setAccordionsState] = useState<IAccordionState[]>([]);
    const toggleAccordion = (id:string) => {
        const newState = accordionsState.map(accordion=>{
            if (accordion.accId === id){
                return {accId:accordion.accId,isExpand:!accordion.isExpand}
            }
            return accordion
        })
        setAccordionsState(newState)
    }
    const checkIsExpanded = (id:string) => {
        const currentAccordion = accordionsState.find(accordion=>accordion.accId === id)
        if (currentAccordion){
            return currentAccordion.isExpand
        }
        else return false
    }
    const createAccordionState = () => {
        if (groupedSchedules) {
            const accordionState = groupedSchedules.map(schedule=>{
                return {
                    accId: schedule.schedules[0].eventId.toString(),
                    isExpand: false
                }
            })
            setAccordionsState(accordionState)
        }
    }
    const collapseAll = () => {
        const newState = accordionsState.map(accordion=>{
            return{...accordion,isExpand:false}
        })
        setAccordionsState(newState)
    }
    const expandAll = () => {
        const newState = accordionsState.map(accordion=>{
            return{...accordion,isExpand:true}
        })
        setAccordionsState(newState)
    }

    useEffect(() => {
        if(accordionsState.length === groupedSchedules.length){
            return
        } else {
            createAccordionState()
        }
    }, [groupedSchedules]);

    return {toggleAccordion,checkIsExpanded,collapseAll,expandAll,createAccordionState}
}
