import React from 'react';
import styles from './index.module.scss'
import FirstStepForm from "./FirstStepForm/FirstStepForm";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {Outlet} from "react-router-dom";


const Login: React.FC = (): JSX.Element => {
    const {isFirstStep} = useTypedSelector(state => state.authReducer)
    return (
        <div className={styles.loginPage}>
            <FirstStepForm isFirstStep={isFirstStep}/>
            <Outlet/>
        </div>
    )
};

export default React.memo(Login);
