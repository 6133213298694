import {useState} from "react";
import {MultiValue} from "react-select";
import {Range} from "react-calendar-datetime-picker";
import {MultiSelectValue} from "./useGetSchedules";
import {useDispatch} from "react-redux";
import {ScheduleActionCreators} from "../../../store/reducers/schedule/action-creators";
import {getQueryString} from "./getQueryString";

export enum filtersFields {
    broadcaster = 'broadcaster',
    server = 'server',
    decoder = 'decoder',
    sport = 'sport',
    competition = 'competition',
    country = 'country'
}

export interface IFiltersState {
    broadcaster: MultiValue<{ value: string, label: string }>,
    server: MultiValue<{ value: string, label: string }>,
    decoder: MultiValue<{ value: string, label: string }>,
    sport: MultiValue<{ value: string, label: string }>,
    competition: MultiValue<{ value: string, label: string }>,
    country: MultiValue<{ value: string, label: string }>,
}

export const useTableFilters = (range: Range, sport: MultiSelectValue, groupBy: MultiSelectValue, sortingString: string) => {
    const [filtersState, setFiltersState] = useState<IFiltersState>({
        broadcaster: [],
        server: [],
        decoder: [],
        sport: [],
        competition: [],
        country: [],
    })
    const dispatch = useDispatch();

    const handleTableFilters = (filterValue: MultiValue<{
        value: string,
        label: string
    }>, filterName: string): void => {

        const newFiltersState = {...filtersState, [filterName]: filterValue};
        setFiltersState(newFiltersState);

        dispatch(ScheduleActionCreators.getSchedules(getQueryString(range, sport, groupBy, sortingString, newFiltersState)))
    }

    return {filtersState, setFiltersState, handleTableFilters}
}
