import React, {ChangeEvent, useEffect, useState} from 'react';
import styles from './style.module.scss';
import Title from "../../../components/Title";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import {UsersActionCreators} from "../../../store/reducers/userManagement/action-creators";
import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import moment from "moment";
import UserManagementForm from "./children/UserManagementForm/UserManagementForm";
import {IUser} from "../../../store/reducers/userManagement/types";
import WarningModal from "../../../components/WarningModal/WarningModal";
import {useDebounce} from "../../../helpers/useDebounce";
import TableSort from '../../../img/tableSort.svg';
import Loader from "../../../components/Loader/Loader";


const UserManagement:React.FC = () => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [isOpenNewForm, setIsOpenNewForm] = useState<boolean>(false);
    const [isOpenEditForm, setIsOpenEditForm] = useState<boolean>(false);
    const [editUser, setEditUser] = useState<IUser | undefined>(undefined);
    const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
    const [isOpenResetPasswordConfirm, setIsOpenResetPasswordConfirm] = useState(false);
    const [deletingUser, setDeletingUser] = useState<{ id: number, email: string } | undefined>(undefined);
    const [userForResetPassword, setUserForResetPassword] = useState<{ id: number, email: string } | undefined>(undefined);

    const [isEmailSortingAsc, setIsEmailSortingAsc] = useState<boolean>(true);
    const [isLastLoginDateSortingAsc, setIsLastLoginDateAsc] = useState<boolean>(true);
    const [isRoleSortingAsc, setIsRoleSortingAsc] = useState<boolean>(true);

    const dispatch = useDispatch();
    const {users, isLoading} = useTypedSelector(state => state.usersReducer);


    useEffect(() => {
        dispatch(UsersActionCreators.getUsers())
    }, [])

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
    }


    const createSortingString = (sortProperty: string, isSortingOrderAsc?: boolean) => {
        if (sortProperty === 'searchInput') {
            return `SearchText=${searchValue}`
        } else {
            return `${searchValue ? `SearchText=${searchValue}` : ''}&Sorting.Property=${sortProperty}&Sorting.IsAscending=${isSortingOrderAsc}`
        }
    }

    const searchRequest = () => {
        setIsEmailSortingAsc(true);
        setIsLastLoginDateAsc(true);
        setIsRoleSortingAsc(true);

        const payload = createSortingString('searchInput');
        dispatch(UsersActionCreators.getSearchUsers(payload));
    }

    useDebounce(searchRequest, searchValue, 500);


    const editUserHandler = (id: number) => {
        const foundUser = users.find(user => user.userId === id);

        setEditUser(foundUser);
        setIsOpenEditForm(true);
    }

    const openConfirmDeleteModal = (id: number, email: string) => {
        const deletingUser = {
            id: id,
            email: email
        }
        setDeletingUser(deletingUser);
        setIsOpenDeleteConfirm(true);
    }

    const deleteUserHandler = () => {
        if (deletingUser) {
            dispatch(UsersActionCreators.deleteUser(deletingUser.id))
        }
    }

    const openConfirmResetPasswordModal = (id: number, email: string) => {
        const user = {
            id: id,
            email: email
        }
        setUserForResetPassword(user)
        setIsOpenResetPasswordConfirm(true);
    }

    const resetPasswordHandler = () => {
        if (userForResetPassword) {
            dispatch(UsersActionCreators.resetPassword(userForResetPassword.email))
        }
    }


    const sortingHandler = (sortProperty: string) => {
        let isSortingOrderAsc = undefined;

        if (sortProperty === 'email') {
            isSortingOrderAsc = !isEmailSortingAsc;

            setIsEmailSortingAsc(isSortingOrderAsc);
            setIsLastLoginDateAsc(true);
            setIsRoleSortingAsc(true);

        } else if (sortProperty === 'lastLoginDate') {
            isSortingOrderAsc = !isLastLoginDateSortingAsc;

            setIsEmailSortingAsc(true);
            setIsLastLoginDateAsc(isSortingOrderAsc);
            setIsRoleSortingAsc(true);

        } else if (sortProperty === 'role') {
            isSortingOrderAsc = !isRoleSortingAsc;

            setIsEmailSortingAsc(true);
            setIsLastLoginDateAsc(true);
            setIsRoleSortingAsc(isSortingOrderAsc);
        }

        const payload = createSortingString(sortProperty, isSortingOrderAsc);
        dispatch(UsersActionCreators.getSearchUsers(payload));
    }

    return (
        <div className={styles.container}>
            {isOpenNewForm && <UserManagementForm
                setIsOpenForm={setIsOpenNewForm}
                modalTitle='Add User'
            />}

            {isOpenEditForm &&
                <UserManagementForm
                    setIsOpenForm={setIsOpenEditForm}
                    modalTitle='Edit User'
                    editUser={editUser}
                />}

            {isOpenDeleteConfirm && <WarningModal
                setIsOpenModal={() => setIsOpenDeleteConfirm(false)}
                action={deleteUserHandler}
                confirmButtonText={'Confirm'}
                cancelButtonText={'Cancel'}
            >
                <span>Are you sure you want to delete user: <br/><br/>{deletingUser?.email} ?</span>
            </WarningModal>
            }

            {isOpenResetPasswordConfirm && <WarningModal
                setIsOpenModal={() => setIsOpenResetPasswordConfirm(false)}
                action={resetPasswordHandler}
                confirmButtonText={'Confirm'}
                cancelButtonText={'Cancel'}
            >
                <span>Are you sure you want to reset password for user: <br/><br/>{userForResetPassword?.email} ?</span>
            </WarningModal>
            }

            <div className={styles.mainTitle}>
                <Title value={'User Management'}/>
            </div>

            <div className={styles.options}>
                <div className={styles.searchContainer}>
                    <Input
                        type="text"
                        value={searchValue}
                        readonly={false}
                        placeholder={'Input user\'s email'}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
                <div className={styles.addUserButton}>
                    <Button value={'+ Add User'} onClick={() => setIsOpenNewForm(true)}/>
                </div>
            </div>

            <div className={styles.tableContainer}>
                {isLoading && <Loader/>}
                <table className={styles.tableWrapper}>
                    <thead>
                    <tr className={styles.theadRow}>
                        <th>
                            <div className={styles.tableTitle}>
                                <p>Email</p>
                                <img src={TableSort} alt="tableSortIcon"
                                     className={isEmailSortingAsc ? styles.ascIcon : styles.descIcon}
                                     onClick={() => sortingHandler('email')}
                                />
                            </div>
                        </th>
                        <th>
                            <div className={styles.tableTitle}>
                                <p>Last Login Date</p>
                                <img src={TableSort} alt="tableSortIcon"
                                     className={isLastLoginDateSortingAsc ? styles.ascIcon : styles.descIcon}
                                     onClick={() => sortingHandler('lastLoginDate')}
                                />
                            </div>
                        </th>
                        <th>Login Location</th>
                        <th>
                            <div className={styles.tableTitle}>
                                <p>Role</p>
                                <img src={TableSort} alt="tableSortIcon"
                                     className={isRoleSortingAsc ? styles.ascIcon : styles.descIcon}
                                     onClick={() => sortingHandler('role')}
                                />
                            </div>
                        </th>
                        <th>Actions</th>
                    </tr>
                    </thead>

                    <tbody>
                    {users?.map(user => (
                        <tr key={user.userId} className={styles.tbodyRow}>
                            <td>{user.email}</td>
                            <td>{user.lastLoginDate && moment(user.lastLoginDate).format('MMMM Do YYYY, h:mm:ss a')}</td>
                            <td>{user.loginLocation}</td>
                            <td>{user.role}</td>
                            <td>
                                <div className={styles.actions}>
                                    <button className={styles.removeBtn}
                                            onClick={() => openConfirmDeleteModal(user.userId, user.email)}/>
                                    <button className={styles.updateBtn} onClick={() => editUserHandler(user.userId)}/>
                                    <button className={styles.resetBtn}
                                            onClick={() => openConfirmResetPasswordModal(user.userId, user.email)}/>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default React.memo(UserManagement);