import styles from './button.module.scss'
import React from "react";

interface IButtonProps {
    value: string,
    onClick?: () => void,
    type?: "button" | "submit" | "reset" | undefined,
    disabled?: boolean
}

const Button: React.FC<IButtonProps> = ({value, onClick, type, disabled}) => {
    return (
        <button type={type ? type : "button"} onClick={onClick} className={styles.btn} disabled={disabled}>
            {value}
        </button>
    );
};

export default React.memo(Button);
