import React from 'react';
import './style.scss'

const Loader: React.FC = () => {
    return (
        <div className={"spinner-inner"}>
            <div className={"spinner"}/>
        </div>
    );
};

export default React.memo(Loader);