import {CompetitionsAction, CompetitionsActionEnum, ICompetitionsInit, ICompetitionsState} from "./types";

const defaultState: ICompetitionsState = {
    competitions: [],
    init: {} as ICompetitionsInit
}

export const competitionsReducer = (state = defaultState, action: CompetitionsAction): ICompetitionsState => {
    switch (action.type) {
        case CompetitionsActionEnum.SET_COMPETITIONS: {
            return {...state, competitions: action.payload}
        }
        case CompetitionsActionEnum.SET_COMPETITIONS_INIT: {
            return {...state, init: action.payload}
        }
        default:
            return state
    }
}