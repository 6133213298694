import React from 'react';
import ReactDOM from 'react-dom/client';
import {unstable_HistoryRouter as HistoryRouter} from "react-router-dom";
import {store} from "./store";
import {Provider} from "react-redux";
import {createBrowserHistory} from "history";
import AppRouter from "./components/AppRouter";
import './style.scss';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const history = createBrowserHistory();
root.render(
        <HistoryRouter history={history}>
            <Provider store={store}>
                <AppRouter/>
            </Provider>
        </HistoryRouter>
);

