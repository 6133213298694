import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import styles from "./style.module.scss"
import Title from "../../../../components/Title";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import {useTypedSelector} from "../../../../hooks/useTypedSelector";
import {useDispatch} from "react-redux";
import {SportTimingActionCreators} from "../../../../store/reducers/sportTimings/action-creators";
import {useDebounce} from "../../../../helpers/useDebounce";
import './style.module.scss'
import {ReactComponent as Sort} from "../../../../img/tableSort.svg";

interface ISportTimingsTableProps {
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; sportId: string; }>>
    openConfirmMenu: () => void
}

const SportTimingsTable:React.FC<ISportTimingsTableProps> = ({setIsOpenModal,setEditInfo,openConfirmMenu}) => {
    const dispatch = useDispatch()
    const {sportTimings} = useTypedSelector(state => state.sportTimingsReducer)
    const [isAscending, setIsAscending] = useState(true);
    const [searchValue, setSearchValue] = useState<string>('');
    const [initialized, setInitialized] = useState(false);

    const onSortSportTimingByName = () => {
        setIsAscending(!isAscending)
        const payload = {
            channelIds: [],
            keyword: searchValue,
            hasToBeAvailable: true,
            sortingModel: {
                property: "name",
                isAscending: !isAscending
            }
        }
        dispatch(SportTimingActionCreators.getSportTimings(payload))

    }

    const openEditForm = (sportId:string) => {
        setEditInfo({isEdit:true,sportId})
        setIsOpenModal(true)
    }

    const onOpenConfirmMenu = (sportId:string) => {
        setEditInfo({isEdit:false,sportId})
        openConfirmMenu()
    }

    const restoreSportTiming = (sportId:string) => {
        dispatch(SportTimingActionCreators.restoreSportTiming(sportId))
    }
    const findSportByName = () => {
        if(initialized){
            const payload = {
                channelIds: [],
                keyword: searchValue,
                hasToBeAvailable: true
            }
            dispatch(SportTimingActionCreators.getSportTimings(payload))
        }
        setInitialized(true)
    }
    useDebounce(findSportByName,searchValue,500)

    return (
        <div className={styles.wrapper}>
            <div className={styles.tableTitle}>
                <Title value={'Saved Sport Durations'}/>
                <div className={styles.tableControls}>
                    <div>
                        <Input
                            label={"Search"}
                            placeholder={"Enter sport's name"}
                            onChange={(e) => setSearchValue(e.target.value)}
                            value={searchValue}
                            readonly={false}
                        />
                    </div>
                    <div className="regularBtn">
                        <Button
                            value={'Create'}
                            onClick={() => setIsOpenModal(true)}
                        />
                    </div>
                </div>
            </div>
            <table className={styles.decodersTable}>
                <thead className={styles.tableHeadWrapper}>
                <tr className={styles.tableRow} key={1}>
                    <th onClick={()=>onSortSportTimingByName()} className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                        Sport Name
                        <Sort className={isAscending?`${styles.tableSortAsc}`:`${styles.tableSort}`}/>
                    </th>
                    <th className={styles.tableHeaderDeleted}>Duration</th>
                    <th className={styles.tableHeaderDeleted}/>
                </tr>
                </thead>
                <tbody className={styles.tableBodyWrapper}>
                {sportTimings.map(sport => {
                    return (
                        <tr className={styles.tableRow} key={sport.id}>
                            <td className={styles.tableDescDecoder}>{sport.name}</td>
                            <td className={styles.tableDescDeleted}>{sport.duration}</td>
                            <td className={styles.tableDescDecoder}>
                                <div className={styles.controlBtns}>
                                    <button
                                        onClick={() => openEditForm(sport.id)}
                                        className={styles.updateBtn}
                                    />
                                    {!sport.isDeleted
                                        ?
                                        <button
                                            onClick={() => onOpenConfirmMenu(sport.id)}
                                            className={styles.removeBtn}
                                        />
                                        :
                                        <button
                                            onClick={() => restoreSportTiming(sport.id)}
                                            className={styles.undeleteBtn}>
                                            Restore
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
};

export default SportTimingsTable;
