import React from 'react';
import styles from './index.module.scss';
import {Route, Routes} from "react-router-dom";
import {RouterNames} from "../../route";
import UserManagement from "./UserManagement/UserManagement";
import {getUserInfo} from "../../helpers/getUserInfo";


const User:React.FC = () => {
    const userInfo = getUserInfo();
    const userRole = userInfo ? userInfo.role : null;

    return (
        <div>
            <Routes>
                <Route
                    path={RouterNames.USERMANAGEMENT}
                    element={userRole === 'Admin' ? <UserManagement/>
                        :
                        <div className={styles.error}>
                            You don't have an access to this page.
                        </div>
                    }
                />
            </Routes>
        </div>
    );
};

export default User;