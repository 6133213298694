import {call, takeEvery, put,} from 'redux-saga/effects';
import {
    CompetitionsActionEnum,
    ICompetition,
    ICompetitionsInit,
    ICreateCompetition,
    IGetCompetition,
    IUpdateCompetition,
} from "./types";
import {
    createCompetitionRequest,
    getCompetitionsInit,
    getCompetitionsRequest,
    updateCompetitionRequest
} from "./requests";
import {CompetitionsActionCreators} from "./action-creators";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";


function* tryGetCompetitions({queryString}: IGetCompetition) {
    try {
        const formatQueryString = queryString.substring(0, queryString.length - 1)
        const competitions: ICompetition[] = yield call(getCompetitionsRequest, formatQueryString)
        const initOptions: ICompetitionsInit = yield call(getCompetitionsInit)
        yield put(CompetitionsActionCreators.setCompetitions(competitions))
        yield put(CompetitionsActionCreators.setCompetitionsInit(initOptions))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryCreateCompetition({payload}: ICreateCompetition) {
    try {
        yield call(createCompetitionRequest, payload)
        yield put(CompetitionsActionCreators.getCompetition(payload.queryString))
        toastr.success('IGameMedia', 'Competition has been created',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryUpdateCompetition({payload}: IUpdateCompetition) {
    try {
        yield call(updateCompetitionRequest,payload)
        yield put(CompetitionsActionCreators.getCompetition(payload.queryString))
        toastr.success('IGameMedia', 'Competition has been updated',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

export default function* competitionSagas(): any {
    yield takeEvery(CompetitionsActionEnum.GET_COMPETITIONS, tryGetCompetitions);
    yield takeEvery(CompetitionsActionEnum.CREATE_COMPETITION, tryCreateCompetition);
    yield takeEvery(CompetitionsActionEnum.UPDATE_COMPETITION, tryUpdateCompetition);
}