import moment from "moment/moment";
import {Range} from "react-calendar-datetime-picker";

export const useGetTodayTomorrowEvents = (setRange: (range: Range) => void) => {
    const dateFrom = new Date()
    const tomorrow = new Date(moment().add(1, 'days').toDate())
    const todayRange = {
        from: {
            year: dateFrom.getFullYear(),
            month: dateFrom.getMonth() + 1,
            day: dateFrom.getDate()
        }, to: {
            year: dateFrom.getFullYear(),
            month: dateFrom.getMonth() + 1,
            day: dateFrom.getDate()
        }
    }
    const tomorrowRange = {
        from: {
            year: dateFrom.getFullYear(),
            month: dateFrom.getMonth() + 1,
            day: dateFrom.getDate() + 1
        }, to: {
            year: tomorrow.getFullYear(),
            month: tomorrow.getMonth() + 1,
            day: tomorrow.getDate()
        }
    }
    const getTodayEvents = () => setRange(todayRange)
    const getTomorrowEvents = () => setRange(tomorrowRange)

    return {getTodayEvents, getTomorrowEvents}
}