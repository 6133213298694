import {useEffect, useState} from "react";

export const useKeyboardButton = () => {
    const [isCommandTab, setIsCommandTab] = useState<boolean>(false);

    const handleClickSelectButton = (event: KeyboardEvent, isPressed: boolean): void => {
        if (event.code === "MetaRight" || event.code === "MetaLeft" || event.code === "ControlLeft") {
            setIsCommandTab(isPressed);
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', (event) => handleClickSelectButton(event, true));
        document.addEventListener('keyup', (event) => handleClickSelectButton(event, false));

        return () => {
            document.removeEventListener('keydown', (event) => handleClickSelectButton(event, false));
            document.removeEventListener('keyup', (event) => handleClickSelectButton(event, false));
        };
    }, []);

    return {isCommandTab}
}
