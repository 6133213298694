import Title from "../../../components/Title";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AuditCompetitionTable from "./AuditCompetitionTable";
import {useDispatch} from "react-redux";
import {AuditActionCreators} from "../../../store/reducers/audit/action-creators";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {toastr} from "react-redux-toastr";


export const AuditFixture =() => {
    const {id} = useParams<{id:string}>()
    const dispatch = useDispatch()
    const {competitionAudit} = useTypedSelector(state=>state.auditReducer)
    const [competitionId, setCompetitionId] = useState("")

    useEffect(()=>{
        if (id !== ":id" && id){
            getCompetitionAudit(id)
            setCompetitionId(id)
        }
    },[])

    const getCompetitionAudit = (competitionId:string) => {
        if (competitionId){
            dispatch(AuditActionCreators.getCompetitionAudit(competitionId))
        }
        else toastr.warning("IGame","Enter competition id")
    }

    return (
        <div className={'container'}>
            <div className="mt64">
                <Title value={'Competition Audit Trail'}/>
            </div>
            <div className="flexAlignEnd">
                <div className="largeSelect mt15">
                    <Input
                        setValue={setCompetitionId}
                        value={competitionId}
                        label={'Competition'}
                        readonly={false}/>
                </div>
                <div className="regularBtn">
                    <Button onClick={()=>getCompetitionAudit(competitionId)} value={'View Audit Trail'}/>
                </div>
            </div>
            <div className={'mt60'}>
                <AuditCompetitionTable
                    competitionAudit={competitionAudit}
                />
            </div>
        </div>
    )
}

export default React.memo(AuditFixture);
