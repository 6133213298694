import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AuthActionCreators} from "../../../store/reducers/auth/action-creators";
import {FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {client} from "../../../services/api-servise";
import {useNavigate} from "react-router-dom";
import BtnLoader from "../../../components/BtnLoader/BtnLoader";
import logo from '../../../img/new_logo.svg';
import styles from './index.module.scss';
import SecondStepForm from "../SecondStepForm/SecondStepForm";

interface IFormInputs {
    username: string
    password: string
}

interface IFirstStepForm {
    isFirstStep: boolean
}

const FirstStepForm: React.FC<IFirstStepForm> = ({isFirstStep}) => {
    const [userCredentials, setUserCredentials] = useState({username: '', password: '',})
    const {register, handleSubmit, formState: {errors}} = useForm<IFormInputs>();
    const [appVersion, setAppVersion] = useState("");
    const dispatch = useDispatch();
    const {error, loading} = useTypedSelector(state => state.authReducer);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(AuthActionCreators.setIsFirstStep(false))
        checkIsUserLogin()
    }, [dispatch])


    const checkIsUserLogin = () => {
        const token = localStorage.getItem("iptv-token")
        if (token) {
            dispatch(AuthActionCreators.setIsAuth(true))
        } else {
            getAppVersion()
        }

    }

    const keyDownHandler = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !loading) {
            dispatch(AuthActionCreators.login(userCredentials))
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name
        setUserCredentials({...userCredentials, [name]: e.target.value})
    }

    const onSubmit: SubmitHandler<FieldValues> = () => {
        if (!loading) {
            dispatch(AuthActionCreators.login(userCredentials))
        }
    };

    const getAppVersion = async () => {
        const appVersion = await client.get("/api/Account/Assembley-Version")
        setAppVersion(appVersion.data)
    }

    return (
        <div className={styles.firstFormWrapper}>
            <div className={styles.firstFormWrapperLeft}>
                <div className={styles.firstFormWrapperDescrWrap}>
                    <div>Welcome to</div>
                    <div className={styles.firstFormWrapperLeftDescr}><span>ONE</span>OTT</div>
                    <div>Scheduling</div>
                </div>
                <div className={styles.firstFormWrapperPowered}>
                    <div>powered by</div>
                    <img src={logo} alt="igame-logo"/>
                </div>
            </div>
            <div className={styles.firstFormWrapperRight}>
                <form
                    autoComplete="new-password"
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.loginForm}
                >
                    <div className={styles.loginTitleInner}>
                        <h1 className={styles.loginTitle}>Log in</h1>
                    </div>

                    <div className={styles.inputsInner}>
                        <div className={styles.inputLabelWrapper}>
                            <label className={styles.inputLabel} htmlFor="username">Email</label>
                            <p
                                onClick={() => navigate("/forgot-password")}
                                className={styles.rememberPasswordLink}>
                                Forgot your password?
                            </p>
                        </div>
                        <input
                            {...register("username",
                                {
                                    required: !userCredentials.username
                                })}
                            id={"username"}
                            name={"username"}
                            value={userCredentials.username}
                            onChange={handleChange}
                            placeholder={"Username"}
                            autoComplete="new-password"
                            className={styles.loginInput}
                        />
                        {errors.username && <div className={styles.formErrorBlock}>Email is required</div>}
                        <div className={styles.inputLabelWrapper}>
                            <label className={styles.inputLabel} htmlFor="password">Password</label>
                        </div>
                        <input
                            {...register("password",
                                {
                                    required: !userCredentials.password
                                })}
                            id={"password"}
                            name={"password"}
                            value={userCredentials.password}
                            onChange={handleChange}
                            placeholder={"Password"}
                            autoComplete="new-password"
                            className={styles.loginInput}
                            type="password"
                            onKeyDown={keyDownHandler}
                        />
                        {errors.password && <div className={styles.formErrorBlock}>Password is required</div>}
                        {error && <div className={styles.errorInner}>
                            <div className={styles.formErrorBlock}>{error}</div>
                        </div>}
                    </div>
                    <div className={styles.loginHelper}>
                        <div className={styles.checkbox}>
                            <input type="checkbox" id="html"/>
                            <label htmlFor="html">Remember me</label>
                        </div>
                    </div>
                    <div className={styles.signInInner}>
                        <button
                            type='submit'
                            className={styles.signInBtn}
                        >
                            {loading ? <BtnLoader/> : "Login"}
                        </button>
                    </div>
                </form>
            </div>
            <div className={styles.appVersion}>{appVersion}</div>
            {isFirstStep && <SecondStepForm/>}
        </div>
    );
};

export default React.memo(FirstStepForm);
