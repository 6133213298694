import {call, takeEvery, put,} from 'redux-saga/effects';
import {
    createBroadcastersRequest, deleteBroadcastersRequest,
    getBroadcastersInit,
    getBroadcastersRequest,
    updateBroadcastersRequest
} from "./requests";
import {
    BroadcastersActionEnum,
    IBroadcaster,
    IBroadcastersInit,
    ICreateBroadcaster,
    IDeleteBroadcaster, IGetBroadcasters,
    IUpdateBroadcaster
} from "./types";
import {BroadcastersActionCreators} from "./action-creators";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";

interface IGetBroadcastersResp {
    definitionSelected: number,
    regionSelected: string,
    broadcasters: IBroadcaster[]
}

function* tryGetBroadcasters({queryString}:IGetBroadcasters) {
    try {
        const broadcastersResp:IGetBroadcastersResp = yield call(getBroadcastersRequest,queryString)
        const initBroadcasterResp:IBroadcastersInit = yield call(getBroadcastersInit)
        yield put(BroadcastersActionCreators.setBroadcastersInit(initBroadcasterResp))
        yield put(BroadcastersActionCreators.setBroadcasters(broadcastersResp.broadcasters))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryCreateBroadcaster({payload}:ICreateBroadcaster) {
    try {
        yield call(createBroadcastersRequest,payload)
        yield put(BroadcastersActionCreators.getBroadcasters(""))
        toastr.success('IGameMedia', 'Broadcaster has been created',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryUpdateBroadcaster({payload}:IUpdateBroadcaster) {
    try {
        yield call(updateBroadcastersRequest,payload)
        yield put(BroadcastersActionCreators.getBroadcasters(""))
        toastr.success('IGameMedia', 'Broadcaster has been updated',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryDeleteBroadcaster({payload}:IDeleteBroadcaster) {
    try {
        yield call(deleteBroadcastersRequest,payload)
        yield put(BroadcastersActionCreators.deleteBroadcasterSuccess(payload))
        toastr.success('IGameMedia', 'Broadcaster has been deleted',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

export default function* broadcasterSagas(): any {
    yield takeEvery(BroadcastersActionEnum.GET_BROADCASTERS, tryGetBroadcasters);
    yield takeEvery(BroadcastersActionEnum.CRETE_BROADCASTER, tryCreateBroadcaster);
    yield takeEvery(BroadcastersActionEnum.UPDATE_BROADCASTER, tryUpdateBroadcaster);
    yield takeEvery(BroadcastersActionEnum.DELETE_BROADCASTER, tryDeleteBroadcaster);
}