import Title from "../../../components/Title";
import React, {useEffect, useState} from "react";
import Button from "../../../components/Button";
import CompetitionTable from "./CompetitionTable/CompetitionTable";
import styles from './style.module.scss'
import SelectInput from "../../../components/SelectInput";
import {SingleValue} from "react-select";
import {useDispatch} from "react-redux";
import {CompetitionsActionCreators,} from "../../../store/reducers/competitions/action-creators";
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import CompetitionsModal from "./CompetitionsModal/CompetitionsModal";
import Input from "../../../components/Input";
import {useCompetitionSearch} from "./CompetitionSearchHooks/useCompetitionSearch";
import {formatOptions} from "../../../helpers/formatSelectOptions";


export interface ICompFilterValue {
    sportId: string,
    regionId: string,
    typeId: string,
    inputValues: {
        sportId: SingleValue<any>,
        regionId: SingleValue<any>,
        typeId: SingleValue<any>
    }
}
const CompetitionSearch: React.FC = () => {
    const dispatch = useDispatch()
    const {init, competitions} = useTypedSelector(state => state.competitionsReducer)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [editInfo, setEditInfo] = useState({isEdit: false, competitionId: ''})
    const {setSearchString,competitionsByName,searchString} = useCompetitionSearch()
    const [filterValue, setFilterValue] = useState<ICompFilterValue>({
        sportId: "",
        regionId: "",
        typeId: "",
        inputValues: {
            sportId: '',
            regionId: '',
            typeId: ''
        }
    })

    useEffect(() => {
        const filterValues = {
            sportId: filterValue.sportId,
            regionId: filterValue.regionId,
            typeId: filterValue.typeId
        }
        dispatch(CompetitionsActionCreators.getCompetition(getQueryString(filterValues)))
    }, [filterValue])



    const clearFilter = () => {
        const filterValue = {
            sportId: "",
            regionId: "",
            typeId: "",
            inputValues: {
                sportId: '',
                regionId: '',
                typeId: ''
            }
        }
        setFilterValue(filterValue)
        setSearchString("")
    }

    const setFilter = (e: SingleValue<any>, name: string) => {
        setFilterValue({...filterValue, [name]: e.value, inputValues: {...filterValue.inputValues, [name]: e}})
    }

    const getQueryString = (filter: { sportId: string, regionId: string, typeId: string }) => {
        const {typeId, regionId, sportId} = filter
        const getStringFromFilterValue = (query: string, value: any) => {
            if (value) {

                return `${query}=${value}&`
            }
            return ""
        }
        return `${getStringFromFilterValue("SportIds", sportId)}${getStringFromFilterValue("RegionIds", regionId)}${getStringFromFilterValue("CompetitionTypesSelected", typeId)}`
    }

    return (
        <div className={styles.competitions}>
            <Title value={'Competition search'}/>
            <div className={styles.filter}>
                <div className={styles.filterLeftSide}>
                    <div className="regularInput-Select mr20">
                        <SelectInput
                            value={filterValue.inputValues.sportId}
                            onChange={setFilter}
                            name={'sportId'}
                            searchable={true}
                            options={formatOptions(init?.sports?.items)}
                            label={'Sport'}/>
                    </div>
                    <div className="regularInput-Select mr20">
                        <SelectInput
                            value={filterValue.inputValues.regionId}
                            onChange={setFilter}
                            name={'regionId'}
                            searchable={true}
                            options={formatOptions(init?.regions?.items)}
                            label={'Region'}/>
                    </div>
                    <div className="regularInput-Select mr20">
                        <SelectInput
                            value={filterValue.inputValues.typeId}
                            onChange={setFilter}
                            name={"typeId"}
                            searchable={true}
                            options={formatOptions(init?.competitionTypes?.items)}
                            label={'Type'}/>
                    </div>
                    <div className={styles.searchInput}>
                        <Input
                            placeholder={"Search..."}
                            value={searchString}
                            setValue={setSearchString}
                            label={'Search by name...'}
                            readonly={false}
                        />
                    </div>
                    <div className={styles.clearFilterBtn}>
                        <Button
                            onClick={() => clearFilter()}
                            value={'Clear Filter'}
                        />
                    </div>
                </div>
                <div className={styles.filterRightSide}>
                    <div className="regularBtn">
                        <Button
                            onClick={() => setIsOpenModal(true)}
                            value={'Create'}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.competitionTableInner}>
                <CompetitionTable
                    setIsOpenModal={setIsOpenModal}
                    setEditInfo={setEditInfo}
                    compTypes={init?.competitionTypes?.items}
                    competitions={searchString?competitionsByName:competitions}
                />
            </div>
            {isOpenModal &&
            <CompetitionsModal
                getQueryString={getQueryString}
                filterValues={filterValue}
                formatOptions={formatOptions}
                setIsOpenModal={setIsOpenModal}
                setEditInfo={setEditInfo}
                editInfo={editInfo}
            />}
        </div>
    );
};

export default React.memo(CompetitionSearch);
