import React, {useState} from 'react';
import Input from "../../components/Input";
import Button from "../../components/Button";
import styles from './style.module.scss'
import footerLogo from "../../img/new_logo.svg";
import {Controller, useForm} from "react-hook-form";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {UsersActionCreators} from "../../store/reducers/userManagement/action-creators";


const ForgotPassword = () => {
    const {handleSubmit, control, getValues, formState: {errors}} = useForm({
        defaultValues: {
            email: "",
        }
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [requestIsSubmitted, setRequestIsSubmitted] = useState<boolean>(false);


    const onSubmit = () => {
        setRequestIsSubmitted(true);

        const payload = getValues("email");
        dispatch(UsersActionCreators.resetPassword(payload));
        navigate('/login')
    }

    return (
        <div className={styles.formInner}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className={styles.forgotPasswordForm}
            >
                <h1 className={styles.formTitle}>Enter your email:</h1>
                <div className={styles.inputInner}>
                    <div className={styles.formInput}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{
                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                required: true
                            }}
                            render={({field: {onChange, value}}) => <Input
                                readonly={false}
                                label={"Email"}
                                value={value}
                                setValue={onChange}
                                disabled={requestIsSubmitted}
                            />
                            }
                        />
                    </div>
                    {errors.email?.type === 'required' &&
                        <p className={styles.formError}>Email is required field</p>
                    }
                    {errors.email?.type === 'pattern' &&
                        <p className={styles.formError}>Incorrect email format</p>
                    }
                </div>
                <div className={styles.submitBtn}>
                    <Button
                        type={"submit"}
                        value={"Submit"}
                        disabled={requestIsSubmitted}
                    />
                </div>
                <div className={styles.formFooter}>
                    <div>
                        <img src={footerLogo} alt="logo"/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default React.memo(ForgotPassword);
