import {Link, NavLink, useLocation} from "react-router-dom";
import logo from "../../img/new_logo.svg";
import {ReactComponent as More} from "../../img/headerItemMore.svg";
import React, {useRef, useState} from "react";
import styles from "./index.module.scss";
import DropDownMenu from "./DropdownMenu/DropdownMenu";
import {RouterNames} from "../../route";
import {useOutsideHeaderClick} from "./hooks/useOutsideHeaderClick";
import {getUserInfo} from "../../helpers/getUserInfo";


const Header: React.FC = () => {
    const [isOpenManagementLinks, setIsOpenManagementLinks] = useState(false)
    const [isOpenAuditLinks, setIsOpenAuditLinks] = useState(false)
    const [isOpenUserLinks, setIsOpenUserLinks] = useState(false)

    const {pathname} = useLocation()
    const currentLink = pathname.split("/")[1]

    const userInfo = getUserInfo();
    const userRole = userInfo ? userInfo.role : null;

    const auditLinksMenu = useRef(null)
    const managementLinksMenu = useRef(null)
    const userLinksMenu = useRef(null)

    const ignoreManagementRef = useRef(null)
    const ignoreAuditsRef = useRef(null)
    const ignoreUserRef = useRef(null)

    const user = localStorage.getItem("iptv-token")
    const closeManagementLinks = () => setIsOpenManagementLinks(false)

    const closeAuditLinks = () => setIsOpenAuditLinks(false)

    const closeUserLinks = () => setIsOpenUserLinks(false)

    useOutsideHeaderClick(managementLinksMenu, ignoreManagementRef, closeManagementLinks)

    useOutsideHeaderClick(auditLinksMenu, ignoreAuditsRef, closeAuditLinks)

    useOutsideHeaderClick(userLinksMenu, ignoreUserRef, closeUserLinks)

    const toggleManagementLinks = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpenManagementLinks(!isOpenManagementLinks)
    }

    const toggleAuditLinks = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpenAuditLinks(!isOpenAuditLinks)
    }

    const toggleUserLinks = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        setIsOpenUserLinks(!isOpenUserLinks)
    }


    const managementLinks = [
        {
            part: `management/${RouterNames.BROADCASTERS}`,
            text: "BROADCASTERS"
        },
        {
            part: `management/${RouterNames.DECODERS}`,
            text: "DECODERS"
        },
        {
            part: `management/${RouterNames.EVENTS}`,
            text: "EVENTS"
        },
        {
            part: `management/${RouterNames.COMPETITIONS}`,
            text: "COMPETITIONS"
        },
        {
            part: `management/${RouterNames.SPORT_TIMINGS}`,
            text: "SPORT DURATIONS"
        },
        {
            part: `management/${RouterNames.SERVERS}`,
            text: "SERVERS"
        },
    ]

    const auditsLinks = [
        {
            part: `audits/fixture/:id`,
            text: "FIXTURE"
        },
        {
            part: `audits/competition/:id`,
            text: "COMPETITION"
        },
        {
            part: `audits/event/:id`,
            text: "EVENT"
        },
    ]

    const userLinks = userRole === 'Admin' ? [
            {
                part: `user/${RouterNames.USERMANAGEMENT}`,
                text: "USER MANAGEMENT"
            },
            {
                part: `user/logout`,
                text: "LOGOUT"
            },
        ]
        :
        [
            {
                part: `user/logout`,
                text: "LOGOUT"
            },
        ]

    return (
        <>
            {user && <header className={styles.header}>
                <Link to="fixtures">
                    <img className={styles.logo} src={logo} alt="logo"/>
                </Link>
                <menu className={styles.navbar}>
                    <NavLink
                        className={(navData) => (navData.isActive ? `${styles.active}` : `${styles.link}`)}
                        to={'schedule'}>
                        Schedule
                    </NavLink>
                    <h6
                        ref={ignoreManagementRef}
                        onClick={toggleManagementLinks}
                        className={currentLink === "management" ? styles.active : styles.link}>
                        Management
                        <More
                            className={`${isOpenManagementLinks ? `${styles.itemMoreActive}` : `${styles.itemMore}`}`}/>
                        {isOpenManagementLinks &&
                            <div ref={managementLinksMenu} className={styles.linkMenu}>
                                {<DropDownMenu onCloseMenu={setIsOpenManagementLinks} links={managementLinks}/>}
                            </div>}
                    </h6>
                    <NavLink
                        className={(navData) => (navData.isActive ? `${styles.active}` : `${styles.link}`)}
                        to={'fixtures'}>
                        Fixtures
                    </NavLink>
                    <h6
                        ref={ignoreAuditsRef}
                        onClick={toggleAuditLinks}
                        className={currentLink === "audits" ? styles.active : styles.link}
                    >
                        Audits
                        <More
                            className={`${isOpenAuditLinks ? `${styles.itemMoreActive}` : `${styles.itemMore}`}`}/>
                        {isOpenAuditLinks &&
                            <div ref={auditLinksMenu} className={styles.linkMenu}>
                                {<DropDownMenu onCloseMenu={setIsOpenAuditLinks} links={auditsLinks}/>}
                            </div>}
                    </h6>
                </menu>
                <h6 ref={ignoreUserRef} onClick={toggleUserLinks}
                    className={currentLink === "user" ? styles.active : styles.link}>
                    <button
                        className={currentLink === "user" || isOpenUserLinks ? styles.loginBtnActive : styles.loginBtn}/>
                    <More
                        className={`${isOpenUserLinks ? `${styles.itemMoreActive}` : `${styles.itemMore}`}`}/>
                    {isOpenUserLinks &&
                        <div ref={userLinksMenu} className={`${styles.linkMenu} ${styles.userLinkMenu}`}><DropDownMenu
                            onCloseMenu={setIsOpenUserLinks} links={userLinks}/></div>}
                </h6>
            </header>}
        </>
    );
};

export default React.memo(Header)
