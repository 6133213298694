import DtPicker from 'react-calendar-datetime-picker'
import React from "react";
import {Range} from "react-calendar-datetime-picker";
import './style.scss'


interface IRangeCalendarProps {
    setRange: (dateRange: Range) => void
    defaultRange: Range
}

const RangeCalendar: React.FC<IRangeCalendarProps> = ({setRange, defaultRange}) => {
    return (
        <div className="wrapper">
            <button
                type="button"
                className={"calendarDecor"}
            />
            <p className={"calendar-label"}>Date range</p>
            <DtPicker
                calenderModalClass={"rangePicker"}
                daysClass={"range-calendar-days"}
                yearsClass={"range-calendar-years"}
                headerClass={"range-calendar-header"}
                monthsClass={"range-calendar-months"}
                onChange={setRange}
                initValue={defaultRange}
                autoClose={true}
                type='range'
            />
        </div>
    )
}
export default React.memo(RangeCalendar);
