import {call, put, takeEvery} from "redux-saga/effects";
import {
    addUserRequest, confirmResetPasswordRequest,
    deleteUserRequest,
    getSearchUsersRequest,
    getUsersRequest,
    getUsersRolesRequest, resetPasswordRequest,
    updateUserRequest
} from "./requests";
import {UsersActionCreators} from "./action-creators";
import {
    IAddUser,
    IConfirmResetPassword,
    IDeleteUser,
    IGetSearchUsers,
    IResetPassword, IUpdateUser,
    IUser,
    IUsersRoles,
    UsersActionEnum
} from "./types";
import {toastr} from "react-redux-toastr";
import {AxiosError} from "axios";


type IGetUsersResp = IUser[];
type IGetUsersRolesResp = IUsersRoles;


function* tryGetUsers() {
    try {
        yield put(UsersActionCreators.setUsersLoader(true))
        const resp: IGetUsersResp = yield call(getUsersRequest)
        yield put(UsersActionCreators.setUsers(resp))
        yield put(UsersActionCreators.setUsersLoader(false))
    } catch (e) {
        yield put(UsersActionCreators.setUsersLoader(false))
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryGetSearchUsers({payload}: IGetSearchUsers) {
    try {
        const resp: IGetUsersResp = yield call(getSearchUsersRequest, payload)
        yield put(UsersActionCreators.setUsers(resp))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryGetUsersRoles() {
    try {
        const resp: IGetUsersRolesResp = yield call(getUsersRolesRequest)
        yield put(UsersActionCreators.setUsersRoles(resp))
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryAddUser({payload}: IAddUser) {
    try {
        yield call(addUserRequest, payload)
        yield put(UsersActionCreators.getUsers())
        toastr.success('IGameMedia', 'User has been added',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryUpdateUser({payload}: IUpdateUser) {
    try {
        yield call(updateUserRequest, payload.updatingBody)
        yield put(UsersActionCreators.updateUserSuccess(payload))
        toastr.success('IGameMedia', 'User has been updated',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryDeleteUser({payload}: IDeleteUser) {
    try {
        yield call(deleteUserRequest, payload)
        yield put(UsersActionCreators.deleteUserSuccess(payload))
        toastr.success('IGameMedia', 'User has been deleted',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryResetPassword({payload}: IResetPassword) {
    try {
        yield call(resetPasswordRequest, payload)
        toastr.success('IGameMedia', 'Email for reset password has been sent to user',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}

function* tryConfirmResetPassword({payload}: IConfirmResetPassword) {
    try {
        yield call(confirmResetPasswordRequest, payload)
        toastr.success('IGameMedia', 'Password has been successful changed',)
    } catch (e) {
        if (e instanceof AxiosError) {
            toastr.error('IGameMedia', e?.response?.data)
        }
    }
}


export default function* usersSagas(): any {
    yield takeEvery(UsersActionEnum.GET_USERS, tryGetUsers);
    yield takeEvery(UsersActionEnum.GET_SEARCH_USERS, tryGetSearchUsers);
    yield takeEvery(UsersActionEnum.GET_USERS_ROLES, tryGetUsersRoles);
    yield takeEvery(UsersActionEnum.ADD_USER, tryAddUser);
    yield takeEvery(UsersActionEnum.UPDATE_USER, tryUpdateUser);
    yield takeEvery(UsersActionEnum.DELETE_USER, tryDeleteUser);
    yield takeEvery(UsersActionEnum.RESET_PASSWORD, tryResetPassword);
    yield takeEvery(UsersActionEnum.CONFIRM_RESET_PASSWORD, tryConfirmResetPassword);
}