import {auth} from "../../../services/auth-service";
import {client} from "../../../services/api-servise";
import {IResetPasswordData} from "./types";

export const loginRequest = async (email: string, password: string): Promise<any> => {
    const loginResponse = await auth.post('/api/Account/login/first-step', {email, password})
    return loginResponse.data
}

export const verifyRequest = async (email: string, verificationCode: string, staySignedIn: boolean): Promise<any> => {
    const verifyResponse = await auth.post('/api/Account/login/second-step', {email, verificationCode, staySignedIn})
    return verifyResponse.data
}
