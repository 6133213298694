import React, {Dispatch, SetStateAction, useState} from "react";
import Title from "../../../../components/Title";
import styles from './style.module.scss'
import Checkbox from "../../../../components/Checkbox";
import {IDecoder} from "../../../../store/reducers/decoders/types";
import Button from "../../../../components/Button";
import {DecodersActionCreators} from "../../../../store/reducers/decoders/action-creators";
import {useDispatch} from "react-redux";
import Input from "../../../../components/Input";
import {ReactComponent as Sort} from "../../../../img/tableSort.svg";

interface IDecodersTableProps {
    decoders: IDecoder[]
    setIsOpenModal: Dispatch<SetStateAction<boolean>>
    setEditInfo: Dispatch<SetStateAction<{ isEdit: boolean; decoderId: number; }>>
    openConfirmMenu: () => void
    searchResult: IDecoder[]
    findDecoderByName: (event: React.ChangeEvent<HTMLInputElement>) => void
    searchValue: string
}

const DecodersTable: React.FC<IDecodersTableProps> = ({
      decoders,
      setIsOpenModal,
      setEditInfo,
      openConfirmMenu,
      searchResult,
      searchValue,
      findDecoderByName
    }) => {

    const dispatch = useDispatch()
    const [sortingState, setSortingState] = useState([{field: "name", asc: true}]);
    const [currentSortingField, setCurrentSortingField] = useState("");
    const openEditModal = (decoderId: number) => {
        setEditInfo({isEdit: true, decoderId})
        setIsOpenModal(true)
    }
    const openConfirm = (decoderId: number) => {
        setEditInfo({isEdit: false, decoderId})
        openConfirmMenu()
    }

    const toggleDeleteDecoder = (decoderId: number) => {
        dispatch(DecodersActionCreators.toggleDeleteDecoder(decoderId))
    }
    const getCurrentDecodersArray = () => {
        if (searchValue) {
            return searchResult
        } else return decoders
    }

    const onSort = (field: string) => {
        setCurrentSortingField(field)
        const newSortingState = sortingState.map(state => {
            if (state.field === field) {
                state.asc = !state.asc
                return state
            } else {
                state.asc = true
                return state
            }
        })
        const currentDirection = newSortingState.find(state => state.field === field)
        const queryString = `?SortingModel.Property=${field}&SortingModel.IsAscending=${currentDirection?.asc}`
        setSortingState(newSortingState)
        dispatch(DecodersActionCreators.getDecoders(queryString))

    }
    const getCurrentDirectionByFieldName = (field: string) => {
        const currentDirection = sortingState.find(state => state.field === field)
        if (currentDirection) {
            return currentDirection.asc
        }
    }
    return (
        <div className={styles.wrapper}>
            <div className={styles.tableTitle}>
                <Title value={'Saved Decoders'}/>
                <div className={styles.tableControls}>
                    <div>
                        <Input
                            label={"Search"}
                            placeholder={"Enter decoder's name"}
                            onChange={(e) => findDecoderByName(e)}
                            value={searchValue}
                            readonly={false}
                        />
                    </div>
                    <div className="regularBtn">
                        <Button onClick={() => setIsOpenModal(true)} value={'Create'}/>
                    </div>
                </div>
            </div>
            <table className={styles.decodersTable}>
                <thead className={styles.tableHeadWrapper}>
                <tr className={styles.tableRow} key={1}>
                    <th
                        onClick={() => onSort("name")}
                        className={`${styles.tableHeader} ${styles.tableSortingHeader}`}>
                        Decoder Name
                        {currentSortingField === "name" && <Sort
                            className={getCurrentDirectionByFieldName("name") ? `${styles.tableSortAsc}` : `${styles.tableSort}`}
                        />}
                    </th>
                    <th className={styles.tableHeaderDeleted}>Deleted</th>
                    <th className={styles.tableHeaderDeleted}/>
                </tr>
                </thead>
                {searchResult.length === 0 && searchValue &&
                    <div className={styles.tableInfoBlock}>
                        <h1 className={styles.text}>No search results</h1>
                    </div>
                }
                <tbody className={styles.tableBodyWrapper}>
                {getCurrentDecodersArray()?.map(decoder => {
                    return (
                        <tr className={styles.tableRow} key={decoder.id}>
                            <td className={styles.tableDescDecoder}>{decoder.name}</td>
                            <td className={styles.tableDescDeleted}>
                                <div
                                    className={styles.decodersCheckbox}>
                                    <Checkbox
                                        isChecked={decoder.isDeleted}
                                    />
                                </div>
                            </td>
                            <td className={styles.tableDescDecoder}>
                                <div className={styles.controlBtns}>
                                    <button
                                        onClick={() => openEditModal(decoder.id)}
                                        className={styles.updateBtn}
                                    />
                                    {!decoder.isDeleted
                                        ?
                                        <button
                                            onClick={() => openConfirm(decoder.id)}
                                            className={styles.removeBtn}
                                        />
                                        :
                                        <button
                                            onClick={() => toggleDeleteDecoder(decoder.id)}
                                            className={styles.undeleteBtn}>
                                            Restore
                                        </button>
                                    }
                                </div>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
};

export default React.memo(DecodersTable);
