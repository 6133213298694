import authSagas from "./store/reducers/auth/saga";
import {all} from "redux-saga/effects";
import fixturesSagas from "./store/reducers/fixtures/saga";
import decoderSagas from "./store/reducers/decoders/saga";
import broadcasterSagas from "./store/reducers/broadcasters/saga";
import competitionSagas from "./store/reducers/competitions/saga";
import eventsSaga from "./store/reducers/event/saga";
import auditSaga from "./store/reducers/audit/saga";
import scheduleSaga from "./store/reducers/schedule/saga";
import usersSagas from "./store/reducers/userManagement/saga";
import sportTimingsSagas from "./store/reducers/sportTimings/saga";
import channelsSagas from "./store/reducers/channels/saga";


export function* rootSaga() {
    yield all([
        authSagas(),
        fixturesSagas(),
        decoderSagas(),
        broadcasterSagas(),
        competitionSagas(),
        eventsSaga(),
        auditSaga(),
        scheduleSaga(),
        usersSagas(),
        sportTimingsSagas(),
        channelsSagas()
    ])

}
